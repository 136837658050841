import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Cancel, CloudUpload, Delete, Public } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDrive,
  getAssigntypeData,
  getDriveData,
  updateDrive,
} from "../../services/drive.service";
import AutoSnackbar from "../../common/AutoSnackbar";
import { createMedia, fetchMedia } from "../../services/media.service";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddDrive(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [loading, seLoading] = useState(true);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [featuredImg, setFeaturedImg] = useState("");
  const assignTypes = [
    {
      type: "Course",
      value: "Course",
      label: "select_course",
      url: "courses",
    },
    {
      type: "Group",
      value: "Group",
      label: "select_group",
      url: "groups",
    },
    {
      type: "Users",
      value: "Users",
      label: "select_users",
      url: "users",
    },
  ];
  const [assignType, setAssignType] = useState(assignTypes[0]);
  const initialDetails = {
    title: "",
    content: "",
    acf: {},
    featured_media: null,
    status: "publish",
  };
  const [driveDetails, setDriveDetails] = useState(initialDetails);
  const [initialDriveData, setInitialDriveData] = useState(null);
  const handleFeatureChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setDriveDetails({
          ...driveDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setDriveDetails({
          ...driveDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSectionRow = () => {
    const sectionObj = {
      file_type: "",
      file_name: "",
      file: "",
    };
    const updatedSections = {
      ...driveDetails,
      acf: {
        ...driveDetails.acf,
        section: driveDetails.acf.section
          ? [...driveDetails.acf.section, sectionObj]
          : [sectionObj],
      },
    };

    setDriveDetails(updatedSections);
  };
  const handleRemoveRow = (index) => {
    const updatedSections = {
      ...driveDetails,
      acf: {
        ...driveDetails.acf,
        section: driveDetails.acf.section.filter((_, i) => i !== index),
      },
    };
    setDriveDetails(updatedSections);
  };
  const handleAssignTypeChange = (e) => {
    const selectedType = assignTypes.find(
      (type) => type.value === e.target.value
    );
    setDriveDetails((prevDetails) => ({
      ...prevDetails,
      acf: {
        ...prevDetails.acf,
        assign_type: e.target.value,
      },
    }));
    setAssignType(selectedType);
  };

  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateDrive(params?.id, driveDetails);
    } else {
      res = await createDrive(driveDetails);
    }
    if (res) {
      if (res) navigate(`/drive/${res?.id}`);
      setPublishSuccess(true);
    }
  };
  useEffect(() => {
    const fetchDriveData = async () => {
      const response = await getDriveData(params?.id);
      let featuredimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response?.featured_media);

      setFeaturedImg(featuredimgData?.source_url);
      const selectedType = assignTypes.find(
        (type) => type.value === response.acf?.assign_type
      );
      if (selectedType) setAssignType(selectedType);
      const details = {
        ...driveDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        acf: {
          section: response.acf?.section || [],
          select_course: response.acf?.select_course || null,
          select_group: response.acf?.select_group || null,
          select_users: response.acf?.select_users || null,
          assign_type: response.acf?.assign_type
            ? response.acf?.assign_type
            : assignType.value,
        },
        featured_media: featuredimgData?.id,
      };
      setDriveDetails(details);
      setInitialDriveData(details);
      seLoading(false);
    };

    if (params?.id) {
      fetchDriveData();
    } else {
      setDriveDetails(initialDetails);
      seLoading(false);
    }
  }, [params, params?.id]);
  useEffect(() => {
    const fetchSelectedData = async () => {
      const selectTypeData = await getAssigntypeData(assignType?.url);
      setSelectedData(selectTypeData);
    };
    fetchSelectedData();
  }, [assignType]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Drive" : "Add New Drive"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={driveDetails.status}
              onChange={(e) =>
                setDriveDetails({
                  ...driveDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={driveDetails.title}
                        onChange={(e) =>
                          setDriveDetails({
                            ...driveDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={driveDetails.content}
                        onChange={(value) =>
                          setDriveDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Drive" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Assign Type</InputLabel>
                      <Select
                        sx={{ mt: "5px" }}
                        fullWidth
                        labelId="select-type"
                        id="select-type"
                        placeholder="Select"
                        value={driveDetails.acf.assign_type}
                        onChange={handleAssignTypeChange}
                      >
                        {assignTypes.map((type) => (
                          <MenuItem key={type.type} value={type.value}>
                            {type.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>
                        {assignType?.label
                          ?.replace(/_/g, " ")
                          ?.replace(/\b\w/g, (c) => c.toUpperCase())}
                      </InputLabel>

                      <Select
                        sx={{ mt: "5px" }}
                        fullWidth
                        labelId="select-types"
                        id="select-types"
                        placeholder="Select"
                        value={driveDetails.acf[assignType.label]}
                        onChange={(e) =>
                          setDriveDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              [assignType.label]: e.target.value,
                            },
                          }))
                        }
                      >
                        {selectedData &&
                          selectedData?.map((data) => (
                            <MenuItem key={data.id} value={data.id}>
                              {data?.title || data?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>Section</InputLabel>
                      <Box
                        sx={{
                          padding: "10px",
                          background: "#ebebeb",
                          margin: "10px 0",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {driveDetails.acf.section &&
                        driveDetails.acf.section?.length !== 0 ? (
                          driveDetails.acf.section.map((row, index) => (
                            <Paper
                              key={index}
                              sx={{
                                padding: "20px",
                                position: "relative",
                                margin: "5px",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <InputLabel>File Type</InputLabel>
                                  <TextField
                                    placeholder="File Type"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="name"
                                    value={row.file_type}
                                    onChange={(e) =>
                                      setDriveDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          section: prevDetails.acf.section.map(
                                            (item, i) =>
                                              i === index
                                                ? {
                                                    ...item,
                                                    file_type: e.target.value,
                                                  }
                                                : item
                                          ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <InputLabel>File Name</InputLabel>
                                  <TextField
                                    placeholder="Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="name"
                                    value={row.file_name}
                                    onChange={(e) =>
                                      setDriveDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          section: prevDetails.acf.section.map(
                                            (item, i) =>
                                              i === index
                                                ? {
                                                    ...item,
                                                    file_name: e.target.value,
                                                  }
                                                : item
                                          ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <InputLabel>File Link</InputLabel>
                                  <TextField
                                    placeholder="URL"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="link"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Public />
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={row.file}
                                    onChange={(e) =>
                                      setDriveDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          section: prevDetails.acf.section.map(
                                            (item, i) =>
                                              i === index
                                                ? {
                                                    ...item,
                                                    file: e.target.value,
                                                  }
                                                : item
                                          ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: "95%",
                                }}
                                onClick={() => handleRemoveRow(index)}
                              >
                                <Cancel />
                              </IconButton>
                            </Paper>
                          ))
                        ) : (
                          <Typography>No Rows</Typography>
                        )}
                      </Box>
                      <Button variant="contained" onClick={handleSectionRow}>
                        Add Row
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setFeaturedImg("");
                          setDriveDetails((prevDetails) => ({
                            ...prevDetails,
                            featured_media: null,
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddDrive;
