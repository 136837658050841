import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { admin_login } from "../../services/login.service";

const loginUser = createAsyncThunk(
  "login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await admin_login(credentials);
      if (response?.token) {
        if (Array.isArray(response.roles) && ["administrator", "mentor","finance","publisher"].some(role => response.roles.includes(role))) {
          return response;
        } else {
          return rejectWithValue("You are not allowed.");
        }
      } else {
        const errorData = await response;
        throw new Error(errorData.message || "Login failed");
      }
    } catch (error) {
      return rejectWithValue("Login failed, Check the details.");
    }
  }
);

const logout = createAction("logout");

const authSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = action.payload?.token;
    });

    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(logout, (state) => {
      return {};
    });
  },
});
export const loginActions = { ...authSlice.actions, loginUser, logout };
export default authSlice.reducer;
