import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, Button, IconButton } from "@mui/material";
import { Logout, MenuOpen } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../redux/Slice/authSlice";
import { persistor } from "../redux/store";

const Header = ({handleSideToggle ,toggle}) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const handleToggle=()=>{
    handleSideToggle()
  }
  const handleLogout = () => {
    if (token) {
      dispatch(loginActions.logout());
      persistor.purge();
      localStorage.clear();
    }
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        "&.MuiPaper-root.MuiAppBar-root": {
          width: toggle ?"85%":"100%",
          background: "#fff",
          boxShadow: "1px 0px 10px 1px #ccc",
        },
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconButton onClick={handleToggle}>
            <MenuOpen />
          </IconButton>
          <Button variant="contained" onClick={handleLogout} endIcon={<Logout />}>LogOut</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
