import { axiosInstance } from "./axiosInstence";

export const getBadges = async(page, perPage,seachText)=>{
  try {
    const response = await axiosInstance.get(
      `badge?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
}
export const getBadgeData = async(id)=>{
    const response = await axiosInstance
    .get(`badge/${id}`)
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}
export const updateBadge = async(id, data)=>{
  const response = await axiosInstance
  .post(`badge/${id}`,data)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
export const createBadge = async(data)=>{
  const response = await axiosInstance
  .post("badge",data)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
// export const deleteBadge = async(id)=>{
//   const response = await axiosInstance
//   .delete(`badge/${id}`)
//   .then((res) => res.data)
//   .catch((err)=>err.message);
// return response;
// }