import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-row": {
            maxHeight: "none!important",
          },
          "& .MuiDataGrid-cell": {
            overflow: "auto!important",
            whiteSpace: "normal!important",
            maxHeight: "none!important",
          },
        },
        virtualScroller:{
          minHeight:"55vh !important"
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& input": {
            padding: "0.625rem 0.938rem",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
          },
          color: "#000",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 1px 1px #ccc",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "initial !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "16px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0.125rem",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiPickersDay-root": {
            "&:hover": {
              background: "#ccc",
            },
            "&.Mui-selected": {
              backgroundColor: "#333f52",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#294776",
              },
            },
          },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.25rem",
          minWidth: "7.5rem",
          backgroundColor: "#333f52",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#294776",
            color: "#fff",
          },
        },
        outlined: {
          textTransform: "none",
          borderRadius: "0.25rem",
          minWidth: "7.5rem",
          backgroundColor: "#fff",
          color: "#000",
          borderColor: "#000",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "0.25rem",
            borderColor: "#000",
          },
        },
        sizeSmall: {
          padding: "0.375rem 1rem",
        },
        sizeMedium: {
          padding: "0.5rem 1.25rem",
        },
        sizeLarge: {
          padding: "0.6875rem 1.5rem",
        },
        textSizeSmall: {
          padding: "0.4375rem 0.75rem",
        },
        textSizeMedium: {
          padding: "0.5625rem 1rem",
        },
        textSizeLarge: {
          padding: "0.75rem 1rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0.9375rem",
          "&:last-child": {
            paddingBottom: "0.9375rem",
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: "end",
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          fontSize: "16px",
        },
        subheaderTypographyProps: {
          variant: "body2",
        },
      },
      styleOverrides: {
        root: {
          padding: "10px 16px",
          background: "#333f52",
          color: "#fff",
        },
      },
    },
    MuiDialogTitle:{
      styleOverrides: {
        root: {
          padding: "10px 16px",
          background: "#333f52",
          color: "#fff",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.MuiTextField-root": {
            marginTop: "5px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: "#fff",
          padding: "0.375rem 0.625rem",
          "&:hover,&:focus": {
            background: "#333f52",
            color: "#fff",
            borderRadius: "0rem",
          },
          fontSize: "0.875rem",
          "&.MuiMultiSectionDigitalClockSection-item": {
            ":hover": {
              background: "#ccc",
              color: "#000",
            },
            "&.Mui-selected": {
              background: "#333f52",
              color: "#fff",
              ":hover": {
                background: "#294776",
              },
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
        select: {
          "&.MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "10px 15px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "0.18rem 0.6rem",
          },
        },
        option: {
          zIndex:"2000 !important",
          background: "#fff",
          padding: "0.375rem 0.625rem",
          fontSize: "0.875rem",
          "&:hover, &:focus": {
            background: "#333f52 !important",
            color: "#fff",
            borderRadius: "0rem",
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        ".pac-container": {
          zIndex: "9999",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
          ".MuiTableCell-root": {
            color: "#ccc",
          },
          // borderBottom: 'none',
          "& .MuiTableCell-root": {
            fontSize: "0.75rem",
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: "none",
          },
          "& .MuiTableCell-paddingCheckbox": {
            paddingTop: 4,
            paddingBottom: 4,
          },
          "& .MuiTableCell-head": {
            color: "#fff",
          },
          "& .MuiTableHead-root": {
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  shape: {
    borderRadius: 8,
  },

  typography: {
    fontFamily: "Nunito, sans-serif",
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },

    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.0313rem",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.375,
    },
    h2: {
      fontFamily: "Nunito, sans-serif",
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.375,
    },
    h3: {
      fontFamily: "Nunito, sans-serif",
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.375,
    },
    h4: {
      fontFamily: "Nunito, sans-serif",
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.375,
    },
    h5: {
      fontFamily: "Nunito, sans-serif",
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.375,
    },
    h6: {
      fontFamily: "Nunito, sans-serif",
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.375,
    },
    button: {
      textTransform: "none",
      lineHeight: "normal",
    },
  },
});
