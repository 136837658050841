import { axiosInstance } from "./axiosInstence";

export const getAllDrives = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `drive?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
// export const deteleDrive = async (id) => {
//     const response = await axiosInstance
//       .delete(`drive/${id}`)
//       .then((res) => res.data)
//       .catch((err) => err.message);
//     return response;
//   };

  export const getDriveData = async (id) => {
    const response = await axiosInstance
      .get(`drive/${id}`)
      .then((res) =>res.data)
      .catch((err) => err.message);
    return response;
  };
  export const getAssigntypeData = async(url)=>{
    const response = await axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}

export const updateDrive = async (id, data) => {
    const response = await axiosInstance
      .post(`drive/${id}`, data)
      .then((res) => res.data)
      .catch((err) => null);
    return response;
  };
  export const createDrive = async (data) => {
      const response = await axiosInstance
        .post("drive", data)
        .then((res) => res.data)
        .catch((err) => null);
      return response;
    };