import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function AlertDialog({
  open,
  setOpen,
  handleConfirm,
  handleReject = () => {},
  title,
  dialogTitle,
  dialogText,
  noButtonText,
  yesButtonText,
}) {
  const handleAgree = () => {
    handleConfirm();
  };
  const handleCancel = () => {
    handleReject();
    setOpen(false);
  };
  return (
    <Dialog
      onClose={()=>setOpen(false)}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {dialogTitle && (
        <DialogTitle id="alert-dialog-title" sx={{mb:"20px"}}>{dialogTitle}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText || `Are you sure you want to delete the ${title}.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          {noButtonText || "No"}
        </Button>
        <Button variant="contained" onClick={handleAgree} autoFocus>
          {yesButtonText || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog;
