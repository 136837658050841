import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Cancel, CloudUpload, Delete, Public } from "@mui/icons-material";
import { createMedia, fetchMedia } from "../../services/media.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  createMeditation,
  getMedLinkedProducts,
  getMeditationCategories,
  getMeditationData,
  updateMeditation,
} from "../../services/meditation.service";
import { getWebsites } from "../../services/extraSites.service";
import AutoSnackbar from "../../common/AutoSnackbar";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddMeditation(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isSqureHovered, setIsSqureHovered] = useState(false);
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [categories, setCategories] = useState(null);
  const [websites, setWebsites] = useState(null);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const extraFeildObj = {
    button_link: "",
    button_name: "",
    type: "",
  };
  const initialDetails = {
    title: "",
    content: "",
    meditation_category: [],
    acf: {
      type: "Free",
      audio: "",
      extra_fields: [],
      banner: null,
      square_image: null,
      watch_instructions: "",
    },
    extra_pages_cat: [],
    featured_media: null,
    excerpt: "",
    status: "publish",
  };
  const [medDetails, setMedDetails] = useState(initialDetails);
  const [Images, setImages] = useState({
    bannerImg: "",
    squareImg: "",
    featuredImg: "",
  });
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const handleAddTag = () => {
    const newTags = tagInput.split(",").map((tag) => tag.trim());
    setTags([...tags, ...newTags]);
    setTagInput("");
  };

  const handleImageChange = async (file, acfKey, mediaKey) => {
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          [mediaKey]: reader.result,
        }));
        if (acfKey === "featured_media") {
          setMedDetails((prevDetails) => ({
            ...prevDetails,
            [acfKey]: res.id,
          }));
        } else
          setMedDetails((prevDetails) => ({
            ...prevDetails,
            acf: {
              ...prevDetails.acf,
              [acfKey]: res.id,
            },
          }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDrop = async (e, acfKey, mediaKey) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    await handleImageChange(file, acfKey, mediaKey);
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "banner", "bannerImg");
  };

  const handleBannerDrop = (e) => {
    handleImageDrop(e, "banner", "bannerImg");
  };

  const handleSquareChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "square_image", "squareImg");
  };

  const handleSquareDrop = (e) => {
    handleImageDrop(e, "square_image", "squareImg");
  };

  const handleFeatureChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "featured_media", "featuredImg");
  };

  const handleFeatureDrop = (e) => {
    handleImageDrop(e, "featured_media", "featuredImg");
  };

  const handleExtraFieldRow = () => {
    const updatedFields = {
      ...medDetails,
      acf: {
        ...medDetails.acf,
        extra_fields: medDetails.acf.extra_fields
          ? [...medDetails.acf.extra_fields, extraFeildObj]
          : [extraFeildObj],
      },
    };

    setMedDetails(updatedFields);
  };
  const handleRemoveRow = (index) => {
    const updatedFields = {
      ...medDetails,
      acf: {
        ...medDetails.acf,
        extra_fields: medDetails.acf.extra_fields.filter((_, i) => i !== index),
      },
    };
    setMedDetails(updatedFields);
  };

  const handleCategoryChange = (categoryId) => {
    setMedDetails((prevDetails) => {
      const updatedCategories = prevDetails.meditation_category.includes(
        categoryId
      )
        ? prevDetails.meditation_category.filter((id) => id !== categoryId)
        : [...prevDetails.meditation_category, categoryId];

      return {
        ...prevDetails,
        meditation_category: updatedCategories,
      };
    });
  };
  const handleWebsiteChange = (siteId) => {
    setMedDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };
  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateMeditation(params?.id, medDetails);
    } else {
      res = await createMeditation(medDetails);
    }
    if (res) {
      navigate(`/meditations/${res?.id}`);
      setPublishSuccess(true);
    }
  };

  useEffect(() => {
    const fetchPostData = async () => {
      const response = await getMeditationData(params?.id);
      let featuredimgData = "";
      let bannerimgData = "";
      let squareimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);
      if (response?.acf?.banner)
        bannerimgData = await fetchMedia(response?.acf?.banner);
      if (response?.acf?.square_image)
        squareimgData = await fetchMedia(response?.acf?.square_image);

      setImages({
        bannerImg: bannerimgData?.source_url,
        squareImg: squareimgData?.source_url,
        featuredImg: featuredimgData?.source_url,
      });
      seLoading(false);
      setMedDetails({
        ...medDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        excerpt: response?.excerpt,
        meditation_category: response?.meditation_category || [],
        extra_pages_cat: response?.extra_pages_cat || [],
        featured_media: featuredimgData?.id || null,
        acf: {
          banner: bannerimgData?.id || null,
          audio: response?.acf?.audio,
          square_image: squareimgData?.id || null,
          type: response?.acf?.type || "Free",
          linked_product: response?.acf?.linked_product,
          extra_fields: response?.acf?.extra_fields || [],
          watch_instructions: response?.acf?.watch_instructions,
        },
      });
    };

    if (params?.id) {
      fetchPostData();
    } else {
      setMedDetails(initialDetails);
      setImages({
        bannerImg: "",
        squareImg: "",
        featuredImg: "",
      });
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getMeditationCategories();
      setCategories(response.data);
    };
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    const fetchLinkedProd = async () => {
      const response = await getMedLinkedProducts();
      setLinkedProducts(response);
    };
    fetchCategories();
    fetchWebsites();
    fetchLinkedProd();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Meditation" : "Add New Meditation"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={medDetails.status}
              onChange={(e) =>
                setMedDetails({
                  ...medDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={medDetails.title}
                        onChange={(e) =>
                          setMedDetails({
                            ...medDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={medDetails.content}
                        onChange={(value) =>
                          setMedDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Meditation" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InputLabel>Type</InputLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group"
                        name="event_type"
                        value={medDetails?.acf?.type}
                        onChange={(e) =>
                          setMedDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              type: e.target.value,
                            },
                          }))
                        }
                      >
                        <FormControlLabel
                          value="Free"
                          control={<Radio />}
                          label="Free"
                        />
                        <FormControlLabel
                          value="Paid"
                          control={<Radio />}
                          label="Paid"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                      {medDetails?.acf?.type === "Paid" && (
                        <>
                          <InputLabel>Linked Product</InputLabel>
                          <Autocomplete
                            fullWidth
                            id="autocomplete-Product"
                            options={linkedProducts}
                            getOptionLabel={(option) => option.name}
                            value={
                              medDetails?.acf?.linked_product !== ""
                                ? linkedProducts.find(
                                    (prod) =>
                                      prod.id ===
                                      medDetails?.acf?.linked_product
                                  ) || null
                                : null
                            }
                            onChange={(e, newValue) =>
                              setMedDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  linked_product: newValue ? newValue.id : null,
                                },
                              }))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select"
                                fullWidth
                              />
                            )}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Audio Track</InputLabel>
                      <TextField
                        placeholder="Audio URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="audiourl"
                        value={medDetails.acf.audio}
                        onChange={(e) =>
                          setMedDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              audio: e.target.value,
                            },
                          }))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Public />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Banner Image</InputLabel>
                      {!Images.bannerImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleBannerDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("banner-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{ position: "relative" }}
                          onMouseEnter={() => setIsBannerHovered(true)}
                          onMouseLeave={() => setIsBannerHovered(false)}
                        >
                          <img
                            src={Images.bannerImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isBannerHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  bannerImg: "",
                                }));
                                setMedDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    banner: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="banner-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleBannerChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Square Image</InputLabel>
                      {!Images.squareImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleSquareDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("square-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsSqureHovered(true)}
                          onMouseLeave={() => setIsSqureHovered(false)}
                        >
                          <img
                            src={Images.squareImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isSqureHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  squareImg: "",
                                }));
                                setMedDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    square_image: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="square-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleSquareChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>Extra Fields</InputLabel>
                      <Box
                        sx={{
                          padding: "10px",
                          background: "#ebebeb",
                          margin: "10px 0",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {medDetails.acf?.extra_fields &&
                        medDetails.acf?.extra_fields?.length !== 0 ? (
                          medDetails.acf?.extra_fields.map((row, index) => (
                            <Paper
                              key={index}
                              sx={{
                                padding: "20px",
                                position: "relative",
                                margin: "5px",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <InputLabel>Type</InputLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="radio-buttons-group"
                                    name="event_type"
                                    value={row.type}
                                    onChange={(e) =>
                                      setMedDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          extra_fields:
                                            prevDetails.acf.extra_fields.map(
                                              (item, i) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      type: e.target.value,
                                                    }
                                                  : item
                                            ),
                                        },
                                      }))
                                    }
                                  >
                                    <FormControlLabel
                                      value="Popup"
                                      control={<Radio />}
                                      label="Popup"
                                    />
                                    <FormControlLabel
                                      value="Link"
                                      control={<Radio />}
                                      label="Link"
                                    />
                                  </RadioGroup>
                                </Grid>
                                <Grid item xs={4}>
                                  <InputLabel>Button Name</InputLabel>
                                  <TextField
                                    placeholder="Name"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="name"
                                    value={row.button_name}
                                    onChange={(e) =>
                                      setMedDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          extra_fields:
                                            prevDetails.acf.extra_fields.map(
                                              (item, i) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      button_name:
                                                        e.target.value,
                                                    }
                                                  : item
                                            ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <InputLabel>Button Link</InputLabel>
                                  <TextField
                                    placeholder="Link"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="link"
                                    value={row.button_link}
                                    onChange={(e) =>
                                      setMedDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          extra_fields:
                                            prevDetails.acf.extra_fields.map(
                                              (item, i) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      button_link:
                                                        e.target.value,
                                                    }
                                                  : item
                                            ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: "95%",
                                }}
                                onClick={() => handleRemoveRow(index)}
                              >
                                <Cancel />
                              </IconButton>
                            </Paper>
                          ))
                        ) : (
                          <Typography>No Rows</Typography>
                        )}
                      </Box>
                      <Button variant="contained" onClick={handleExtraFieldRow}>
                        Add Row
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Watch Instructions" />
              <CardContent>
                <InputLabel>Watch Instructions</InputLabel>
                <TextField
                  placeholder="Watch Instructions URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="instructionsurl"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public />
                      </InputAdornment>
                    ),
                  }}
                  value={medDetails?.acf?.watch_instructions}
                  onChange={(e) =>
                    setMedDetails((prevDetails) => ({
                      ...prevDetails,
                      acf: {
                        ...prevDetails.acf,
                        watch_instructions: e.target.value,
                      },
                    }))
                  }
                />
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Excerpts" />
              <CardContent>
                <TextField
                  placeholder="Excerpts"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="excerpts"
                  multiline
                  rows={3}
                  value={medDetails?.excerpt}
                  onChange={(e) =>
                    setMedDetails({
                      ...medDetails,
                      excerpt: e?.target?.value,
                    })
                  }
                />
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card>
              <CardHeader title="Meditation Categories" />
              <CardContent>
                <FormGroup>
                  {categories &&
                    categories?.map((category) => (
                      <FormControlLabel
                        key={category?.id}
                        control={
                          <Checkbox
                            checked={medDetails?.meditation_category?.includes(
                              category.id
                            )}
                            onChange={() => handleCategoryChange(category.id)}
                          />
                        }
                        label={category?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={medDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Tags" />
              <CardContent>
                <TextField
                  placeholder="Tag"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="contained" onClick={handleAddTag}>
                          Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                />
                <Typography variant="caption">
                  Separate tags with commas.
                </Typography>
                <Box>
                  {tags?.map((tag) => (
                    <Chip
                      sx={{ padding: "0 5px", margin: "0 5px" }}
                      label={tag}
                      onDelete={() =>
                        setTags((prevTags) => prevTags.filter((t) => t !== tag))
                      }
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
            {/* <Card sx={{ marginTop: "20px" }}>
            <CardHeader title="Meditation Attributes" />
            <CardContent>
              <InputLabel>Parrent</InputLabel>
              <Select
                sx={{ mt: "5px" }}
                fullWidth
                labelId="select-parrent"
                id="select-parrent"
                placeholder="Select"
                value={0}
              >
                <MenuItem value={0}>No parrent</MenuItem>
                <MenuItem value={10}>Demo 1</MenuItem>
                <MenuItem value={20}>Demo 2</MenuItem>
              </Select>
              <InputLabel sx={{ marginTop: "10px" }}>Order</InputLabel>
              <TextField
                type="nubmer"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card> */}
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!Images.featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={Images.featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setImages((pre) => ({
                            ...pre,
                            featuredImg: "",
                          }));
                          setMedDetails((prevDetails) => ({
                            ...prevDetails,
                            featured_media: null,
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddMeditation;
