import { axiosInstance } from "./axiosInstence";

export const getAllWebinars = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `webinar?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
export const getWebinarData = async(id)=>{
    const response = await axiosInstance
    .get(`webinar/${id}`)
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}

export const getWebinarCategories = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `event_category?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
      );
      const totalCount = response.headers.get("X-Wp-Total");
      const totalPages = response.headers.get("X-Wp-TotalPages");
      return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};

// export const deleteWebinar= async(id)=>{
//     const response = await axiosInstance
//     .delete(`webinar/${id}`)
//     .then((res) => res.data)
//     .catch((err)=>err.message);
//   return response;
// }

export const updateWebinar = async (id, data) => {
  const response = await axiosInstance
    .post(`webinar/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const createWebinar = async (data) => {
    const response = await axiosInstance
      .post("webinar", data)
      .then((res) => res.data)
      .catch((err) => null);
    return response;
  };
  
  export const updateWebinarCategory = async (data) => {
    const response = await axiosInstance
      .post(`event_category/${data?.id}`,data)
      .then((res) => res.data)
      .catch((err) => err.message);
    return response;
  }
  export const addWebinarCategory = async (data) => {
    const response = await axiosInstance
      .post("event_category",data)
      .then((res) => res.data)
      .catch((err) => err.message);
    return response;
  }
  // export const deleteWebinarCategory = async (id) => {
  //   const response = await axiosInstance
  //     .delete(`event_category/${id}?force=1`,)
  //     .then((res) => res.data)
  //     .catch((err) => err.message);
  //   return response;
  // }
  export const getSelectedtypeData = async(url)=>{
    const response = await axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}