import { axiosInstance_WC, axiosInstance_custom } from "./axiosInstence";

export const getAllUsers = async (page, perPage, searchText) => {
  try {
    const response = await axiosInstance_custom.get(
      `list-users?page=${page || 1}&per_page=${perPage || 10}&search=${
        searchText || ""
      }`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};
export const getUserData = async (id) => {
  try {
    const response = await axiosInstance_custom.get(`user/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getRoles = async (id) => {
  try {
    const response = await axiosInstance_custom.get("roles");
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axiosInstance_custom.post(`update-user/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const addUser = async (data) => {
  try {
    const response = await axiosInstance_custom.post(`add-user`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};


export const getWalletTransactions = async (email) => {
  try {
    const response = await axiosInstance_WC.get(`wallet?email=${email}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};


export const getWalletBallence = async (email) => {
  try {
    const response = await axiosInstance_WC.get(`wallet/balance?email=${email}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};


export const AddTransaction = async (data) => {
  try {
    const response = await axiosInstance_WC.post(`wallet`,data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
