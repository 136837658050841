import { axiosInstance } from "./axiosInstence";

export const getAllChallenges = async (page,perPage,seachText) => {
  try {
    const response = await axiosInstance.get(`challenges?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText}`);
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
export const getChallengeData = async (id) => {
  try {
    const response = await axiosInstance.get(`challenges/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

// export const deteleChallenge = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`challenges/${id}`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
export const updateChallenge = async (id, data) => {
  try {
    const response = await axiosInstance.post(`challenges/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const createChallenge = async (data) => {
  try {
    const response = await axiosInstance.post("challenges", data);
    if (data.featured_media)
      axiosInstance.post(
        `media/${data.featured_media}?challenges=${response?.data?.id}`
      );
    return response.data;
  } catch (err) {
    throw err;
  }
};
