import { Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  addMeditationCategory,
  deleteMeditationCategory,
  updateMeditationCategory,
  getMeditationCategories,
} from "../../services/meditation.service";
import AlertDialog from "../../common/AlertDialog";
import React, { useEffect, useState } from "react";
// import { getMeditationCategories } from "../../services/meditation.service";
function MeditationsCategories(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [categories, setCategories] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [search ,setSearch]= useState("");

  const [cat, setCat] = useState({
    name: "",
    description: "",
    slug: "",
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 180,
    },

    { field: "description", headerName: "Description", width: 180 },
    {
      field: "slug",
      headerName: "Slug",
      // type: "number",
      width: 180,
    },
    {
      field: "count",
      headerName: "Count",
      // type: "string",
      width: 180,
    },
    {
      // field: "",
      // headerName: "",
      width: 100,
      renderCell: (params) =>
        hoveredRowId === params?.id && (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => setCat(params?.row)}>
              <Edit />
            </IconButton>
            {/* <IconButton
              onClick={() => {
                setAlert(true);
                setRowId(params.id);
              }}
            >
              <Delete />
            </IconButton> */}
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];


  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const handleRowHover = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(Number(rowId));
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );
  };
  const handleRowLeaved = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(null);
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: false } })
    );
  };
  const handleSubmit = async () => {
    if (cat?.id) {
      await updateMeditationCategory(cat);
    } else {
      await addMeditationCategory(cat);
    }
    setCat({
      id: null,
      name: "",
      description: "",
      slug: "",
    });
    fetchCategories();
  };

  // const handleDelete = async () => {
  //   await deleteMeditationCategory(rowId);
  //   setAlert(false);
  //   fetchCategories();
  // };
  const fetchCategories = async () => {
    try {
      const response = await getMeditationCategories(currentPage, itemsPerPage,search);
      const catRows = response.data.map((cat) => ({
        id: cat?.id,
        name: cat?.name,
        description: cat?.description,
        slug: cat?.slug,
        count: cat?.count,
      }));
      setRows(catRows);
      setTotalCount(response.totalCount);
      setCategories(response);
    } catch (error) {
      console.error("Error fetching video categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [currentPage, itemsPerPage,search]);
  return (
    <Container>
      <Box
        sx={{
          padding: "15px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Categories</Typography>

        {cat?.id && (
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
            }}
            onClick={() =>
              setCat({
                id: null,
                name: "",
                description: "",
                slug: "",
              })
            }
          >
            Add
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "28%" }}>
          <Card>
            <CardHeader
              title={cat?.id ? "Edit Category" : "Add New Category"}
            />
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      placeholder="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Name"
                      value={cat.name}
                      onChange={(e) =>
                        setCat((prev) => ({ ...prev, name: e.target.value }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Slug</InputLabel>
                    <TextField
                      placeholder="Slug"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Slug"
                      value={cat.slug}
                      onChange={(e) =>
                        setCat((prev) => ({ ...prev, slug: e.target.value }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Description</InputLabel>
                    <TextField
                      placeholder="Description"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Description"
                      multiline
                      rows={4}
                      value={cat.description}
                      onChange={(e) =>
                        setCat((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {cat?.id ? "Update" : "Submit"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ width: "70%" }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  padding: "15px 0",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <Search />
                  </IconButton>
                </Paper>
              </Box>
              <DataGrid
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: itemsPerPage,
                    },
                  },
                }}
                pagination
                paginationMode="server"
                pageSize={itemsPerPage}
                rowCount={totalCount}
                pageSizeOptions={[10, 20, 50]}
                onPaginationModelChange={(params) => handlePageChange(params)}
                disableRowSelectionOnClick
                slotProps={{
                  row: {
                    onMouseEnter: handleRowHover,
                    onMouseLeave: handleRowLeaved,
                  },
                }}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
      {/* <AlertDialog
        open={alert}
        setOpen={(isOpen) => setAlert(isOpen)}
        handleConfirm={handleDelete}
        dialogText="Are you sure you want to delete?"
      /> */}
    </Container>
  );
}

export default MeditationsCategories;
