import { axiosInstance } from "./axiosInstence";

export const getAllVideos = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `videos?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
export const getVideoData = async (id) => {
  try {
    const response = await axiosInstance.get(`videos/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getVideoCategories = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `video_category?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
      );
      const totalCount = response.headers.get("X-Wp-Total");
      const totalPages = response.headers.get("X-Wp-TotalPages");
      return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};

// export const deteleVideo = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`videos/${id}`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const updateVideo = async (id, data) => {
  try {
    const response = await axiosInstance.post(`videos/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const createVideo = async (data) => {
  try {
    const response = await axiosInstance.post("videos", data);
    if (data.featured_media)
      axiosInstance.post(
        `media/${data.featured_media}?videos=${response?.data?.id}`
      );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateVideoCategory = async (data) => {
  try {
    const response = await axiosInstance.post(
      `video_category/${data?.id}`,
      data
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const addVideoCategory = async (data) => {
  try {
    const response = await axiosInstance.post("video_category", data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
// export const deleteVideoCategory = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`video_category/${id}?force=1`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
