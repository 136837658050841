import {
    combineReducers,
    configureStore,
  } from "@reduxjs/toolkit";
  import { persistStore, persistReducer } from "redux-persist";
  import storage from "redux-persist/lib/storage";
  import { encryptTransform } from "redux-persist-transform-encrypt";
import authSlice from "./Slice/authSlice";
  
  const encryptionKey = "Guru_Athmananda";
  
  const persistConfig = {
    key: "root",
    storage,
    transforms: [encryptTransform({ secretKey: encryptionKey })],
  };
  const rootReducer = combineReducers({
    auth: authSlice,
  });
  
  const resettableRootReducer = (state, action) => {
    if (action.type === "logout") {
      state = undefined;
    }
    return rootReducer(state, action);
  };
  const persistedReducer = persistReducer(persistConfig, resettableRootReducer);
  
  const store = configureStore({
    reducer: persistedReducer,
  });
  
  const persistor = persistStore(store);
  
  export { store, persistor };
  