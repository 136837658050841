import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled } from "@mui/system";
import {
  AddTransaction,
  addUser,
  getRoles,
  getUserData,
  getWalletBallence,
  getWalletTransactions,
  updateUser,
} from "../../services/users.service";
import { useNavigate, useParams } from "react-router-dom";
import { getBadges } from "../../services/badges.service";
import { DataGrid } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import AutoSnackbar from "../../common/AutoSnackbar";

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddNewUser = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [userDetails, setDetails] = useState({});
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [userBalance, setUserBalance] = useState(null);
  const [badges, setBadges] = useState([]);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [rows, setRows] = useState([]);
  const [openDialog, setDialog] = useState(false);
  const [transactionObj, setTransactionObj] = useState({
    email: "",
    type: "credit",
    amount: null,
    note: "",
  });
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const columns = [
    { field: "transaction_id", headerName: "Transaction_ID", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    {
      field: "amount",
      headerName: "Amount",
      // type: "number",
      width: 200,
      renderCell: (params) => {
        const formattedNumber = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: params.row.currency || "INR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.value);
        return <div>{formattedNumber}</div>;
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      renderCell: (params) => {
        const formattedNumber = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: params.row.currency || "INR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(params.value);
        return <div>{formattedNumber}</div>;
      },
    },
    {
      field: "details",
      headerName: "Notes",
      width: 200,
    },
    {
      field: "date",
      headerName: "Date",
      width: 250,
    },
  ];
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const handlePageChange = (data) => {
    setPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const generateRandomPassword = () => {
    const charset =
      "!@#$%&*0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 10;
    let randomPassword = "";

    for (let i = 0; i < passwordLength; i++) {
      randomPassword += charset.charAt(
        Math.floor(Math.random() * charset.length)
      );
    }

    setDetails({ ...userDetails, user_pass: randomPassword });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = null;
    if (params.id) {
      res = await updateUser(params.id, userDetails);
    } else {
      res = await addUser(userDetails);
    }
    if (res) {
      navigate(`/users/${res?.user_id}`);
      setPublishSuccess(true);
    }
  };
  const handleAddTrasaction = async () => {
    const res = await AddTransaction(transactionObj);
    if (res) {
      setTransactionObj({
        ...transactionObj,
        type: "credit",
        amount: null,
        note: "",
      });
      setDialog(false);
      handleWallet(userDetails?.user_email);
    }
  };
  const handleWallet = async (email) => {
    const actBalance = await getWalletBallence(email);
    const formattedBalance = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: actBalance?.currency || "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(actBalance?.balance || 0);
    setUserBalance(formattedBalance);
    const res = await getWalletTransactions(email);
    setRows(res);
    setTransactionObj({ ...transactionObj, email: email });
  };
  useEffect(() => {
    const fetchUserData = async () => {
      const response = await getUserData(params?.id);
      setDetails({
        first_name: response?.meta?.first_name,
        last_name: response?.meta?.last_name,
        user_email: response?.user_email,
        acf: {
          badge: response?.acf?.badge || [],
        },
        roles: response?.roles || [],
      });
      setLoading(false);
      handleWallet(response?.user_email);
    };
    if (params?.id) {
      fetchUserData();
    } else {
      setDetails({});
      setLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchRoles = async () => {
      const res = await getRoles();
      console.log(res);
      setRoles(res);
    };
    const fetchBadges = async () => {
      await getBadges().then((res) => {
        setBadges(res.data);
      });
    };
    fetchRoles();
    fetchBadges();
  }, []);

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {publishSuccess && (
        <AutoSnackbar
          message={params.id ? "Updated Successfully" : "Added Successfully"}
          severity="success"
        />
      )}
      <Box
        sx={{
          position: "sticky",
          top: "61px",
          background: "#f1f1f1",
          zIndex: 100,
          margin:" 0 -2px",
          padding: "15px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">
          {" "}
          {params.id ? "Edit User Details" : "Add New User"}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: "15%",
            fontSize: "16px",
            fontWeight: 500,
          }}
          onClick={handleSubmit}
        >
          {params.id ? "Update User" : "Add User"}
        </Button>
      </Box>
      <Card sx={{mt:"5px"}}>
        <CardContent>
          <form autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputLabel>First Name</InputLabel>
                <TextField
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={userDetails?.first_name || ""}
                  onChange={(e) =>
                    setDetails({ ...userDetails, first_name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Last Name</InputLabel>
                <TextField
                  placeholder="Last Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={userDetails?.last_name || ""}
                  onChange={(e) =>
                    setDetails({ ...userDetails, last_name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Email *</InputLabel>
                <TextField
                  placeholder="Enter User Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoComplete="new-password"
                  margin="normal"
                  value={userDetails?.user_email || ""}
                  onChange={(e) =>
                    setDetails({ ...userDetails, user_email: e.target.value })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <InputLabel>Roles</InputLabel>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={roles}
                  disableCloseOnSelect
                  value={roles.filter((role) =>
                    userDetails?.roles?.includes(role.id)
                  )}
                  onChange={(e, newRoles) =>
                    setDetails({
                      ...userDetails,
                      roles: newRoles.map((role) => role.id),
                    })
                  }
                  getOptionLabel={(option) => option?.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Roles" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Badge</InputLabel>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={badges}
                  disableCloseOnSelect
                  value={badges?.filter((badge) =>
                    userDetails?.acf?.badge?.includes(badge.id)
                  )}
                  onChange={(event, newBadges) => {
                    setDetails({
                      ...userDetails,
                      acf: {
                        ...userDetails.acf,
                        badge: newBadges.map((badge) => badge.id),
                      },
                    });
                  }}
                  getOptionLabel={(option) => option?.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Badge" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Password</InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    name="new-password"
                    placeholder="Password"
                    variant="outlined"
                    margin="normal"
                    autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    value={userDetails?.user_pass || ""}
                    onChange={(e) =>
                      setDetails({ ...userDetails, user_pass: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ ml: "10px" }}
                    onClick={generateRandomPassword}
                  >
                    Generate Password
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Card sx={{ mt: "20px", display: !params.id && "none" }}>
        <CardHeader title="Wallet" />
        <CardContent>
          <Box
            sx={{
              padding: "15px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: "600", mr: "20px" }}>
                Account Balance : {userBalance}
              </Typography>
              <Button
                onClick={() => {
                  setDialog(true);
                  setTransactionObj({
                    ...transactionObj,
                    type: "credit",
                    amount: null,
                    note: "",
                  });
                }}
              >
                Credit / Debit
              </Button>
            </Box>
          </Box>
          {rows.length > 0 ? (
            <DataGrid
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
              rows={filteredRows.slice(
                (page - 1) * itemsPerPage,
                page * itemsPerPage
              )}
              columns={columns}
              disableRowSelectionOnClick
              getRowId={(row) => row.transaction_id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: itemsPerPage,
                  },
                },
              }}
              pagination
              paginationMode="server"
              pageSize={itemsPerPage}
              rowCount={filteredRows?.length}
              pageSizeOptions={[5, 10, 20, 50]}
              onPaginationModelChange={(params) => handlePageChange(params)}
            />
          ) : (
            <Typography
              sx={{ textAlign: "center", fontSize: "24px", color: "#b3b3b3" }}
            >
              No Transactions
            </Typography>
          )}
        </CardContent>
        <Dialog open={openDialog}>
          <DialogTitle>Add Transaction</DialogTitle>
          <DialogContent sx={{ mt: "10px" }}>
            <RadioGroup
              row
              aria-label="transaction-type"
              name="transaction-type"
              value={transactionObj?.type || "credit"}
              onChange={(e) =>
                setTransactionObj({ ...transactionObj, type: e.target.value })
              }
            >
              <FormControlLabel
                value="credit"
                control={<Radio />}
                label="Credit"
              />
              <FormControlLabel
                disabled={userBalance === "₹0.00"}
                value="debit"
                control={<Radio />}
                label="Debit"
              />
            </RadioGroup>
            <TextField
              placeholder="Amount"
              type="number"
              fullWidth
              value={transactionObj?.amount || null}
              onChange={(e) =>
                setTransactionObj({ ...transactionObj, amount: e.target.value })
              }
            />
            <TextField
              placeholder="Notes"
              multiline
              rows={3}
              fullWidth
              value={transactionObj?.note || ""}
              onChange={(e) =>
                setTransactionObj({ ...transactionObj, note: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialog(false);
                setTransactionObj({
                  ...transactionObj,
                  type: "credit",
                  amount: null,
                  note: "",
                });
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleAddTrasaction} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Container>
  );
};

export default AddNewUser;
