import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Cancel, CloudUpload, Delete, Public } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { createMedia, fetchMedia } from "../../services/media.service";
import {
  createPodcast,
  getPodcastCategories,
  getPodcastData,
  updatePodcast,
} from "../../services/podcast.service";
import { getWebsites } from "../../services/extraSites.service";
import AutoSnackbar from "../../common/AutoSnackbar";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddPodcast(props) {
  const navigate = useNavigate();
  const params = useParams();
  const initialField = {
    id: 1,
    title: "",
    description: "",
  };
  const [podcastAudios, setPodcastAudiosRows] = useState([]);
  const [isSqureHovered, setIsSqureHovered] = useState(false);
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [categories, setCategories] = useState(null);
  const [websites, setWebsites] = useState(null);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const podcastAdudioObj = {
    podcast_name: "",
    podcast_link: "",
  };
  const initialDetails = {
    title: "",
    content: "",
    podcast_category: [],
    acf: {
      track: "",
      duration: "",
      podcast_adudios: [],
      banner: null,
      square_image: null,
    },
    extra_pages_cat: [],
    featured_media: null,
    excerpt: "",
    status: "publish",
  };
  const [podcastDetails, setPodcastDetails] = useState(initialDetails);
  const [Images, setImages] = useState({
    bannerImg: "",
    squareImg: "",
    featuredImg: "",
  });

  const handleImageChange = async (file, acfKey, mediaKey) => {
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          [mediaKey]: reader.result,
        }));
        if (acfKey === "featured_media") {
          setPodcastDetails((prevDetails) => ({
            ...prevDetails,
            [acfKey]: res.id,
          }));
        } else
          setPodcastDetails((prevDetails) => ({
            ...prevDetails,
            acf: {
              ...prevDetails.acf,
              [acfKey]: res.id,
            },
          }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDrop = async (e, acfKey, mediaKey) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    await handleImageChange(file, acfKey, mediaKey);
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "banner", "bannerImg");
  };

  const handleBannerDrop = (e) => {
    handleImageDrop(e, "banner", "bannerImg");
  };

  const handleSquareChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "square_image", "squareImg");
  };

  const handleSquareDrop = (e) => {
    handleImageDrop(e, "square_image", "squareImg");
  };

  const handleFeatureChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "featured_media", "featuredImg");
  };

  const handleFeatureDrop = (e) => {
    handleImageDrop(e, "featured_media", "featuredImg");
  };

  const handlePodcastRow = () => {
    const updatedFields = {
      ...podcastDetails,
      acf: {
        ...podcastDetails.acf,
        podcast_adudios: podcastDetails.acf.podcast_adudios
          ? [...podcastDetails.acf.podcast_adudios, podcastAdudioObj]
          : [podcastAdudioObj],
      },
    };

    setPodcastDetails(updatedFields);
  };
  const handleRemoveRow = (index) => {
    const updatedFields = {
      ...podcastDetails,
      acf: {
        ...podcastDetails.acf,
        podcast_adudios: podcastDetails.acf.podcast_adudios.filter(
          (_, i) => i !== index
        ),
      },
    };
    setPodcastDetails(updatedFields);
  };
  const handleCategoryChange = (categoryId) => {
    setPodcastDetails((prevDetails) => {
      const updatedCategories = prevDetails.podcast_category.includes(
        categoryId
      )
        ? prevDetails.podcast_category.filter((id) => id !== categoryId)
        : [...prevDetails.podcast_category, categoryId];

      return {
        ...prevDetails,
        podcast_category: updatedCategories,
      };
    });
  };
  const handleWebsiteChange = (siteId) => {
    setPodcastDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };
  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updatePodcast(params?.id, podcastDetails);
    } else {
      res = await createPodcast(podcastDetails);
      navigate(`/podcast/${res?.id}`);
    }
    if (res) {
      setPublishSuccess(true);
    }
  };

  useEffect(() => {
    const fetchPodcastData = async () => {
      const response = await getPodcastData(params?.id);
      let featuredimgData = "";
      let bannerimgData = "";
      let squareimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);
      if (response?.acf?.banner)
        bannerimgData = await fetchMedia(response?.acf?.banner);
      if (response?.acf?.square_image)
        squareimgData = await fetchMedia(response?.acf?.square_image);

      setImages({
        bannerImg: bannerimgData?.source_url,
        squareImg: squareimgData?.source_url,
        featuredImg: featuredimgData?.source_url,
      });
      seLoading(false);
      setPodcastDetails({
        ...podcastDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        excerpt: response?.excerpt,
        podcast_category: response?.podcast_category,
        extra_pages_cat: response?.extra_pages_cat,
        featured_media: featuredimgData?.id || null,
        acf: {
          banner: bannerimgData?.id || null,
          square_image: squareimgData?.id || null,
          track: response?.acf?.track,
          duration: response?.acf?.duration,
          podcast_adudios: response?.acf?.podcast_adudios,
        },
      });
    };

    if (params?.id) {
      fetchPodcastData();
    } else {
      setPodcastDetails(initialDetails);
      setImages({
        bannerImg: "",
        squareImg: "",
        featuredImg: "",
      });
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getPodcastCategories();
      setCategories(response.data);
    };
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    fetchCategories();
    fetchWebsites();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Podcast" : "Add New Podcast"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={podcastDetails.status}
              onChange={(e) =>
                setPodcastDetails({
                  ...podcastDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={podcastDetails.title}
                        onChange={(e) =>
                          setPodcastDetails({
                            ...podcastDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={podcastDetails.content}
                        onChange={(value) =>
                          setPodcastDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Podcast" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Track</InputLabel>
                      <TextField
                        placeholder="Track"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="track"
                        value={podcastDetails?.acf?.track}
                        onChange={(e) =>
                          setPodcastDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              track: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Duration</InputLabel>
                      <TextField
                        placeholder="Duration"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="duration"
                        // type="number"
                        value={podcastDetails?.acf?.duration}
                        onChange={(e) =>
                          setPodcastDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              duration: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Banner Image</InputLabel>
                      {!Images.bannerImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleBannerDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("banner-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{ position: "relative" }}
                          onMouseEnter={() => setIsBannerHovered(true)}
                          onMouseLeave={() => setIsBannerHovered(false)}
                        >
                          <img
                            src={Images.bannerImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isBannerHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  bannerImg: "",
                                }));
                                setPodcastDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    banner: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="banner-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleBannerChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Square Image</InputLabel>
                      {!Images.squareImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleSquareDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("square-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsSqureHovered(true)}
                          onMouseLeave={() => setIsSqureHovered(false)}
                        >
                          <img
                            src={Images.squareImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isSqureHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  squareImg: "",
                                }));
                                setPodcastDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    square_image: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="square-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleSquareChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>Podcast Audios</InputLabel>
                      <Box
                        sx={{
                          padding: "10px",
                          background: "#ebebeb",
                          margin: "10px 0",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {podcastDetails.acf?.podcast_adudios &&
                        podcastDetails.acf?.podcast_adudios?.length !== 0 ? (
                          podcastDetails.acf?.podcast_adudios.map(
                            (row, index) => (
                              <Paper
                                key={index}
                                sx={{
                                  padding: "20px",
                                  position: "relative",
                                  margin: "5px",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <InputLabel>Podcast Name</InputLabel>
                                    <TextField
                                      placeholder="Name"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      name="name"
                                      value={row.podcast_name}
                                      onChange={(e) =>
                                        setPodcastDetails((prevDetails) => ({
                                          ...prevDetails,
                                          acf: {
                                            ...prevDetails.acf,
                                            podcast_adudios:
                                              prevDetails.acf.podcast_adudios.map(
                                                (item, i) =>
                                                  i === index
                                                    ? {
                                                        ...item,
                                                        podcast_name:
                                                          e.target.value,
                                                      }
                                                    : item
                                              ),
                                          },
                                        }))
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <InputLabel>Podcast Link</InputLabel>
                                    <TextField
                                      placeholder="URL"
                                      variant="outlined"
                                      fullWidth
                                      margin="normal"
                                      name="link"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <Public />
                                          </InputAdornment>
                                        ),
                                      }}
                                      value={row.podcast_link}
                                      onChange={(e) =>
                                        setPodcastDetails((prevDetails) => ({
                                          ...prevDetails,
                                          acf: {
                                            ...prevDetails.acf,
                                            podcast_adudios:
                                              prevDetails.acf.podcast_adudios.map(
                                                (item, i) =>
                                                  i === index
                                                    ? {
                                                        ...item,
                                                        podcast_link:
                                                          e.target.value,
                                                      }
                                                    : item
                                              ),
                                          },
                                        }))
                                      }
                                    />
                                  </Grid>
                                </Grid>
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: "95%",
                                  }}
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  <Cancel />
                                </IconButton>
                              </Paper>
                            )
                          )
                        ) : (
                          <Typography>No Rows</Typography>
                        )}
                      </Box>
                      <Button variant="contained" onClick={handlePodcastRow}>
                        Add Row
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Excerpts" />
              <CardContent>
                <TextField
                  placeholder="Excerpts"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="excerpts"
                  multiline
                  rows={3}
                  value={podcastDetails?.excerpt}
                  onChange={(e) =>
                    setPodcastDetails({
                      ...podcastDetails,
                      excerpt: e?.target?.value,
                    })
                  }
                />
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card>
              <CardHeader title="Podcast Categories" />
              <CardContent>
                <FormGroup>
                  {categories &&
                    categories?.map((category) => (
                      <FormControlLabel
                        key={category?.id}
                        control={
                          <Checkbox
                            checked={podcastDetails?.podcast_category?.includes(
                              category.id
                            )}
                            onChange={() => handleCategoryChange(category.id)}
                          />
                        }
                        label={category?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={podcastDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            {/* <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Podcast Attributes" />
              <CardContent>
                <InputLabel>Parrent</InputLabel>
                <Select
                  sx={{ mt: "5px" }}
                  fullWidth
                  labelId="select-parrent"
                  id="select-parrent"
                  placeholder="Select"
                  value={0}
                >
                  <MenuItem value={0}>No parrent</MenuItem>
                  <MenuItem value={10}>Demo 1</MenuItem>
                  <MenuItem value={20}>Demo 2</MenuItem>
                </Select>
                <InputLabel sx={{ marginTop: "10px" }}>Order</InputLabel>
                <TextField
                  type="nubmer"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </CardContent>
            </Card> */}
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!Images.featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={Images.featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setImages((pre) => ({
                            ...pre,
                            featuredImg: "",
                          }));
                          setPodcastDetails((prevDetails) => ({
                            ...prevDetails,
                            featured_media: null,
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddPodcast;
