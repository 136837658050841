import { axiosInstance } from "./axiosInstence";

export const getPrograms = async()=>{
    const response = await axiosInstance
    .get("programs")
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}
export const getProgramData = async(id)=>{
  const response = await axiosInstance
  .get(`programs/${id}`)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
// export const detelePrograms = async (id) => {
//   const response = await axiosInstance
//     .delete(`programs/${id}?force=true`)
//     .then((res) => res.data)
//     .catch((err) => err.message);
//   return response;
// };
export const updatePrograms= async (id, data) => {
  const response = await axiosInstance
    .post(`programs/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const addProgram = async (data) => {
  const response = await axiosInstance
    .post("programs", data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const getProgramCourses = async (page,perPage,id) => {
  try {
    const response = await axiosInstance.get(`courses?programs=${id}&page=${page || 1}&per_page=${perPage || 10}`)
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};