import {axiosInstance_WC } from "./axiosInstence";

export const getAllProducts = async (page, perPage) => {
    try {
      const response = await axiosInstance_WC.get(
        `products?page=${page || 1}&per_page=${perPage || 10}`
      );
      const totalCount = response.headers.get("X-Wp-Total");
      const totalPages = response.headers.get("X-Wp-TotalPages");
      return {data:response.data,totalCount:totalCount,totalPages:totalPages};
    } catch (err) {
      throw err;
    }
  };