import { Delete, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
function ChallengeTag(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      width: 180,
    },

    { field: "Description", headerName: "Description", width: 180 },
    {
      field: "Slug",
      headerName: "Slug",
      // type: "number",
      width: 180,
    },
    {
      field: "Count",
      headerName: "Count",
      // type: "string",
      width: 180,
    },
    {
      // field: "",
      // headerName: "",
      width: 100,
      renderCell: (params) =>
        hoveredRowId === params.id && (
          <Box sx={{ display: "flex" }}>
            <IconButton>
              <Edit />
            </IconButton>
            <IconButton>
              <Delete />
            </IconButton>
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];

  const rows = [
    {
      id: 1,
      Name: "Snow",
      Description: "Jon",
      Slug: "Snow",
      Count: 2,
    },
    {
      id: 2,
      Name: "Lannister",
      Description: "Cersei",
      Slug: "Lannister",
      Count: 5,
    },
    {
      id: 3,
      Name: "Lannister",
      Description: "Jaime",
      Slug: "Lannister",
      Count: 11,
    },
    {
      id: 4,
      Name: "Stark",
      Description: "Arya",
      Slug: "Stark",
      Count: 9,
    },
    {
      id: 5,
      Name: "Targaryen",
      Description: "Daenerys",
      Slug: "Targaryen",
      Count: 4,
    },
    { id: 6, Name: "Melisandre", Description: null, Slug: 150, Count: null },
    {
      id: 7,
      Name: "Clifford",
      Description: "Ferrara",
      Slug: "Clifford",
      Count: 6,
    },
    {
      id: 8,
      Name: "Frances",
      Description: "Rossini",
      Slug: "Frances",
      Count: 12,
    },
    { id: 9, Name: "Roxie", Description: "Harvey", Slug: "Roxie", Count: 15 },
  ];

  const handleRowHover = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(Number(rowId));
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );
  };
  const handleRowLeaved = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(null);
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: false } })
    );
  };
  return (
    <Container>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4">Tags</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "28%" }}>
          <Card>
            <CardHeader title="Add New Tag" />
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      placeholder="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Slug</InputLabel>
                    <TextField
                      placeholder="Slug"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Slug"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Description</InputLabel>
                    <TextField
                      placeholder="Description"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Description"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ width: "70%" }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  padding: "15px 0",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <Search />
                  </IconButton>
                </Paper>
              </Box>
              <DataGrid
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                disableRowSelectionOnClick
                slotProps={{
                  row: {
                    onMouseEnter: handleRowHover,
                    onMouseLeave: handleRowLeaved,
                  },
                }}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
}

export default ChallengeTag;
