import axios from "axios";
import { axiosInstance, axiosInstance_WC } from "./axiosInstence";

export const getAllCourses = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `courses?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};
export const getCourseData = async (id) => {
  try {
    const response = await axiosInstance.get(`courses/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getCourseCategories = async () => {
  try {
    const response = await axiosInstance.get("course_category");
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getCourseGroup = async () => {
  try {
    const response = await axiosInstance.get("groups");
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getBatches = async () => {
  try {
    const response = await axiosInstance.get("batches");
    return response.data;
  } catch (err) {
    throw err;
  }
};

// export const deteleCourse = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`courses/${id}`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
export const updateCourse = async (id, data) => {
  try {
    const response = await axiosInstance.post(`courses/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const createCourse = async (data) => {
  try {
    const response = await axiosInstance.post("courses", data);
    if (data.featured_media)
      axiosInstance.post(
        `media/${data.featured_media}?courses=${response?.data?.id}`
      );
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getMeditationList = async (id, data) => {
  try {
    const response = await axios.get(
      "https://iam.guruatmananda.org/wp-json/myapi/v1/meditations"
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getKriyaList = async (id, data) => {
  try {
    const response = await axios.get(
      "https://iam.guruatmananda.org/wp-json/myapi/v1/kriyas"
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getCategories = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `course_category?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};

export const updateCategory = async (data) => {
  const response = await axiosInstance
    .post(`course_category/${data?.id}`, data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const addCategory = async (data) => {
  const response = await axiosInstance
    .post("course_category", data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
// export const deleteCategory = async (id) => {
//   const response = await axiosInstance
//     .delete(`course_category/${Number(id)}?force=true`)
//     .then((res) => res.data)
//     .catch((err) => err.message);
//   return response;
// };
export const getCoursesLinkedProducts = async () => {
  try {
    const response = await axiosInstance_WC.get("products");
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const getAssignmentTemplates = async () => {
  try {
    const response = await axiosInstance.get("assignment_templates");
    return response.data;
  } catch (err) {
    throw err;
  }
};
