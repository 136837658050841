import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import {
  detelePrograms,
  getPrograms,
} from "../../../services/programs.service";
import AlertDialog from "../../../common/AlertDialog";
import React, { useEffect, useState } from "react";
import { fetchMedia } from "../../../services/media.service";
import { useNavigate } from "react-router-dom";
function Programs(props) {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [rowId, setRowId] = useState(null);

  // const handleDelete = async () => {
  //   await detelePrograms(rowId);
  //   setAlert(false);
  //   fetchPrograms();
  // };
  const fetchPrograms = async () => {
    const response = await getPrograms();
    const programsList = [];
    await Promise.all(
      response?.map(async (pro) => {
        const featureImgData = pro?.acf?.category_image
          ? await fetchMedia(pro?.acf?.category_image)
          : null;

        programsList.push({
          id: pro?.id,
          name: pro?.name,
          description: pro?.description,
          slug: pro?.slug,
          acf: {
            category_image: featureImgData?.id || null,
            category_url: featureImgData?.source_url,
          },
        });
      })
    );
    setPrograms(programsList);
    setLoading(false);
  };

  useEffect(() => {
    fetchPrograms();
  }, []);
  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          padding: "15px 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Programs</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate("/programs/addprograms")}
          >
            Add New
          </Button>
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {programs?.map((data) => (
            <Grid item xs={2.4}>
              <Card>
                <CardHeader title={data?.name} />
                <Box sx={{ p: "10px" }}>
                  <CardMedia
                    component="img"
                    image={data?.acf.category_url}
                    // src={data?.category_url}
                    alt={data?.name}
                  />
                </Box>
                <CardActions>
                  {/* <Button
                    variant="contained"
                    onClick={() => {
                      setAlert(true);
                      setRowId(data.id);
                    }}
                  >
                    Delete
                  </Button> */}
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/programs/${data?.id}`, { state: { name: data?.name } })}
                  >
                    View Courses
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "28%" }}>
          <Card>
            <CardHeader
              title={cat?.id ? "Edit Category" : "Add New Category"}
            />
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      placeholder="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Name"
                      value={cat?.name}
                      onChange={(e) =>
                        setCat((prev) => ({ ...prev, name: e.target.value }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Slug</InputLabel>
                    <TextField
                      placeholder="Slug"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Slug"
                      value={cat?.slug}
                      onChange={(e) =>
                        setCat((prev) => ({ ...prev, slug: e.target.value }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Description</InputLabel>
                    <TextField
                      placeholder="Description"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Description"
                      multiline
                      value={cat?.description}
                      onChange={(e) =>
                        setCat((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      rows={4}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>Featured image</InputLabel>
                    {!selectedFeatureFile ? (
                      <Paper
                        sx={{
                          height: "100px",
                          margin: "30px",
                          border: "1px dashed",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onDrop={handleFeatureDrop}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <IconButton
                          onClick={() =>
                            document
                              .getElementById("Feature-file-upload")
                              .click()
                          }
                        >
                          <CloudUpload sx={{ fontSize: "50px" }} />
                        </IconButton>
                      </Paper>
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onMouseEnter={() => setIsFeatureHovered(true)}
                        onMouseLeave={() => setIsFeatureHovered(false)}
                      >
                        <img
                          src={selectedFeatureFile}
                          alt="feature Preview"
                          style={{ maxWidth: "100%", marginTop: "10px" }}
                        />
                        {isFeatureHovered && (
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: "3%",
                              left: "86%",
                              background: "#000",
                              borderRadius: "50%",
                            }}
                            onClick={() => setSelectedFeatureFile(null)}
                          >
                            <Delete sx={{ color: "#fff" }} />
                          </IconButton>
                        )}
                      </div>
                    )}
                    <input
                      id="Feature-file-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFeatureChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginTop: "20px",
                  }}
                  fullWidth
                  onClick={handleSubmit}
                >
                  {cat?.id ? "Update" : "Submit"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ width: "70%" }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  padding: "15px 0",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <Search />
                  </IconButton>
                </Paper>
              </Box>
              <DataGrid
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                disableRowSelectionOnClick
                slotProps={{
                  row: {
                    onMouseEnter: handleRowHover,
                    onMouseLeave: handleRowLeaved,
                  },
                }}
              />
            </CardContent>
          </Card>
        </Box>
      </Box> */}
      {/* <AlertDialog
        open={alert}
        setOpen={(isOpen) => setAlert(isOpen)}
        handleConfirm={handleDelete}
        dialogText="Are you sure you want to delete?"
      /> */}
    </Container>
  );
}

export default Programs;
