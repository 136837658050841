import { Delete, Done, Edit, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getAllUsers } from "../../services/users.service";
import { useNavigate } from "react-router-dom";
import { getUserMeta, updateApplication } from "../../services/track.service";
import CountUp from "react-countup";
import AlertDialog from "../../common/AlertDialog";
function Applications(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const navigate = useNavigate();
  const columns = [
    { field: "user_id", headerName: "User Id", width: 100 },
    { field: "full_name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "phone_number", headerName: "Phone No.", width: 150 },
    {
      field: "course_title",
      headerName: "Program",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      width: 150,
      renderCell: (params) =>
        params?.row?.status === "applied" && (
          <Box sx={{ display: "flex" }}>
            <Button
              onClick={() => {
                setAlert(true);
                setRowDetails(params?.row);
              }}
            >
              Approve
            </Button>
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const handleUpdateApplication = async (action) => {
    const requestData = {
      user_id: rowDetails?.user_id,
      data: [
        {
          meta_key: `application_${rowDetails?.course_id}`,
          meta_value: action,
        },
      ],
    };
    try {
      await updateApplication(requestData);
      setAlert(false);
      fetchData();
    } catch (error) {
      console.error("Error in update applicaton", error.message);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const users = await getUserMeta();
      const userRows = [];
      users?.map((user, i) => {
        userRows.push({
          id: i,
          user_id: user?.user_id,
          full_name: user?.full_name,
          email: user?.email,
          phone_number: user?.phone_number,
          course_title: user?.course_title,
          course_id: user?.course_id,
          status: user?.status,
        });
      });
      setRows(userRows);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching usersMeta:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const filteredRows = rows.filter(
    (row) =>
      (statusFilter === "all" || row.status === statusFilter) &&
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(search.toLowerCase())
      )
  );

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4"> Application Tracking </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
        <Card sx={{ marginRight: "20px" }}>
          <CardHeader title="Applied Applications" />
          <CardContent
            sx={{ fontSize: "40px", fontWeight: 600, textAlign: "center" }}
          >
            <CountUp
              start={0}
              end={rows?.length || 0}
              duration={2.5}
              delay={1}
            />
          </CardContent>
        </Card>
        <Card sx={{ marginRight: "20px" }}>
          <CardHeader title="Approved Applications" />
          <CardContent
            sx={{ fontSize: "40px", fontWeight: 600, textAlign: "center" }}
          >
            <CountUp
              start={0}
              end={
                rows?.filter((row) => row.status === "approved")?.length || 0
              }
              duration={2.5}
              delay={1}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Rejected Applications" />
          <CardContent
            sx={{ fontSize: "40px", fontWeight: 600, textAlign: "center" }}
          >
            <CountUp
              start={0}
              end={
                rows?.filter((row) => row.status === "rejected")?.length || 0
              }
              duration={2.5}
              delay={1}
            />
          </CardContent>
        </Card>
      </Box>

      <Card>
        <CardContent>
          <Box
            sx={{
              padding: "15px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={statusFilter}
              onChange={(e, newValue) => setStatusFilter(newValue)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="All" value="all" />
              <Tab label="Applied" value="applied" />
              <Tab label="Approved" value="approved" />
              <Tab label="Rejected" value="rejected" />
            </Tabs>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
            {/* <Button variant="contained">Add New</Button> */}
          </Box>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={filteredRows.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={filteredRows?.length}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
      <AlertDialog
        open={alert}
        setOpen={(isOpen) => setAlert(isOpen)}
        handleConfirm={() => handleUpdateApplication("approved")}
        handleReject={() => handleUpdateApplication("rejected")}
        noButtonText="Reject"
        yesButtonText="Approve"
        dialogText="Are you sure you want to take action on this application?"
      />
    </Container>
  );
}

export default Applications;
