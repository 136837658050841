import { axiosInstance, axiosInstance_WC } from "./axiosInstence";

export const getAllEvents = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `city_events?page=${page || 1}&per_page=${
        perPage || 10
      }&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};
export const getEventData = async (id) => {
  try {
    const response = await axiosInstance.get(`city_events/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

// export const deteleEvent = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`city_events/${id}`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const getLinkedProducts = async () => {
  try {
    const response = await axiosInstance_WC.get("products?category=99");
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateEvent = async (id, data) => {
  try {
    const response = await axiosInstance.post(`city_events/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const createEvent = async (data) => {
  try {
    const response = await axiosInstance.post("city_events", data);
    if (data.featured_media)
      axiosInstance.post(
        `media/${data.featured_media}?event=${response?.data?.id}`
      );
    return response.data;
  } catch (err) {
    throw err;
  }
};
