import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.bubble.css";
import { CloudUpload, Delete } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { createMedia, fetchMedia } from "../../../services/media.service";
import AutoSnackbar from "../../../common/AutoSnackbar";
import {
  addProgram,
  getProgramData,
  getPrograms,
  updatePrograms,
} from "../../../services/programs.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddProgram(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const [programsList, setProgramsList] = useState();
  const [programDetails, setProgramDetails] = useState({
    name: "",
    acf:{
      category_image:null
    }
  });
  const [featuredImg, setFeaturedImg] = useState("");

  const handleFeatureChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setProgramDetails((prevDetails) => ({
          ...prevDetails,
          acf: {
            ...prevDetails?.acf,
            category_image: res.id,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setProgramDetails((prevDetails) => ({
          ...prevDetails,
          acf: {
            ...prevDetails.acf,
            category_image: res.id,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updatePrograms(params?.id, programDetails);
    } else {
      res = await addProgram(programDetails);
      navigate(`/programs`);
    }
    if (res) {
      setPublishSuccess(true);
    }
  };

  useEffect(() => {
    const fetchProgramData = async () => {
      const response = await getProgramData(params?.id);
      let featuredimgData = "";
      if (response?.acf?.category_image) {
        featuredimgData = await fetchMedia(response?.acf?.category_image);
        setFeaturedImg(featuredimgData?.source_url);
      }
      setProgramDetails({
        ...programDetails,
        name: response?.name,
        description: response?.description,
        parent: response?.parent || 0,
        acf: {
          start_date: response?.acf.start_date || "",
          end_date: response?.acf.end_date || "",
          category_image: featuredimgData?.id,
        },
      });
      seLoading(false);
    };

    if (params?.id) {
      fetchProgramData();
    } else {
      setProgramDetails(null);
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchPrograms = async () => {
      const response = await getPrograms();
      setProgramsList(response);
    };
    fetchPrograms();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Program" : "Add New Program"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
          {params?.id?"Update":"Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Name</InputLabel>
                      <TextField
                        placeholder="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="name"
                        value={programDetails?.name}
                        onChange={(e) =>
                          setProgramDetails({
                            ...programDetails,
                            name: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <TextField
                        placeholder="Description"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="discription"
                        multiline
                        rows={4}
                        value={programDetails?.description}
                        onChange={(e) =>
                          setProgramDetails({
                            ...programDetails,
                            description: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel>Parrent</InputLabel>
                      <Select
                        fullWidth
                        sx={{ m: "5px" }}
                        variant="outlined"
                        margin="normal"
                        name="parrent"
                        value={programDetails?.parent || 0}
                        onChange={(e) =>
                          setProgramDetails((prevDetails) => ({
                            ...prevDetails,
                            parent: e.target.value,
                          }))
                        }
                      >
                        <MenuItem value={0}>None</MenuItem>
                        {programsList &&
                          programsList?.map((prgm) => (
                            <MenuItem key={prgm?.id} value={prgm?.id}>
                              {prgm?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputLabel>Start Date</InputLabel>
                        <DatePicker
                          value={
                            programDetails?.acf?.start_date
                              ? dayjs(programDetails.acf.start_date)
                              : null
                          }
                          format="DD/MM/YYYY"
                          onChange={(date) => {
                            if (dayjs(date).isValid()) {
                              const formattedDate =
                                dayjs(date).format("YYYY-MM-DD");
                              setProgramDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  start_date: formattedDate,
                                },
                              }));
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <InputLabel>End Date</InputLabel>
                        <DatePicker
                          value={
                            programDetails?.acf?.end_date
                              ? dayjs(programDetails.acf.end_date)
                              : null
                          }
                          format="DD/MM/YYYY"
                          onChange={(date) => {
                            if (dayjs(date).isValid()) {
                              const formattedDate =
                                dayjs(date).format("YYYY-MM-DD");
                              setProgramDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  end_date: formattedDate,
                                },
                              }));
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card>
              <CardHeader title="Category image" />
              <CardContent>
                {!featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Category-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={featuredImg}
                      alt="category Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setFeaturedImg("");
                          setProgramDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails?.acf,
                              category_image: null,
                            },
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Category-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddProgram;
