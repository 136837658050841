import { Box, ThemeProvider, Typography } from "@mui/material";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";
import "./App.css";
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Posts from "./components/posts/Posts";
import { theme } from "./theme.js";
import Courses from "./components/courses/Courses.js";
import Vedios from "./components/vedios/Vedios.js";
import Events from "./components/events/Events.js";
import Meditattions from "./components/meditattions/Meditattions.js";
import Challenges from "./components/challenges/Challenges.js";
import Podcast from "./components/podcast/Podcast.js";
import Webinar from "./components/webinar/Webinar.js";
import Drive from "./components/drive/Drive.js";
import Products from "./components/products/Products.js";
import User from "./components/userManage/User.js";
import Badges from "./components/settings/badge/Badges.js";
import AddPost from "./components/posts/AddPost.js";
import Categories from "./components/posts/Categories.js";
import Tags from "./components/posts/Tags.js";
import CourseCategories from "./components/courses/CourseCategories.js";
import Programs from "./components/courses/Programs/Programs.js";
import VideoCategories from "./components/vedios/VideoCategories.js";
// import VideoTag from "./components/vedios/VideoTag.js";
import EventCategories from "./components/events/EventsCategories.js";
import EventsTag from "./components/events/Eventstag.js";
import MeditationsCategories from "./components/meditattions/MeditationsCategories.js";
import MeditationsTag from "./components/meditattions/MeditationsTag.js";
import ProductsCategories from "./components/products/ProductsCategories.js";
import WebinarCategories from "./components/webinar/WebinarCategories.js";
import WebinarTag from "./components/webinar/WebinarTag.js";
import PodcastCategories from "./components/podcast/PodcastCategories.js";
import PodcastTag from "./components/podcast/PodcastTag.js";
import ChallengeCategories from "./components/challenges/ChallengeCategories.js";
import ChallengeTag from "./components/challenges/ChallengesTag.js";
import AddVideo from "./components/vedios/AddVideo.js";
import NewEvent from "./components/events/NewEvent.js";
import AddMeditation from "./components/meditattions/AddMeditation.js";
import AddNew from "./components/userManage/AddNew.js";
import AddChallenge from "./components/challenges/AddChallenge.js";
import AddPodcast from "./components/podcast/AddPodcast.js";
import AddWebinar from "./components/webinar/AddWebinar.js";
import AddDrive from "./components/drive/AddDrive.js";
import Login from "./components/LogIn.js";
import { useSelector } from "react-redux";
import AxiosInterceptor from "./services/axiosInstence.js";
import Websites from "./components/settings/Websites.js";
import AddBadge from "./components/settings/badge/AddBadge.js";
import AddGroup from "./components/settings/groups/AddGroup.js";
import Groups from "./components/settings/groups/Groups.js";
import PaymentGateway from "./components/settings/PaymentsGateway.js";
import AddCourse from "./components/courses/AddCourse.js";
import AddProgram from "./components/courses/Programs/AddProgram.js";
import ViewCourses from "./components/courses/Programs/ViewCourses.js";
import Orders from "./components/reports/Orders.js";
import Subscriptions from "./components/reports/Subscriptions.js";
import Applications from "./components/track/Applications.js";
import Payments from "./components/track/Payments.js";
function App() {
  const navigate =useNavigate();
  const isAuthenticated =
    useSelector((state) => state?.auth?.isAuthenticated ||false);
  const [toggle, settoggle] = useState(true);
  const { pathname } = useLocation();
  const handleSideToggle = () => {
    settoggle(!toggle);
  };
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login")
    }
  }, [isAuthenticated, navigate, pathname]);
  if (!isAuthenticated || pathname === "/login") {
    // If it's the login page, render only the login component
    return (
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to={"/login"} />} />
        </Routes>
      </ThemeProvider>
    );
  }
  return (
    <Box sx={{ background: "#f1f1f1", width: "100%", minHeight: "100vh" }}>
      <ThemeProvider theme={theme}>
        <Header handleSideToggle={handleSideToggle} toggle={toggle} />
        <Box sx={{ display: "flex" }}>
          <Sidebar toggle={toggle} />
          <Box
            sx={{
              paddingTop: "4%",
              width: toggle ? "85%" : "100%",
              ml: "auto",
              marginBottom: "20px",
            }}
          >
            <Routes>
              <Route path="/posts" element={<Posts />} />
              <Route path="/posts/:id" element={<AddPost />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/videos" element={<Vedios />} />
              <Route path="/videos/:id" element={<AddVideo />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/:id" element={<NewEvent />} />
              <Route path="/meditations" element={<Meditattions />} />
              <Route path="/meditations/:id" element={<AddMeditation />} />
              <Route path="/challenges" element={<Challenges />} />
              <Route path="/challenges/:id" element={<AddChallenge />} />
              <Route path="/podcast" element={<Podcast />} />
              <Route path="/podcast/:id" element={<AddPodcast />} />
              <Route path="/webinar" element={<Webinar />} />
              <Route path="/webinar/:id" element={<AddWebinar />} />
              <Route path="/drive" element={<Drive />} />
              <Route path="/drive/:id" element={<AddDrive />} />
              <Route path="/products" element={<Products />} />
              <Route path="/users" element={<User />} />
              <Route path="/newpost" element={<AddPost />} />
              <Route path="/posts/categories" element={<Categories />} />
              <Route path="/posts/tags" element={<Tags />} />
              <Route path="/courses/categories" element={<CourseCategories />} />
              <Route path="/programs" element={<Programs />} />
              <Route path="/programs/addprograms" element={<AddProgram />} />
              <Route path="/programs/:id" element={<ViewCourses />} />
              <Route path="/videos/categories" element={<VideoCategories />} />
              {/* <Route path="/videotag" element={<VideoTag />} /> */}
              <Route path="/events/categories" element={<EventCategories />} />
              <Route path="/events/tags" element={<EventsTag />} />
              <Route path="/newevent" element={<NewEvent />} />
              <Route
                path="/meditations/categories"
                element={<MeditationsCategories />}
              />
              <Route path="/meditations/tags" element={<MeditationsTag />} />
              <Route
                path="/product/categories"
                element={<ProductsCategories />}
              />
              <Route
                path="/webinar/categories"
                element={<WebinarCategories />}
              />
              <Route path="/webinar/tags" element={<WebinarTag />} />
              <Route
                path="/podcast/categories"
                element={<PodcastCategories />}
              />
              <Route path="/podcast/tags" element={<PodcastTag />} />
              <Route
                path="/challenge/categories"
                element={<ChallengeCategories />}
              />
              <Route path="/challenge/tags" element={<ChallengeTag />} />
              <Route path="/addvideo" element={<AddVideo />} />
              <Route path="/addmeditation" element={<AddMeditation />} />
              <Route path="/adduser" element={<AddNew />} />
              <Route path="/users/:id" element={<AddNew />} />
              <Route path="/addchallenge" element={<AddChallenge />} />
              <Route path="/addpodcast" element={<AddPodcast />} />
              <Route path="/addwebinar" element={<AddWebinar />} />
              <Route path="/adddrive" element={<AddDrive />} />
              <Route path="/websites" element={<Websites />} />
              <Route path="/badges" element={<Badges />} />
              <Route path="/badges/new" element={<AddBadge />} />
              <Route path="/badges/:id" element={<AddBadge />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/groups/new" element={<AddGroup />} />
              <Route path="/groups/:id" element={<AddGroup />} />
              <Route path="/payments" element={<PaymentGateway />} />
              <Route path="/addcourse" element={<AddCourse />} />
              <Route path="/courses/:id" element={<AddCourse />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/track/applications" element={<Applications />} />
              <Route path="/track/payments" element={<Payments />} />
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
      <AxiosInterceptor />
    </Box>
  );
}

export default App;
