import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Cancel, CloudUpload, Delete, Public } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useParams } from "react-router-dom";
import { getWebsites } from "../../services/extraSites.service";
import { getPrograms } from "../../services/programs.service";
import {
  createWebinar,
  getSelectedtypeData,
  getWebinarData,
  updateWebinar,
} from "../../services/webinar.service";
import AutoSnackbar from "../../common/AutoSnackbar";
import dayjs from "dayjs";
import { createMedia, fetchMedia } from "../../services/media.service";

function AddWebinar(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, seLoading] = useState(true);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [featuredImg, setFeaturedImg] = useState("");
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [summery, setSummery] = useState("");
  const summeryFileInputRef = useRef(null);
  const userTypes = [
    {
      type: "batch_based",
      value: "Batch Based",
      label: "select_batch",
      url: "batches",
    },
    {
      type: "badge_based",
      value: "Badge Based",
      label: "select_badge",
      url: "badge",
    },
    {
      type: "add_courses",
      value: "Course Based",
      label: "select_course",
      url: "courses",
    },
  ];
  const [userType, setUserType] = useState(userTypes[0]);
  const [selectedData, setSelectedData] = useState(null);

  const initialDetails = {
    title: "",
    acf: {
      user_type: userTypes[0].value,
    },
    extra_pages_cat: [],
    status: "publish",
  };
  const [webinarDetails, setWebinarDetails] = useState(initialDetails);
  const handleFeatureChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setWebinarDetails({
          ...webinarDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setWebinarDetails({
          ...webinarDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSummeryChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      handleFile(file);
      setWebinarDetails((prevDetails) => ({
        ...prevDetails,
        acf: {
          ...prevDetails.acf,
          webinar_summery: res.id,
        },
      }));
      event.target.value = null;
    }
  };

  const handleSummeryDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      handleFile(file);
      setWebinarDetails((prevDetails) => ({
        ...prevDetails,
        acf: {
          ...prevDetails.acf,
          webinar_summery: res.id,
        },
      }));
    }
  };

  const handleFile = (file) => {
    setSummery(file?.name);
  };
  const handleAddDetailsRow = () => {
    const selectBasedObj = {
      [userType.label]: null,
      webinar_time: "",
    };
    const updatedItinerary = {
      ...webinarDetails,
      acf: {
        ...webinarDetails.acf,
        [userType.type]: webinarDetails.acf[userType.type]
          ? [...webinarDetails.acf[userType.type], selectBasedObj]
          : [selectBasedObj],
      },
    };

    setWebinarDetails(updatedItinerary);
  };
  const handleRemoveRow = (index) => {
    const updatedItinerary = {
      ...webinarDetails,
      acf: {
        ...webinarDetails.acf,
        [userType.type]: webinarDetails.acf[userType.type]?.filter(
          (_, i) => i !== index
        ),
      },
    };

    setWebinarDetails(updatedItinerary);
  };
  const handleUserTypeChange = (e) => {
    const selectedUserType = userTypes.find(
      (type) => type.value === e.target.value
    );
    setWebinarDetails((prevDetails) => ({
      ...prevDetails,
      acf: {
        ...prevDetails.acf,
        user_type: e.target.value,
      },
    }));
    setUserType(selectedUserType);
  };
  const handleWebsiteChange = (siteId) => {
    setWebinarDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };
  const handleProgramChange = (programId) => {
    setWebinarDetails((prevDetails) => {
      const updatedPrograms = (prevDetails.programs || []).includes(programId)
        ? (prevDetails.programs || []).filter((id) => id !== programId)
        : [...(prevDetails.programs || []), programId];

      return {
        ...prevDetails,
        programs: updatedPrograms,
      };
    });
  };

  const handleSelectTypeChange = (e, index) => {
    const { value } = e.target;

    setWebinarDetails((prevDetails) => {
      const updatedRows = prevDetails.acf[userType?.type].map((row, id) =>
        id === index
          ? {
              ...row,
              [userType?.label]: value,
            }
          : row
      );
      return {
        ...prevDetails,
        acf: {
          ...prevDetails.acf,
          [userType?.type]: updatedRows,
        },
      };
    });
  };

  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateWebinar(params?.id, webinarDetails);
    } else {
      res = await createWebinar(webinarDetails);
    }
    if (res) {
      navigate(`/webinar/${res?.id}`);
      setPublishSuccess(true);
    }
  };
  useEffect(() => {
    const fetchWebinarData = async () => {
      const response = await getWebinarData(params?.id);
      seLoading(false);
      let featuredimgData;
      let summaryData;
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);
      if (response.webinar_summery)
        summaryData = await fetchMedia(response.featured_media);
      const selectedUserType = userTypes.find(
        (type) => type.value === response.acf?.user_type
      );
      console.log(summaryData);
      setFeaturedImg(featuredimgData?.source_url);
      setUserType(selectedUserType);
      setWebinarDetails({
        ...webinarDetails,
        title: response?.title,
        status: response?.status,
        featured_media: featuredimgData?.id || null,
        acf: {
          password: response.acf?.password || "",
          webinar_summery: response.acf?.webinar_summery || null,
          webinar_link: response.acf?.webinar_link || "",
          user_type: response.acf?.user_type,
          batch_based: response.acf?.batch_based || [],
          badge_based: response.acf?.badge_based || [],
          add_courses: response.acf?.add_courses || [],
        },
      });
    };

    if (params?.id) {
      fetchWebinarData();
    } else {
      setWebinarDetails(initialDetails);

      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchSelectedData = async () => {
      const selectTypeData = await getSelectedtypeData(userType?.url);
      setSelectedData(selectTypeData);
    };
    fetchSelectedData();
  }, [userType]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin: " 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Webinar" : "Add New Webinar"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={webinarDetails.status}
              onChange={(e) =>
                setWebinarDetails({
                  ...webinarDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mt: "5px" }}
        >
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={webinarDetails.title}
                        onChange={(e) =>
                          setWebinarDetails({
                            ...webinarDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Webinar" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Webinar Link</InputLabel>
                      <TextField
                        placeholder="Webinar Link"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="webinarurl"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Public />
                            </InputAdornment>
                          ),
                        }}
                        value={webinarDetails?.acf?.webinar_link}
                        onChange={(e) =>
                          setWebinarDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              webinar_link: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>User Type</InputLabel>
                      <Select
                        sx={{ mt: "5px" }}
                        fullWidth
                        labelId="select-type"
                        id="select-type"
                        placeholder="Select"
                        value={webinarDetails.acf.user_type}
                        onChange={handleUserTypeChange}
                      >
                        {userTypes.map((type) => (
                          <MenuItem key={type.type} value={type.value}>
                            {type.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Webinar Summary</InputLabel>
                      {summery ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>{summery}</Typography>
                          <IconButton
                            onClick={() => {
                              setSummery("");
                              summeryFileInputRef.current.value = null;
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </Box>
                      ) : (
                        <Paper
                          sx={{
                            height: "100px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleSummeryDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() => summeryFileInputRef.current.click()}
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                            <Typography
                              variant="subtitle"
                              sx={{ fontSize: "14px" }}
                            >
                              (.pdf)
                            </Typography>
                          </IconButton>
                        </Paper>
                      )}
                      <input
                        ref={summeryFileInputRef}
                        id="summery-file-upload"
                        type="file"
                        accept=".pdf"
                        style={{ display: "none" }}
                        onChange={handleSummeryChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Password</InputLabel>
                      <TextField
                        placeholder="Password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="password"
                        value={webinarDetails?.acf?.password}
                        onChange={(e) =>
                          setWebinarDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              password: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>{userType?.value}</InputLabel>
                      <Box
                        sx={{
                          padding: "10px",
                          background: "#ebebeb",
                          margin: "10px 0",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {webinarDetails.acf[userType?.type] &&
                        webinarDetails.acf[userType?.type]?.length !== 0 ? (
                          webinarDetails.acf[userType?.type].map(
                            (row, index) => (
                              <Paper
                                key={index}
                                sx={{
                                  padding: "20px",
                                  position: "relative",
                                  margin: "5px",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <InputLabel>
                                      {userType?.label
                                        ?.replace(/_/g, " ")
                                        ?.replace(/\b\w/g, (c) =>
                                          c.toUpperCase()
                                        )}
                                    </InputLabel>
                                    {selectedData && (
                                      <Select
                                        sx={{ mt: "5px" }}
                                        fullWidth
                                        labelId="select-types"
                                        id="select-types"
                                        placeholder="Select"
                                        value={row[userType?.label]}
                                        onChange={(e) =>
                                          handleSelectTypeChange(e, index)
                                        }
                                      >
                                        {selectedData?.map((data) => (
                                          <MenuItem
                                            key={data.id}
                                            value={data.id}
                                          >
                                            {data?.title}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    )}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <InputLabel>Webinar Time</InputLabel>
                                      <DateTimePicker
                                        value={
                                          row?.webinar_time
                                            ? dayjs(row?.webinar_time)
                                            : null
                                        }
                                        onChange={(date) => {
                                          if (dayjs(date).isValid()) {
                                            const formattedTime = dayjs(
                                              date
                                            ).format("YYYY-MM-DD HH:mm:ss");
                                            setWebinarDetails((prevDetails) => {
                                              const acfUserType = [
                                                ...prevDetails.acf[
                                                  userType?.type
                                                ],
                                              ];

                                              const updatedArray =
                                                acfUserType.map((item, id) => {
                                                  if (id === index) {
                                                    return {
                                                      ...item,
                                                      webinar_time:
                                                        formattedTime,
                                                    };
                                                  }
                                                  return item;
                                                });

                                              return {
                                                ...prevDetails,
                                                acf: {
                                                  ...prevDetails.acf,
                                                  [userType?.type]:
                                                    updatedArray,
                                                },
                                              };
                                            });
                                          }
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                </Grid>
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: "95%",
                                  }}
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  <Cancel />
                                </IconButton>
                              </Paper>
                            )
                          )
                        ) : (
                          <Typography>No Rows</Typography>
                        )}
                      </Box>
                      <Button variant="contained" onClick={handleAddDetailsRow}>
                        Add Row
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setFeaturedImg("");
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
            {/* <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Programs" />
              <CardContent>
                <FormGroup>
                  {programs &&
                    programs?.map((program) => (
                      <FormControlLabel
                        key={program?.id}
                        control={
                          <Checkbox
                            checked={webinarDetails.programs?.includes(
                              program.id
                            )}
                            onChange={() => handleProgramChange(program.id)}
                          />
                        }
                        label={program?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={webinarDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card> */}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddWebinar;
