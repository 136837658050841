// AxiosInterceptor.js
import axios from "axios";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL + process.env.REACT_APP_WP_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosInstance_WC = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL + process.env.REACT_APP_WC_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosInstance_custom = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL + process.env.REACT_APP_CUSTOM_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosInstance_custom_user = axios.create({
  baseURL: process.env.REACT_APP_SITE_URL + process.env.REACT_APP_CUSTOM_USER_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
const applyTokenInterceptor = (instance, token) => {
  const interceptor = instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return interceptor;
};

const AxiosInterceptor = () => {
  const token = useSelector(state => state.auth.token);

  useLayoutEffect(() => {
    const interceptorInstance = applyTokenInterceptor(axiosInstance, token);
    const interceptorInstanceWC = applyTokenInterceptor(axiosInstance_WC, token);
    const interceptorInstanceCustom = applyTokenInterceptor(axiosInstance_custom, token);
    const interceptorInstanceCustomUser = applyTokenInterceptor(axiosInstance_custom_user, token);
    return () => {
      axiosInstance.interceptors.request.eject(interceptorInstance);
      axiosInstance_WC.interceptors.request.eject(interceptorInstanceWC);
      axiosInstance_custom.interceptors.request.eject(interceptorInstanceCustom);
      axiosInstance_custom_user.interceptors.request.eject(interceptorInstanceCustomUser);
    };
  }, [token]);

  return null;
};

export default AxiosInterceptor;
