import { Delete, Edit, Search } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deteleMeditation,
  getAllMeditations,
} from "../../services/meditation.service";
import AlertDialog from "../../common/AlertDialog";
function Meditattions(props) {
  const navigate = useNavigate();
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [search ,setSearch]= useState("");

  const columns = [
    { field: "Title", headerName: "Title", width: 250 },
    { field: "Categories", headerName: "Categories", width: 250 },
    {
      field: "Websites",
      headerName: "Websites",
      // type: "number",
      width: 250,
    },
    {
      field: "Status",
      headerName: "Status",
      // type: "string",
      width: 100,
    },
    {
      field: "Date",
      headerName: "Date",
      // type: "string",
      width: 200,
    },
    {
      // field: "",
      // headerName: "",
      width: 100,
      renderCell: (params) =>
        hoveredRowId === params.id && (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => navigate(`/meditations/${params.id}`)}>
              <Edit />
            </IconButton>
            {/* <IconButton
              onClick={() => {
                setAlert(true);
                setRowId(params.id);
              }}
            >
              <Delete />
            </IconButton> */}
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];

  const handleRowHover = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(Number(rowId));
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );
  };

  const handleRowLeaved = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(null);
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: false } })
    );
  };

  // const handleDelete = async () => {
  //   await deteleMeditation(rowId);
  //   setAlert(false);
  //   fetchData();
  // };

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const meditations = await getAllMeditations(currentPage, itemsPerPage,search);
      const meditationRows = meditations?.data?.map((meditation) => ({
        id: meditation?.id,
        Title: meditation?.title,
        Categories: meditation?.meditation_category_details
          ?.map((category) => category?.name)
          ?.join(", "),
        Websites: meditation?.extra_pages_cat_details
          ?.map((page) => page?.name)
          ?.join(", "),
        Status: meditation?.status,
        Date: meditation?.date,
      })) || [];
  
      setTotalCount(meditations.totalCount);
      setLoading(false);
      setRows(meditationRows);
    } catch (error) {
      console.error("Error fetching meditations:", error.message);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage,search]);
  

  return (
    <Container>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4">Meditations</Typography>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <Box
            sx={{
              padding: "15px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e)=>setSearch(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              sx={{ fontSize: "16px", fontWeight: 500 }}
              onClick={() => navigate("/addmeditation")}
            >
              Add New
            </Button>
          </Box>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={totalCount}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
            slotProps={{
              row: {
                onMouseEnter: handleRowHover,
                onMouseLeave: handleRowLeaved,
              },
            }}
          />
        </CardContent>
      </Card>
      {/* <AlertDialog
        open={alert}
        setOpen={(isOpen) => setAlert(isOpen)}
        handleConfirm={handleDelete}
        dialogText="Are you sure you want to delete?"
      /> */}
    </Container>
  );
}

export default Meditattions;
