import { axiosInstance } from "./axiosInstence";

export const getPaymentGateways = async(page, perPage,seachText)=>{
  try {
    const response = await axiosInstance.get(
      `payment?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
}
export const getPaymentGatewayData = async(id)=>{
    const response = await axiosInstance
    .get(`payment/${id}`)
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}

export const updatePaymentGateway = async(data)=>{
  const response = await axiosInstance
  .post(`payment/${data?.id}`,data)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
export const createPaymentGateway = async(data)=>{
  const response = await axiosInstance
  .post("payment",data)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
// export const deletePaymentGateway = async(id)=>{
//   const response = await axiosInstance
//   .delete(`payment/${id}?force=true`)
//   .then((res) => res.data)
//   .catch((err)=>err.message);
// return response;
// }