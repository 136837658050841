import { axiosInstance } from "./axiosInstence";

export const getGroups = async(page, perPage,seachText)=>{;
  try {
    const response = await axiosInstance.get(
      `gorups?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
}
export const getGroupData = async(id)=>{
    const response = await axiosInstance
    .get(`gorups/${id}`)
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}
export const updateGroup = async(id, data)=>{
  const response = await axiosInstance
  .post(`gorups/${id}`,data)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
export const createGroup = async(data)=>{
  const response = await axiosInstance
  .post("gorups",data)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}
export const deleteGroup = async(id)=>{
  const response = await axiosInstance
  .delete(`gorups/${id}`)
  .then((res) => res.data)
  .catch((err)=>err.message);
return response;
}