import { Delete, Done, Edit, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getAllUsers } from "../../services/users.service";
import { useNavigate } from "react-router-dom";
import {
  cancelSubscrition,
  getAllPayments,
  getUserMeta,
  updateApplication,
} from "../../services/track.service";
import CountUp from "react-countup";
import AlertDialog from "../../common/AlertDialog";
import { getAllCourses } from "../../services/courses.service";
function Payments(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [rowDetails, setRowDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const today = new Date();
  const columns = [
    { field: "full_name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone_number", headerName: "Phone No.", width: 130 },
    {
      field: "product_name",
      headerName: "Program",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "next_payment_date",
      headerName: "Next Payment",
      width: 150,
    },
    {
      field: "overdue",
      headerName: "Overdue",
      width: 100,
    },
    {
      field: "dropout",
      headerName: "Dropout",
      width: 100,
    },
    {
      width: 150,
      renderCell: (params) => {
        const isOverdue = params.row.overdue === "Yes";
        const isDropout = params.row.dropout === "Yes";
        if (isOverdue && !isDropout) {
          return (
            <Box sx={{ display: "flex" }}>
              <Button
                onClick={() => {
                  setAlert(true);
                  setRowDetails(params?.row);
                }}
              >
                Dropout
              </Button>
            </Box>
          );
        } else {
          return null;
        }
      },
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const handleCancelSubscrition = async () => {
    try {
      await cancelSubscrition(rowDetails?.subscription_id);
      setAlert(false);
      fetchData();
    } catch (error) {
      console.error("Error in cancel subscrition", error.message);
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const isOverdue = (nextPaymentDate) => {
    const today = new Date();
    const paymentDate = new Date(nextPaymentDate);
    return today > paymentDate ? "Yes" : "No";
  };

  const handleChangeFilter = (value) => {
    switch (value) {
      case "All":
        return rows;
      case "Active":
        return rows.filter((item) => item.subscription_status === "active");
      case "Overdue in 5 Days":
        return rows.filter((subscription) => {
          const nextPaymentDate = new Date(subscription.next_payment_date);
          const fiveDaysFromNow = new Date(today);
          fiveDaysFromNow.setDate(today.getDate() + 5);

          return nextPaymentDate >= today && nextPaymentDate <= fiveDaysFromNow;
        });
      case "Overdue":
        return rows.filter((item) => item.subscription_status === "on-hold");
      case "Overdue for 5 Days":
        return rows.filter((subscription) => {
          const nextPaymentDate = new Date(subscription.next_payment_date);
          const fiveDaysAgo = new Date(today);
          fiveDaysAgo.setDate(today.getDate() - 5);

          return nextPaymentDate >= fiveDaysAgo && nextPaymentDate < today;
        });

      case "Dropout":
        return rows.filter(
          (item) => item.subscription_status === "pending-cancel"
        );
      default:
        return null;
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const payments = await getAllPayments(selectedCourse?.acf?.course_attributes?.linked_product);
      const paymentRows = payments.map((application) => ({
        subscription_id: application.subscription_id,
        full_name: application.full_name,
        email: application.email,
        phone_number: application.phone_number,
        product_name: application.product_name,
        amount: application.amount,
        subscription_status: application.subscription_status,
        next_payment_date:
          application.next_payment_date === "NA"
            ? "No Dues"
            : formatDate(application.next_payment_date),
        overdue:
          application.subscription_status === "on-hold" ||
          application.subscription_status === "pending-cancel"
            ? "Yes"
            : isOverdue(application.next_payment_date), // Calculate "Overdue" status
        dropout:
          application.subscription_status === "pending-cancel" ? "Yes" : "No", // Calculate "Dropout" status
      }));
      setRows(paymentRows);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching usersMeta:", error.message);
    }
  };
  useEffect(() => {
    if(selectedCourse?.acf?.course_attributes?.linked_product)
    fetchData();
  }, [selectedCourse]);

  useEffect(() => {
    const fetchCourses = async () => {
      const res = await getAllCourses(1, 100);
      console.log(res?.data?.[0].id);
      setCourses(res.data);
      setSelectedCourse(res?.data?.[0]);
    };
    fetchCourses();
  }, []);

  const filteredRows = (handleChangeFilter(statusFilter) || [])?.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(search.toLowerCase())
    )
  );

  const overdueSubscriptions = rows.filter(
    (item) => item.subscription_status === "on-hold"
  );
  const dropoutSubscriptions = rows.filter(
    (item) => item.subscription_status === "pending-cancel"
  );
  const dropoutCount = dropoutSubscriptions.length;

  const totalAmount = overdueSubscriptions.reduce(
    (accumulator, subscription) => {
      return accumulator + parseFloat(subscription.amount.replace(/,/g, ""));
    },
    0
  );
  const overdueCount = overdueSubscriptions.length;

  return (
    <Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4"> Payment Tracking </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
        <Card sx={{ marginRight: "20px" }}>
          <CardHeader title="Payments Overdue" />
          <CardContent
            sx={{ fontSize: "40px", fontWeight: 600, textAlign: "center" }}
          >
            <CountUp
              start={0}
              end={overdueCount || 0}
              duration={2.5}
              delay={1}
            />
          </CardContent>
        </Card>
        <Card sx={{ marginRight: "20px" }}>
          <CardHeader title="Total Overdue Amount" />
          <CardContent
            sx={{ fontSize: "40px", fontWeight: 600, textAlign: "center" }}
          >
            ₹{" "}
            <CountUp
              start={0}
              end={totalAmount || 0}
              duration={2.5}
              delay={1}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Dropouts" />
          <CardContent
            sx={{ fontSize: "40px", fontWeight: 600, textAlign: "center" }}
          >
            <CountUp
              start={0}
              end={dropoutCount || 0}
              duration={2.5}
              delay={1}
            />
          </CardContent>
        </Card>
      </Box>

      <Card>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InputLabel>Select Course :</InputLabel>
            <Select
              sx={{ m: "5px", minWidth: "30%" }}
              value={selectedCourse}
              onChange={(e) =>
                setSelectedCourse(e.target.value)
              }
              variant="outlined"
              margin="normal"
              name="course"
            >
              {courses && courses?.map((c) => (
                <MenuItem key={c?.id} value={c}>
                  {c?.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              padding: "15px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={statusFilter}
              onChange={(e, newValue) => setStatusFilter(newValue)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              sx={{
                "& .MuiButtonBase-root.MuiTab-root": {
                  flexBasis: "auto !important",
                },
              }}
            >
              <Tab label="All" value="All" />
              <Tab label="Active" value="Active" />
              <Tab label="Overdue in 5 Days" value="Overdue in 5 Days" />
              <Tab label="Overdue" value="Overdue" />
              <Tab label="Overdue for 5 Days" value="Overdue for 5 Days" />
              <Tab label="Dropout" value="Dropout" />
            </Tabs>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
            {/* <Button variant="contained">Add New</Button> */}
          </Box>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={filteredRows.slice(
              (currentPage - 1) * itemsPerPage,
              currentPage * itemsPerPage
            )}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            getRowId={(row) => row.subscription_id}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={filteredRows?.length}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
      <AlertDialog
        open={alert}
        dialogTitle="Mark Dropout"
        setOpen={(isOpen) => setAlert(isOpen)}
        handleConfirm={() => handleCancelSubscrition()}
        dialogText="You will not be able to revert the subscription status.
        Are you sure?"
      />
    </Container>
  );
}

export default Payments;
