import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getAllProducts } from "../../services/products.service";
import { getAllOrders } from "../../services/orders.service";
import dayjs from "dayjs";
function Orders(props) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);

  const columns = [
    { field: "order", headerName: "Order", width: 200 },
    {
      field: "date",
      headerName: "Date",
      // type: "string",
      width: 180,
      valueFormatter: (params) =>
      params.value && dayjs(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "total",
      headerName: "Total",
      width: 100,
    },
    { field: "product", headerName: "Product", width: 250 },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      width: 180,
    },
  ];

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const orders = await getAllOrders(currentPage, itemsPerPage);
      const ordersList =
        orders?.data?.map((order) => ({
          id: order?.id,
          order: `#${order?.id} ${order?.billing.first_name} ${order?.billing.last_name}`,
          date: order?.date_created,
          status:order?.status,
          total: order?.currency_symbol + order?.total,
          product: order?.line_items?.map((item) => item?.name)?.join(", "),
          transactionId: order?.transaction_id,
        })) || [];
      setTotalCount(orders.totalCount);
      setLoading(false);
      setRows(ordersList);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);
  return (
    <Container>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4">Orders</Typography>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={totalCount}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
    </Container>
  );
}

export default Orders;
