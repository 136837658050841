import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { CloudUpload, Delete, Public } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreatePost,
  createTag,
  getCategories,
  getPostData,
  getTags,
  updatePost,
} from "../../services/post.service";
import { createMedia, fetchMedia } from "../../services/media.service";
import { getWebsites } from "../../services/extraSites.service";
import AutoSnackbar from "../../common/AutoSnackbar";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddPost(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isSqureHovered, setIsSqureHovered] = useState(false);
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [categories, setCategories] = useState(null);
  const [websites, setWebsites] = useState(null);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const [tagsList, setTagsList] = useState([]);
  const initialDetails = {
    title: "",
    content: "",
    acf: {
      banner: null,
      square_image: null,
      audio_track: "",
      short_write_up: "",
    },
    categories: [],
    extra_pages_cat: [],
    post_tags: [],
    featured_media: null,
    excerpt: "",
    status: "publish",
  };
  const [postDetails, setPostDetails] = useState(initialDetails);
  const [Images, setImages] = useState({
    bannerImg: "",
    squareImg: "",
    featuredImg: "",
  });

  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const handleAddTag = () => {
    const newTags = Array.from(
      new Set(tagInput.split(",").map((tag) => tag.trim()))
    );
  
    if (newTags.length > 0) {
      const tagObjects = newTags
        .filter((tag) => !postDetails.post_tag_details.some((t) => t.name === tag))
        .map((tag) => ({ name: tag }));
  
      setPostDetails((prevDetails) => ({
        ...prevDetails,
        post_tag_details: [...prevDetails.post_tag_details, ...tagObjects],
      }));
    }
  
    setTagInput("");
  };
  

  const handleImageChange = async (file, acfKey, mediaKey) => {
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          [mediaKey]: reader.result,
        }));
        if (acfKey === "featured_media") {
          setPostDetails((prevDetails) => ({
            ...prevDetails,
            featured_media: res.id,
          }));
        } else
          setPostDetails((prevDetails) => ({
            ...prevDetails,
            acf: {
              ...prevDetails.acf,
              [acfKey]: res.id,
            },
          }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDrop = async (e, acfKey, mediaKey) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    await handleImageChange(file, acfKey, mediaKey);
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "banner", "bannerImg");
  };

  const handleBannerDrop = (e) => {
    handleImageDrop(e, "banner", "bannerImg");
  };

  const handleSquareChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "square_image", "squareImg");
  };

  const handleSquareDrop = (e) => {
    handleImageDrop(e, "square_image", "squareImg");
  };

  const handleFeatureChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "featured_media", "featuredImg");
  };

  const handleFeatureDrop = (e) => {
    handleImageDrop(e, "featured_media", "featuredImg");
  };

  const handleCategoryChange = (categoryId) => {
    setPostDetails((prevDetails) => {
      const updatedCategories = prevDetails.categories.includes(categoryId)
        ? prevDetails.categories.filter((id) => id !== categoryId)
        : [...prevDetails.categories, categoryId];

      return {
        ...prevDetails,
        categories: updatedCategories,
      };
    });
  };
  const handleWebsiteChange = (siteId) => {
    setPostDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };
  const handlePublish = async () => {
    let res = null;
    let details = postDetails;
    if (postDetails.post_tag_details.length > 0) {
      const tagIds = await Promise.all(
        postDetails.post_tag_details.map(async (tag) => {
          if (tag.id) {
            return tag.id;
          } else {
            const matchingTag = tags.find((t) => t.name === tag.name);
            if (matchingTag) {
              return matchingTag.id;
            } else {
              const response = await createTag(tag);
              return response.id;
            }
          }
        })
      );

      details = {
        ...postDetails,
        tags: [...postDetails.post_tags, ...tagIds],
      };
    }

    if (params?.id) {
      res = await updatePost(params?.id, details);
    } else {
      res = await CreatePost(details);
    }

    if (res) {
      navigate(`/posts/${res?.id}`);
      setPublishSuccess(true);
    }
  };

  useEffect(() => {
    const fetchPostData = async () => {
      const response = await getPostData(params?.id);
      let featuredimgData = "";
      let bannerimgData = "";
      let squareimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);
      if (response?.acf?.banner)
        bannerimgData = await fetchMedia(response?.acf?.banner);
      if (response?.acf?.square_image)
        squareimgData = await fetchMedia(response?.acf?.square_image);

      setImages({
        bannerImg: bannerimgData?.source_url,
        squareImg: squareimgData?.source_url,
        featuredImg: featuredimgData?.source_url,
      });
      seLoading(false);
      setPostDetails({
        ...postDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        excerpt: response?.excerpt,
        categories: response?.categories || [],
        extra_pages_cat: response?.extra_pages_cat || [],
        featured_media: featuredimgData?.id || null,
        post_tag_details: response?.post_tag_details || [],
        acf: {
          banner: bannerimgData?.id,
          audio_track: response?.acf?.audio_track,
          square_image: squareimgData?.id,
          short_write_up: response?.acf?.short_write_up,
        },
      });
    };

    if (params?.id) {
      fetchPostData();
    } else {
      setPostDetails(initialDetails);
      setImages({
        bannerImg: "",
        squareImg: "",
        featuredImg: "",
      });
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getCategories(1, 100);
      setCategories(response.data);
    };
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    const fetchTags = async () => {
      const response = await getTags();
      setTags(response);
    };
    fetchTags();
    fetchCategories();
    fetchWebsites();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex", 
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Post" : "Add New Post"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={postDetails.status}
              onChange={(e) =>
                setPostDetails({
                  ...postDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id?"Update":"Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" , mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={postDetails.title}
                        onChange={(e) =>
                          setPostDetails({
                            ...postDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={postDetails.content}
                        onChange={(value) =>
                          setPostDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Blog" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Banner Image</InputLabel>
                      {!Images.bannerImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleBannerDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("banner-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{ position: "relative" }}
                          onMouseEnter={() => setIsBannerHovered(true)}
                          onMouseLeave={() => setIsBannerHovered(false)}
                        >
                          <img
                            src={Images.bannerImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isBannerHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  bannerImg: "",
                                }));
                                setPostDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    banner: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="banner-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleBannerChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Square Image</InputLabel>
                      {!Images.squareImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleSquareDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("square-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsSqureHovered(true)}
                          onMouseLeave={() => setIsSqureHovered(false)}
                        >
                          <img
                            src={Images.squareImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isSqureHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  squareImg: "",
                                }));
                                setPostDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    square_image: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="square-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleSquareChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Audio Track</InputLabel>
                      <TextField
                        placeholder="Audio Track URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="audioTrack"
                        value={postDetails.acf.audio_track}
                        onChange={(e) =>
                          setPostDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              audio_track: e.target.value,
                            },
                          }))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Public />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Short Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={postDetails.acf.short_write_up}
                        onChange={(e) =>
                          setPostDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              short_write_up: e,
                            },
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Excerpt" />
              <CardContent>
                <TextField
                  placeholder="Excerpt"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                  name="excerpt"
                  value={postDetails?.excerpt}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      excerpt: e?.target?.value,
                    })
                  }
                />
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card>
              <CardHeader title="Categories" />
              <CardContent>
                <FormGroup>
                  {categories &&
                    categories?.map((category) => (
                      <FormControlLabel
                        key={category?.id}
                        control={
                          <Checkbox
                            checked={postDetails?.categories?.includes(
                              category.id
                            )}
                            onChange={() => handleCategoryChange(category.id)}
                          />
                        }
                        label={category?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={postDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Tags" />
              <CardContent>
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Tag"
                  variant="outlined"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="contained" onClick={handleAddTag}>
                          Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography variant="caption">
                  Separate tags with commas.
                </Typography>
                <Box>
                  {postDetails?.post_tag_details?.map((tag) => (
                    <Chip
                      key={tag.nme}
                      sx={{ padding: "0 5px", margin: "0 5px" }}
                      label={tag?.name}
                      onDelete={() =>
                        setPostDetails((prevDetails) => ({
                          ...prevDetails,
                          post_tag_details: prevDetails.post_tag_details.filter(
                            (t) => t.name !== tag.name
                          ),
                        }))
                      }
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>

            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!Images.featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={Images.featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setImages((pre) => ({
                            ...pre,
                            featuredImg: "",
                          }));
                          setPostDetails((prevDetails) => ({
                            ...prevDetails,
                            featured_media: null,
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
      F
    </>
  );
}

export default AddPost;
