import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Cancel, CloudUpload, Delete, Public } from "@mui/icons-material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getWebsites } from "../../services/extraSites.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  createEvent,
  getEventData,
  getLinkedProducts,
  updateEvent,
} from "../../services/events.service";
import { createMedia, fetchMedia } from "../../services/media.service";
import dayjs from "dayjs";
import AutoSnackbar from "../../common/AutoSnackbar";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function NewEvent(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [websites, setWebsites] = useState(null);
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const itineraryObj = {
    title: "",
    description: "",
  };
  const initialDetails = {
    title: "",
    content: "",
    acf: {
      type: "Free",
      start_day: "",
      start_time: "",
      end_day: "",
      ends_time: "",
      address: "",
      google_map_location: "",
      itinerary: [],
    },
    extra_pages_cat: [],
    featured_media: null,
    excerpt: "",
    status: "publish",
  };
  const [eventDetails, setEventDetails] = useState(initialDetails);
  const [featuredImg, setFeaturedImg] = useState("");
  const handleFeatureChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setEventDetails({
          ...eventDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setEventDetails({
          ...eventDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleItineraryRow = () => {
    const updatedItinerary = {
      ...eventDetails,
      acf: {
        ...eventDetails.acf,
        itinerary: eventDetails.acf.itinerary
          ? [...eventDetails.acf.itinerary, itineraryObj]
          : [itineraryObj],
      },
    };

    setEventDetails(updatedItinerary);
  };
  const handleRemoveRow = (index) => {
    const updatedItinerary = {
      ...eventDetails,
      acf: {
        ...eventDetails.acf,
        itinerary: eventDetails.acf.itinerary.filter((_, i) => i !== index),
      },
    };

    setEventDetails(updatedItinerary);
  };
  const handleWebsiteChange = (siteId) => {
    setEventDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };

  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateEvent(params?.id, eventDetails);
    } else {
      res = await createEvent(eventDetails);
    }
    if (res) {
      navigate(`/events/${res?.id}`);
      setPublishSuccess(true);
    }
  };

  useEffect(() => {
    const fetchPostData = async () => {
      const response = await getEventData(params?.id);
      let featuredimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);

      setFeaturedImg(featuredimgData?.source_url);
      seLoading(false);
      setEventDetails({
        ...eventDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        excerpt: response?.excerpt,
        extra_pages_cat: response?.extra_pages_cat || [],
        featured_media: featuredimgData?.id || null,
        acf: {
          type: response?.acf?.type || "Free",
          ...(response?.acf?.linked_product && {
            linked_product: response?.acf?.linked_product,
          }),
          start_day: response?.acf?.start_day,
          start_time: response?.acf?.start_time,
          end_day: response?.acf?.end_day,
          ends_time: response?.acf?.ends_time,
          address: response?.acf?.address,
          google_map_location: response?.acf?.google_map_location,
          itinerary: response?.acf?.itinerary || [],
        },
      });
    };

    if (params?.id) {
      fetchPostData();
    } else {
      setEventDetails(initialDetails);
      setFeaturedImg("");
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchLinkedProd = async () => {
      const response = await getLinkedProducts();
      setLinkedProducts(response);
    };
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    fetchLinkedProd();
    fetchWebsites();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Event" : "Add New Event"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={eventDetails?.status}
              onChange={(e) =>
                setEventDetails({
                  ...eventDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" ,mt:"5px"}}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={eventDetails?.title}
                        onChange={(e) =>
                          setEventDetails({
                            ...eventDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={eventDetails?.content}
                        onChange={(value) =>
                          setEventDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Events" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InputLabel>Type</InputLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group"
                        name="event_type"
                        value={eventDetails?.acf?.type}
                        onChange={(e) =>
                          setEventDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              type: e.target.value,
                            },
                          }))
                        }
                      >
                        <FormControlLabel
                          value="Free"
                          control={<Radio />}
                          label="Free"
                        />
                        <FormControlLabel
                          value="Paid"
                          control={<Radio />}
                          label="Paid"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={4}>
                      {eventDetails?.acf?.type === "Paid" && (
                        <>
                          <InputLabel>Linked Product</InputLabel>
                          <Autocomplete
                            fullWidth
                            id="select-Product"
                            options={linkedProducts}
                            getOptionLabel={(option) => option.name}
                            value={
                              linkedProducts.find(
                                (prod) =>
                                  prod.id === eventDetails?.acf?.linked_product
                              ) || null
                            }
                            onChange={(e, newValue) =>
                              setEventDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  linked_product: newValue?.id || "",
                                },
                              }))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select"
                              />
                            )}
                          />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ marginRight: "50px" }}>
                            <InputLabel>Start Date</InputLabel>
                            <DatePicker
                              value={
                                eventDetails?.acf?.start_day
                                  ? dayjs(eventDetails.acf.start_day)
                                  : null
                              }
                              format="DD/MM/YYYY"
                              onChange={(date) => {
                                if (dayjs(date).isValid()) {
                                  const formattedDate =
                                    dayjs(date).format("YYYY-MM-DD");
                                  setEventDetails((prevDetails) => ({
                                    ...prevDetails,
                                    acf: {
                                      ...prevDetails.acf,
                                      start_day: formattedDate,
                                    },
                                  }));
                                }
                              }}
                            />
                          </Box>
                          <Box>
                            <InputLabel>Start Time</InputLabel>
                            <TimePicker
                              value={
                                eventDetails?.acf?.start_time
                                  ? dayjs(
                                      `1970-01-01T${eventDetails.acf.start_time}`
                                    )
                                  : null
                              }
                              onChange={(date) => {
                                if (dayjs(date).isValid()) {
                                  const formattedTime =
                                    dayjs(date).format("HH:mm");
                                  setEventDetails((prevDetails) => ({
                                    ...prevDetails,
                                    acf: {
                                      ...prevDetails.acf,
                                      start_time: formattedTime,
                                    },
                                  }));
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ marginRight: "50px" }}>
                            <InputLabel>End Date</InputLabel>
                            <DatePicker
                              value={
                                eventDetails?.acf?.end_day
                                  ? dayjs(eventDetails.acf.end_day)
                                  : null
                              }
                              format="DD/MM/YYYY"
                              onChange={(date) => {
                                if (dayjs(date).isValid()) {
                                  const formattedDate =
                                    dayjs(date).format("YYYY-MM-DD");
                                  setEventDetails((prevDetails) => ({
                                    ...prevDetails,
                                    acf: {
                                      ...prevDetails.acf,
                                      end_day: formattedDate,
                                    },
                                  }));
                                }
                              }}
                            />
                          </Box>
                          <Box>
                            <InputLabel>End Time</InputLabel>
                            <TimePicker
                              value={
                                eventDetails?.acf?.ends_time
                                  ? dayjs(
                                      `1970-01-01T${eventDetails.acf.ends_time}`
                                    )
                                  : null
                              }
                              onChange={(date) => {
                                if (dayjs(date).isValid()) {
                                  const formattedTime =
                                    dayjs(date).format("HH:mm");
                                  setEventDetails((prevDetails) => ({
                                    ...prevDetails,
                                    acf: {
                                      ...prevDetails.acf,
                                      ends_time: formattedTime,
                                    },
                                  }));
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>Address</InputLabel>
                      <TextField
                        placeholder="Address"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="address"
                        multiline
                        rows={4}
                        value={eventDetails?.acf?.address}
                        onChange={(e) =>
                          setEventDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              address: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Google Map Location</InputLabel>
                      <TextField
                        placeholder="Google Map Location URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="locationUrl"
                        value={eventDetails?.acf?.google_map_location}
                        onChange={(e) =>
                          setEventDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              google_map_location: e.target.value,
                            },
                          }))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Public />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Itinerary</InputLabel>
                      <Box
                        sx={{
                          padding: "10px",
                          background: "#ebebeb",
                          margin: "10px 0",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {eventDetails.acf?.itinerary &&
                        eventDetails.acf?.itinerary?.length !== 0 ? (
                          eventDetails.acf?.itinerary.map((row, index) => (
                            <Paper
                              key={index}
                              sx={{
                                padding: "20px",
                                position: "relative",
                                margin: "5px",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <InputLabel>Title</InputLabel>
                                  <TextField
                                    placeholder="Title"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="title"
                                    value={row.title}
                                    onChange={(e) =>
                                      setEventDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          itinerary:
                                            prevDetails.acf.itinerary.map(
                                              (item, i) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      title: e.target.value,
                                                    }
                                                  : item
                                            ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <InputLabel>Description</InputLabel>
                                  <TextField
                                    placeholder="Description"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    name="description"
                                    multiline
                                    rows={3}
                                    value={row.description}
                                    onChange={(e) =>
                                      setEventDetails((prevDetails) => ({
                                        ...prevDetails,
                                        acf: {
                                          ...prevDetails.acf,
                                          itinerary:
                                            prevDetails.acf.itinerary.map(
                                              (item, i) =>
                                                i === index
                                                  ? {
                                                      ...item,
                                                      description:
                                                        e.target.value,
                                                    }
                                                  : item
                                            ),
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <IconButton
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: "95%",
                                }}
                                onClick={() => handleRemoveRow(index)}
                              >
                                <Cancel />
                              </IconButton>
                            </Paper>
                          ))
                        ) : (
                          <Typography>No Rows</Typography>
                        )}
                      </Box>
                      <Button variant="contained" onClick={handleItineraryRow}>
                        Add Row
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Excerpts" />
              <CardContent>
                <TextField
                  placeholder="Excerpts"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="excerpts"
                  multiline
                  rows={3}
                  value={eventDetails?.excerpt}
                  onChange={(e) =>
                    setEventDetails({
                      ...eventDetails,
                      excerpt: e?.target?.value,
                    })
                  }
                />
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={eventDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            {/* <Card sx={{ marginTop: "20px" }}>
            <CardHeader title="Event Attributes" />
            <CardContent>
              <InputLabel>Parrent</InputLabel>
              <Select
                sx={{ mt: "5px" }}
                fullWidth
                labelId="select-parrent"
                id="select-parrent"
                placeholder="Select"
                value={0}
              >
                <MenuItem value={0}>No parrent</MenuItem>
                <MenuItem value={10}>Demo 1</MenuItem>
                <MenuItem value={20}>Demo 2</MenuItem>
              </Select>
              <InputLabel sx={{ marginTop: "10px" }}>Order</InputLabel>
              <TextField
                type="nubmer"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card> */}
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setFeaturedImg("");
                          setEventDetails({
                            ...eventDetails,
                            featured_media: null,
                          });
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default NewEvent;
