import { axiosInstance, axiosInstance_WC } from "./axiosInstence";

export const getAllMeditations = async (currentPage, ItemPerPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `meditation?page=${currentPage || 1}&per_page=${
        ItemPerPage || 10
      }&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};
export const getMeditationData = async (id) => {
  try {
    const response = await axiosInstance.get(`meditation/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getMeditationCategories = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `meditation_category?page=${page || 1}&per_page=${
        perPage || 10
      }&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};
export const getMeditationTags = async (page, perPage) => {
  try {
    const response = await axiosInstance.get(
      `tags?page=${page || 1}&per_page=${
        perPage || 10
      }&status=publish,draft`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};

// export const deteleMeditation = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`meditation/${id}`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
export const updateMeditation = async (id, data) => {
  try {
    const response = await axiosInstance.post(`meditation/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const createMeditation = async (data) => {
  try {
    const response = await axiosInstance.post("meditation", data);

    if (data.featured_media)
      axiosInstance.post(
        `media/${data.featured_media}?meditation=${response?.data?.id}`
      );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getMedLinkedProducts = async () => {
  try {
    const response = await axiosInstance_WC.get("products?category=100");
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateMeditationCategory = async (data) => {
  try {
    const response = await axiosInstance.post(
      `meditation_category/${data?.id}`,
      data
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const addMeditationCategory = async (data) => {
  try {
    const response = await axiosInstance.post("meditation_category", data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
// export const deleteMeditationCategory = async (id) => {
//   try {
//     const response = await axiosInstance.delete(
//       `meditation_category/${id}?force=true`
//     );
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
