import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getAllSubscriptions } from "../../services/subscriptions.service";
import dayjs from "dayjs";
function Subscriptions(props) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);

  const columns = [
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "subscription",
      headerName: "Subscription",
      // type: "string",
      width: 180,
    },
    {
      field: "items",
      headerName: "Items",
      width: 180,
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      valueFormatter: (params) =>
              params.value && dayjs(params.value).format("DD/MM/YYYY"),
    },
    { field: "next_payment", headerName: "Next Payment", width: 150 , valueFormatter: (params) =>
    params.value && dayjs(params.value).format("DD/MM/YYYY")},
    {
      field: "last_order_date",
      headerName: "Last Order Date",
      width: 150,
      valueFormatter: (params) =>
      params.value && dayjs(params.value).format("DD/MM/YYYY"),
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 150,
      valueFormatter: (params) =>
      params.value && dayjs(params.value).format("DD/MM/YYYY"),
    },
  ];

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const subscriptions = await getAllSubscriptions(currentPage, itemsPerPage);
      const subscriptionsList =
        subscriptions?.data?.map((sub) => ({
          id: sub?.id,
          status: sub?.status,
          subscription:`#${sub?.id} ${sub?.billing.first_name} ${sub?.billing.last_name}`,
          items:sub?.line_items?.map((item) => item?.name)?.join(", "),
          total: `₹${sub?.total} every`+ " " + sub?.billing_interval + " " + sub?.billing_period,
          start_date: sub?.start_date_gmt,
          next_payment: sub?.next_payment_date_gmt,
          last_order_date: sub?.last_payment_date_gmt,
          end_date :sub?.end_date_gmt
        })) || [];
      setTotalCount(subscriptions.totalCount);
      setLoading(false);
      setRows(subscriptionsList);
    } catch (error) {
      console.error("Error fetching subscriptions:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);
  return (
    <Container>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4">Subscriptions</Typography>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={totalCount}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
    </Container>
  );
}

export default Subscriptions;
