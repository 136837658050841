import { axiosInstance_WC, axiosInstance_custom, axiosInstance_custom_user } from "./axiosInstence";

export const getUserMeta = async() => {
    try {
      const response = await axiosInstance_custom.get("users_meta_course/?pattern=application_%");
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  
  export const updateApplication = async(req) => {
    try {
      const response = await axiosInstance_custom_user.post("update",req);
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  export const getAllPayments = async(productId) => {
    try {
      const response = await axiosInstance_custom.get(`all_subscriptions?product_id=${productId}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  export const cancelSubscrition = async(id) => {
    try {
      const response = await axiosInstance_WC.put(`subscriptions/${id}`,{status: "pending-cancel"});
      return response.data;
    } catch (err) {
      throw err;
    }
  };
  
  