import { axiosInstance } from "./axiosInstence";

export const getAllPodcasts = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `podcast?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
export const getPodcastData = async (id) => {
  const response = await axiosInstance
    .get(`podcast/${id}`)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};

export const getPodcastCategories = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `podcast_category?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
      );
      const totalCount = response.headers.get("X-Wp-Total");
      const totalPages = response.headers.get("X-Wp-TotalPages");
      return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};

// export const detelePodcast = async (id) => {
//   const response = await axiosInstance
//     .delete(`podcast/${id}`)
//     .then((res) => res.data)
//     .catch((err) => err.message);
//   return response;
// };
export const updatePodcast = async (id, data) => {
  const response = await axiosInstance
    .post(`podcast/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const createPodcast = async (data) => {
  const response = await axiosInstance
    .post("podcast", data)
    .then((res) => {
      if (data.featured_media)
        axiosInstance.post(
          `media/${data.featured_media}?podcast=${res?.data?.id}`
        );
      return res.data;
    })
    .catch((err) => err.message);
  return response;
};

export const updatePodcastCategory = async (data) => {
  const response = await axiosInstance
    .post(`podcast_category/${data?.id}`, data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const addPodcastCategory = async (data) => {
  const response = await axiosInstance
    .post("podcast_category", data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
// export const deletePodcastCategory = async (id) => {
//   const response = await axiosInstance
//     .delete(`podcast_category/${id}?force=1`)
//     .then((res) => res.data)
//     .catch((err) => err.message);
//   return response;
// };
