import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Select, MenuItem } from "@mui/material";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Cancel, CloudUpload, Delete, Image } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  createVideo,
  getVideoCategories,
  getVideoData,
  updateVideo,
} from "../../services/video.service";
import { createMedia, fetchMedia } from "../../services/media.service";
import { getWebsites } from "../../services/extraSites.service";
import AutoSnackbar from "../../common/AutoSnackbar";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddVideo(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isSqureHovered, setIsSqureHovered] = useState(false);
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [categories, setCategories] = useState(null);
  const [websites, setWebsites] = useState(null);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const initialDetails = {
    title: "",
    content: "",
    acf: {
      banner: null,
      square_image: null,
      video_type: "S3",
      video_link: "",
    },
    video_category: [],
    extra_pages_cat: [],
    featured_media: null,
    excerpt: "",
    status: "publish",
  };
  const [videoDetails, setVideoDetails] = useState(initialDetails);
  const [Images, setImages] = useState({
    bannerImg: "",
    squareImg: "",
    featuredImg: "",
  });

  const handleBannerChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          bannerImg: reader.result,
        }));
        setVideoDetails((prevDetails) => ({
          ...prevDetails,
          acf: {
            ...prevDetails.acf,
            banner: res.id,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          bannerImg: reader.result,
        }));
        setVideoDetails((prevDetails) => ({
          ...prevDetails,
          acf: {
            ...prevDetails.acf,
            banner: res.id,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSquareChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          squareImg: reader.result,
        }));
        setVideoDetails((prevDetails) => ({
          ...prevDetails,
          acf: {
            ...prevDetails.acf,
            square_image: res.id,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSquareDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          squareImg: reader.result,
        }));
        setVideoDetails((prevDetails) => ({
          ...prevDetails,
          acf: {
            ...prevDetails.acf,
            square_image: res.id,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          featuredImg: reader.result,
        }));
        setVideoDetails({
          ...videoDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((pre) => ({
          ...pre,
          featuredImg: reader.result,
        }));
        setVideoDetails({
          ...videoDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCategoryChange = (categoryId) => {
    setVideoDetails((prevDetails) => {
      const updatedCategories = prevDetails.video_category.includes(categoryId)
        ? prevDetails.video_category.filter((id) => id !== categoryId)
        : [...prevDetails.video_category, categoryId];

      return {
        ...prevDetails,
        video_category: updatedCategories,
      };
    });
  };
  const handleWebsiteChange = (siteId) => {
    setVideoDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };
  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateVideo(params?.id, videoDetails);
    } else {
      res = await createVideo(videoDetails);
      navigate(`/videos/${res?.id}`);
    }
    if (res) {
      setPublishSuccess(true);
    }
  };
  useEffect(() => {
    const fetchVideoData = async () => {
      const response = await getVideoData(params?.id);
      let featuredimgData = "";
      let bannerimgData = "";
      let squareimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);
      if (response?.acf?.banner)
        bannerimgData = await fetchMedia(response?.acf?.banner);
      if (response?.acf?.square_image)
        squareimgData = await fetchMedia(response?.acf?.square_image);

      setImages({
        bannerImg: bannerimgData?.source_url,
        squareImg: squareimgData?.source_url,
        featuredImg: featuredimgData?.source_url,
      });
      seLoading(false);
      setVideoDetails({
        ...videoDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        excerpt: response?.excerpt,
        video_category: response?.video_category || [],
        extra_pages_cat: response?.extra_pages_cat || [],
        featured_media: featuredimgData?.id || null,
        acf: {
          banner: bannerimgData?.id || null,
          video_type: response?.acf?.video_type || "S3",
          square_image: squareimgData?.id || null,
          video_link: response?.acf?.video_link,
        },
      });
    };

    if (params?.id) {
      fetchVideoData();
    } else {
      setVideoDetails(initialDetails);
      setImages({
        bannerImg: "",
        squareImg: "",
        featuredImg: "",
      });
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getVideoCategories();
      setCategories(response.data);
    };
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    fetchCategories();
    fetchWebsites();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Video" : "Add New Video"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={videoDetails.status}
              onChange={(e) =>
                setVideoDetails({
                  ...videoDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={videoDetails.title}
                        onChange={(e) =>
                          setVideoDetails({
                            ...videoDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={videoDetails.content}
                        onChange={(value) =>
                          setVideoDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Videos" />
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Video Type</InputLabel>
                      <Select
                        sx={{ mt: "5px" }}
                        value={videoDetails.acf.video_type}
                        onChange={(e) =>
                          setVideoDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              video_type: e.target.value,
                            },
                          }))
                        }
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="videoType"
                      >
                        <MenuItem value="S3">S3</MenuItem>
                        <MenuItem value="Youtube">YouTube</MenuItem>
                        <MenuItem value="Vimeo">Vimeo</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Video URL</InputLabel>
                      <TextField
                        placeholder="Video URL"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="videolink"
                        value={videoDetails.acf.video_link}
                        onChange={(e) =>
                          setVideoDetails((prevDetails) => ({
                            ...prevDetails,
                            acf: {
                              ...prevDetails.acf,
                              video_link: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Banner Image</InputLabel>
                      {!Images.bannerImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleBannerDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("banner-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{ position: "relative" }}
                          onMouseEnter={() => setIsBannerHovered(true)}
                          onMouseLeave={() => setIsBannerHovered(false)}
                        >
                          <img
                            src={Images.bannerImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isBannerHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  bannerImg: "",
                                }));
                                setVideoDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    banner: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="banner-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleBannerChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Square Image</InputLabel>
                      {!Images.squareImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleSquareDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("square-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsSqureHovered(true)}
                          onMouseLeave={() => setIsSqureHovered(false)}
                        >
                          <img
                            src={Images.squareImg}
                            alt="Banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isSqureHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  squareImg: "",
                                }));
                                setVideoDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf: {
                                    ...prevDetails.acf,
                                    square_image: null,
                                  },
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="square-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleSquareChange}
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Excerpts" />
              <CardContent>
                <TextField
                  placeholder="Excerpts"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="excerpts"
                  multiline
                  rows={3}
                  value={videoDetails?.excerpt}
                  onChange={(e) =>
                    setVideoDetails({
                      ...videoDetails,
                      excerpt: e?.target?.value,
                    })
                  }
                />
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card>
              <CardHeader title="Video Categories" />
              <CardContent>
                <FormGroup>
                  {categories &&
                    categories?.map((category) => (
                      <FormControlLabel
                        key={category?.id}
                        control={
                          <Checkbox
                            checked={videoDetails?.video_category?.includes(
                              category.id
                            )}
                            onChange={() => handleCategoryChange(category.id)}
                          />
                        }
                        label={category?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={videoDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>

            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!Images.featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={Images.featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setImages((pre) => ({
                            ...pre,
                            featuredImg: "",
                          }));
                          setVideoDetails((prevDetails) => ({
                            ...prevDetails,
                            featured_media: null,
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddVideo;
