import { axiosInstance } from "./axiosInstence";

export const getAllPosts = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `posts?page=${page || 1}&per_page=${perPage || 10}&status=publish,draft&search=${seachText}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
export const getPostData = async (id) => {
  try {
    const response = await axiosInstance.get(`posts/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getCategories = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `categories?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
      );
      const totalCount = response.headers.get("X-Wp-Total");
      const totalPages = response.headers.get("X-Wp-TotalPages");
      return {data:response.data,totalCount:totalCount,totalPages:totalPages};
  } catch (err) {
    throw err;
  }
};
export const getTags = async (id) => {
  try {
    const response = await axiosInstance.get("tags");
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const createTag = async(data)=>{
  try {
    const response = await axiosInstance.post("tags",data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
// export const deteleTag = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`tags/${id}?force=true`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
// export const detelePost = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`posts/${id}`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };

export const updatePost = async (id, data) => {
  try {
    const response = await axiosInstance.post(`posts/${id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const CreatePost = async (data) => {
  try {
    const response = await axiosInstance.post("posts", data);

    if (data.featured_media)
      axiosInstance.post(
        `media/${data.featured_media}?post=${response?.data?.id}`
      );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateCategory = async (data) => {
  try {
    const response = await axiosInstance.post(`categories/${data?.id}`, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const addCategory = async (data) => {
  try {
    const response = await axiosInstance.post("categories", data);
    return response.data;
  } catch (err) {
    throw err;
  }
};
// export const deleteCategory = async (id) => {
//   try {
//     const response = await axiosInstance.delete(`categories/${id}?force=true`);
//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };
