// src/components/Login.js

import React, { useState } from "react";
import { TextField, Button, Paper, InputLabel } from "@mui/material";
import { admin_login } from "../services/login.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginActions } from "../redux/Slice/authSlice";
import AutoSnackbar from "../common/AutoSnackbar";

const Login = () => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({});
  const [failed,setFailed]= useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFailed("");

    // Validate form data
    const newErrors = {};
    if (!formData.username) {
      newErrors.username = "Username is required";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length === 0) {
      dispatch(
        loginActions.loginUser({
          username: formData.username,
          password: formData.password,
        })
      ).then((res) => {
        console.log(res);
        if (res?.payload?.token) {
          navigate("/");
        }
        else setFailed(res?.payload)
      })
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
    {failed && (
       <AutoSnackbar
         message={failed}
         severity="error"
       />
     )}
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <form onSubmit={handleSubmit}>
        <InputLabel>Username or Email Address</InputLabel>
        <TextField
          placeholder="Enter username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.username}
          helperText={errors.username}
        />
        <InputLabel>Password</InputLabel>
        <TextField
          placeholder="Enter password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.password}
          helperText={errors.password}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "20px" }}
        >
          Log In
        </Button>
      </form>
    </Paper>
    </>
  );
};

export default Login;
