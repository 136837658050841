import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ toggle }) => {
  const roles = useSelector((state) => state?.auth?.user?.roles) || [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sidebarMenu = [
    {
      key: "posts",
      label: "Posts",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_posts",
          label: "All Posts",
          url: "/posts",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/newpost",
        },
        {
          key: "category",
          label: "Category",
          url: "/posts/categories",
        },
        {
          key: "tags",
          label: "Tags",
          url: "/posts/tags",
        },
      ],
    },
    {
      key: "courses",
      label: "Courses",
      // isTitle: false,
      icon: "ri-compasses-2-line",
      children: [
        {
          key: "all",
          label: "All",
          url: "/courses",
        },
        {
          key: "new",
          label: "New",
          url: "/addcourse",
        },
        {
          key: "category",
          label: "Category",
          url: "/courses/categories",
        },
        {
          key: "programs",
          label: "Programs",
          url: "/programs",
        },
      ],
    },
    {
      key: "videos",
      label: "Videos",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Videos",
          label: "All Videos",
          url: "/videos",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/addvideo",
        },
        {
          key: "category",
          label: "Category",
          url: "/videos/categories",
        },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "/videotag",
        // },
      ],
    },
    {
      key: "events",
      label: "Events",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Events",
          label: "All Events",
          url: "/events",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/newevent",
        },
        // {
        //   key: "category",
        //   label: "Category",
        //   url: "/eventscategories",
        // },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "/eventstag",
        // },
      ],
    },
    {
      key: "meditations",
      label: "Meditations",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Meditattions",
          label: "All Meditations",
          url: "/meditations",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/addmeditation",
        },
        {
          key: "category",
          label: "Category",
          url: "/meditations/categories",
        },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "/meditations/tags",
        // },
      ],
    },
    {
      key: "challenges",
      label: "Challenges",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Challenges",
          label: "All Challenges",
          url: "/challenges",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/addchallenge",
        },
        // {
        //   key: "category",
        //   label: "Category",
        //   url: "/challengecategories",
        // },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "/challengetag",
        // },
      ],
    },
    {
      key: "podcast",
      label: "Podcast",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Podcast",
          label: "All Podcast",
          url: "/podcast",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/addpodcast",
        },
        {
          key: "category",
          label: "Category",
          url: "/podcast/categories",
        },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "/podcasttag",
        // },
      ],
    },
    {
      key: "webinar",
      label: "Webinar",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Webinar",
          label: "All Webinar",
          url: "/webinar",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/addwebinar",
        },
        {
          key: "category",
          label: "Category",
          url: "/webinar/categories",
        },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "/webinartag",
        // },
      ],
    },
    {
      key: "drives",
      label: "Drives",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Drives",
          label: "All Drives",
          url: "/drive",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/adddrive",
        },
        // {
        //   key: "category",
        //   label: "Category",
        //   url: "",
        // },
        // {
        //   key: "tags",
        //   label: "Tags",
        //   url: "",
        // },
      ],
    },
    {
      key: "products",
      label: "Products",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_Products",
          label: "All Products",
          url: "/products",
        },
        // {
        //   key: "add_new",
        //   label: "Add New",
        //   url: "/addproduct",
        // },
        // {
        //   key: "category",
        //   label: "Category",
        //   url: "/products/categories",
        // },
      ],
    },
    {
      key: "reports",
      label: "Reports",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "orders",
          label: "Orders",
          url: "/orders",
        },
        {
          key: "subscriptions",
          label: "Subscriptions",
          url: "/subscriptions",
        },
      ],
    },
    {
      key: "user_manage",
      label: "User Manage",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "all_users",
          label: "All Users",
          url: "/users",
        },
        {
          key: "add_new",
          label: "Add New",
          url: "/adduser",
        },
      ],
    },
    {
      key: "track",
      label: "Track",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "applications",
          label: "Applications",
          url: "/track/applications",
        },
        {
          key: "payments",
          label: "Payments",
          url: "/track/payments",
        },
      ],
    },
    {
      key: "settings",
      label: "Settings",
      icon: "ri-briefcase-line",
      children: [
        {
          key: "badges",
          label: "Badges",
          url: "/badges",
        },
        {
          key: "websites",
          label: "Websites",
          url: "/websites",
        },
        {
          key: "payment_gateway",
          label: "Payment Gateway",
          url: "/payments",
        },
        {
          key: "groups",
          label: "Groups",
          url: "/groups",
        },
      ],
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState("/");
  const [rolesMenu, setRolesMenu] = useState([]);
  const handleAccordionToggle = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleNavigate = (url) => {
    const matchedMenuItem = rolesMenu.find(
      (menuGroup) =>
        menuGroup.children &&
        menuGroup.children.some((item) => url.startsWith(item.url))
    );

    if (matchedMenuItem) {
      setOpenAccordionIndex(rolesMenu.indexOf(matchedMenuItem));
      const currentRoute = url;
      setCurrentPage(
        currentRoute.match(/\/\w+\/\d+$/)
          ? `/${currentRoute.split("/")[1]}`
          : currentRoute
      );
      navigate(url);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    const filteredMenu = sidebarMenu.filter((menuGroup) => {
      if (roles.includes("administrator")) {
        return true;
      } else if (
        roles.includes("mentor") &&
        ["courses", "track", "reports", "webinar"].includes(menuGroup.key)
      ) {
        return true;
      } else if (
        roles.includes("finance") &&
        ["reports", "products"].includes(menuGroup.key)
      ) {
        return true;
      } else if (
        roles.includes("publisher") &&
        ["posts", "videos", "podcast", "meditations", "challenges"].includes(
          menuGroup.key
        )
      ) {
        return true;
      }
      return false;
    });
    setRolesMenu(filteredMenu);
  }, []);

  useEffect(() => {
    if (rolesMenu.length > 0) handleNavigate(location.pathname);
  }, [rolesMenu]);

  return (
    <Drawer
      variant="persistent"
      sx={{
        // width: "15%",
        "& .MuiPaper-root.MuiDrawer-paper": {
          width: "15%",
          background: "#1a2942",
          color: "#f1f1f1",
        },
      }}
      open={toggle}
    >
      <Box
        sx={{
          padding: "25px",
          position: "sticky",
          top: 0,
          background: "#1a2942",
          zIndex: 1,
        }}
      >
        <Typography>Supramental Awakening</Typography>
      </Box>
      <List sx={{ overflow: "auto" }}>
        {rolesMenu.map((menuGroup, index) => (
          <Accordion
            key={menuGroup.key}
            expanded={index === openAccordionIndex}
            onChange={() => handleAccordionToggle(index)}
            sx={{
              "&.MuiAccordion-root.Mui-expanded": {
                margin: 0,
              },
            }}
          >
            <AccordionSummary
              sx={{
                "&.MuiButtonBase-root.MuiAccordionSummary-root": {
                  background: "#1a2942",
                  color: "#fff",
                  height: "30px",
                },
                "&.MuiButtonBase-root.MuiAccordionSummary-root:hover": {
                  background: "#333f52",
                },
                "&.MuiAccordionSummary-root.Mui-expanded": {
                  // minHeight: "30px",
                },
              }}
              expandIcon={<ExpandMore sx={{ color: "#fff" }} />}
            >
              <ListItemText>{menuGroup.label}</ListItemText>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                // background: "#1a2942",
                // color: "#fff",
                padding: 0,
              }}
            >
              <List>
                {menuGroup &&
                  menuGroup?.children?.map((Item) => (
                    <ListItem
                      key={Item.key}
                      button
                      sx={{
                        "& .MuiList-root": { padding: 0 },
                        "&.MuiButtonBase-root.MuiListItem-root:hover": {
                          background: "#ccc",
                        },
                        "&.MuiButtonBase-root.MuiListItem-root": {
                          background: `${
                            currentPage === Item.url ? "#333f52" : "initial"
                          }`,
                          color: `${
                            currentPage === Item.url ? "#fff" : "initial"
                          }`,
                        },
                      }}
                      onClick={() => handleNavigate(Item.url)}
                    >
                      <ListItemText>{Item.label}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
