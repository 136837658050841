import { axiosInstance } from "./axiosInstence";

export const getWebsites = async (page, perPage,seachText) => {
  try {
    const response = await axiosInstance.get(
      `extra_pages_cat?page=${page || 1}&per_page=${perPage || 10}&search=${seachText || ""}`
    );
    const totalCount = response.headers.get("X-Wp-Total");
    const totalPages = response.headers.get("X-Wp-TotalPages");
    return {
      data: response.data,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  } catch (err) {
    throw err;
  }
};
export const updateWebsite = async (data) => {
  const response = await axiosInstance
    .post(`extra_pages_cat/${data?.id}`, data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const createWebsite = async (data) => {
  const response = await axiosInstance
    .post("extra_pages_cat", data)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const deleteWebsite = async (id) => {
  const response = await axiosInstance
    .delete(`extra_pages_cat/${id}?force=true`)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
