import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getAllProducts } from "../../services/products.service";
function Products(props) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
 
  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "stock",
      headerName: "Stock",
      width: 150,
      renderCell: (params) => {
        const stockStatus =
          params.value === "instock" ? "In stock" : params.value;
        return <div style={{color:"green"}}>{stockStatus}</div>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 250,
      renderCell: (params)=>{
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = params.value;
        const priceValue = tempDiv.textContent || tempDiv.innerText || "";
    
        return <div dangerouslySetInnerHTML={{ __html: priceValue }} />
      },
    },
    { field: "categories", headerName: "Categories", width: 180 },
    {
      field: "websites",
      headerName: "Websites",
      width: 180,
    },
    {
      field: "date",
      headerName: "Date",
      // type: "string",
      width: 180,
    },
  ];

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const products = await getAllProducts(currentPage, itemsPerPage);
      const productsList =
        products?.data?.map((prod) => ({
          id: prod?.id,
          name: prod?.name,
          stock: prod?.stock_status,
          price: prod?.price_html,
          categories: prod?.product_cat_details
            ?.map((category) => category?.name)
            ?.join(", "),
          websites: prod?.extra_pages_cat_details
            ?.map((page) => page?.name)
            ?.join(", "),
          date: prod?.date_created,
        })) || [];
      setTotalCount(products.totalCount);
      setLoading(false);
      setRows(productsList);
    } catch (error) {
      console.error("Error fetching products:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);
  return (
    <Container>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4">Products</Typography>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card>
        <CardContent>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={totalCount}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
    </Container>
  );
}

export default Products;
