import { Delete, Edit, Public, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import React, { useEffect, useState } from "react";
// import {
//   createWebsite,
//   deleteWebsite,
//   getWebsites,
//   updateWebsite,
// } from "../../services/extraSites.service";
import AlertDialog from "../../common/AlertDialog";
import {
  createPaymentGateway,
  deletePaymentGateway,
  getPaymentGateways,
  updatePaymentGateway,
} from "../../services/paymentGateway.service";

function PaymentGateway(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [alert, setAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const initialDetails = {
    id: null,
    name: "",
    parent: 0,
    description: "",
    slug: "",
    acf: {
      start_date: "",
      end_date: "",
      video_link: "",
      key: "",
      secret: "",
      webhook: "",
    },
  };
  const [paymentDetails, setPaymentDetails] = useState(initialDetails);
  const [search, setSearch] = useState("");
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 180,
    },

    { field: "description", headerName: "Description", width: 200 },
    {
      field: "slug",
      headerName: "Slug",
      // type: "number",
      width: 200,
    },
    {
      field: "count",
      headerName: "Count",
      // type: "string",
      width: 100,
    },
    {
      width: 100,
      renderCell: (params) =>
        hoveredRowId === params?.id && (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => setPaymentDetails(params?.row)}>
              <Edit />
            </IconButton>
            {/* <IconButton
              onClick={() => {
                setAlert(true);
                setRowId(params.id);
              }}
            >
              <Delete />
            </IconButton> */}
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];
  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const handleRowHover = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(Number(rowId));
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );
  };
  const handleRowLeaved = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(null);
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: false } })
    );
  };

  // const handleDelete = async () => {
  //   await deletePaymentGateway(rowId);
  //   setAlert(false);
  //   fetchData();
  // };
  const handleSubmit = async () => {
    const { count, ...details } = paymentDetails;

    if (paymentDetails?.id) {
      await updatePaymentGateway(details);
    } else {
      await createPaymentGateway(details);
    }

    setPaymentDetails(initialDetails);
    fetchData();
  };
  const fetchData = async () => {
    try {
      const payments = await getPaymentGateways(
        currentPage,
        itemsPerPage,
        search
      );
      const paymentsRows = [];
      payments?.data?.map((pay) => {
        paymentsRows.push({
          id: pay?.id,
          name: pay?.name,
          description: pay?.description,
          slug: pay?.slug,
          count: pay?.count,
          parent: pay?.parent,
          acf: {
            start_date: "",
            end_date: "",
            video_link: pay?.acf?.video_link,
            key: pay?.acf?.key,
            secret: pay?.acf?.secret,
            webhook: pay?.acf?.webhook,
          },
        });
      });
      setTotalCount(payments.totalCount);
      setRows(paymentsRows);
    } catch (error) {
      console.error("Error fetching payments:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, search]);
  return (
    <Container>
      <Box
        sx={{
          padding: "15px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Payment Gateway</Typography>

        {paymentDetails?.id && (
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: 500,
            }}
            onClick={() => setPaymentDetails(initialDetails)}
          >
            Add
          </Button>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            width: "28%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Card sx={{ overflow: "hidden", flexGrow: 1 }}>
            <CardHeader
              title={
                paymentDetails?.id
                  ? "Edit Payment Gateway"
                  : "Add Payment Gateway"
              }
            />
            <CardContent sx={{ overflowY: "auto", maxHeight: "65vh" }}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Name</InputLabel>
                    <TextField
                      placeholder="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Name"
                      value={paymentDetails.name}
                      onChange={(e) =>
                        setPaymentDetails((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Slug</InputLabel>
                    <TextField
                      placeholder="Slug"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Slug"
                      value={paymentDetails.slug}
                      onChange={(e) =>
                        setPaymentDetails((prev) => ({
                          ...prev,
                          slug: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Parent Payment Gateway</InputLabel>
                    <Select
                      sx={{ mt: "5px" }}
                      fullWidth
                      labelId="select-Product"
                      id="select-Product"
                      placeholder="Select"
                      value={paymentDetails?.parent}
                      onChange={(e) =>
                        setPaymentDetails((prevDetails) => ({
                          ...prevDetails,
                          parent: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value={0} disabled>
                        Select
                      </MenuItem>
                      {rows
                        ?.filter((row) => row.id !== paymentDetails.id)
                        ?.map((payment) => (
                          <MenuItem key={payment.id} value={payment.id}>
                            {payment?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Description</InputLabel>
                    <TextField
                      placeholder="Description"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="Description"
                      multiline
                      rows={3}
                      value={paymentDetails.description}
                      onChange={(e) =>
                        setPaymentDetails((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Vedio Link</InputLabel>
                    <TextField
                      placeholder="Vedio Link"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="vediourl"
                      value={paymentDetails.acf.video_link}
                      onChange={(e) =>
                        setPaymentDetails((prevDetails) => ({
                          ...prevDetails,
                          acf: {
                            ...prevDetails.acf,
                            video_link: e.target.value,
                          },
                        }))
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Public />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Key</InputLabel>
                    <TextField
                      placeholder="Key"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="key"
                      value={paymentDetails.acf.key}
                      onChange={(e) =>
                        setPaymentDetails((prevDetails) => ({
                          ...prevDetails,
                          acf: {
                            ...prevDetails.acf,
                            key: e.target.value,
                          },
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Secret</InputLabel>
                    <TextField
                      placeholder="Secret"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="secret"
                      value={paymentDetails.acf.secret}
                      onChange={(e) =>
                        setPaymentDetails((prevDetails) => ({
                          ...prevDetails,
                          acf: {
                            ...prevDetails.acf,
                            secret: e.target.value,
                          },
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Webhook</InputLabel>
                    <TextField
                      placeholder="Webhook"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="webhook"
                      value={paymentDetails.acf.webhook}
                      onChange={(e) =>
                        setPaymentDetails((prevDetails) => ({
                          ...prevDetails,
                          acf: {
                            ...prevDetails.acf,
                            webhook: e.target.value,
                          },
                        }))
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Public />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                variant="contained"
                sx={{ fontSize: "18px", fontWeight: "500", marginTop: "10px" }}
                onClick={handleSubmit}
              >
                {paymentDetails?.id ? "Update" : "Submit"}{" "}
              </Button>
            </CardActions>
          </Card>
        </Box>
        <Box sx={{ width: "70%" }}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  padding: "15px 0",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 400,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <Search />
                  </IconButton>
                </Paper>
              </Box>
              <DataGrid
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: itemsPerPage,
                    },
                  },
                }}
                pagination
                paginationMode="server"
                pageSize={itemsPerPage}
                rowCount={totalCount}
                pageSizeOptions={[10, 20, 50]}
                onPaginationModelChange={(params) => handlePageChange(params)}
                disableRowSelectionOnClick
                slotProps={{
                  row: {
                    onMouseEnter: handleRowHover,
                    onMouseLeave: handleRowLeaved,
                  },
                }}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
      {/* <AlertDialog
        open={alert}
        setOpen={(isOpen) => setAlert(isOpen)}
        handleConfirm={handleDelete}
        dialogText="Are you sure you want to delete?"
      /> */}
    </Container>
  );
}

export default PaymentGateway;
