import axios from "axios";

export const admin_login = async(credentials)=>{
    const response = await axios
    .post(`${process.env.REACT_APP_SITE_URL}wp-json/jwt-auth/v1/token`,{
        username: credentials.username,
        password: credentials.password,
      })
    .then((res) => res.data)
    .catch((err)=>err.message);
  return response;
}