import { axiosInstance } from "./axiosInstence";

export const fetchMedia = async (id) => {
  const response = await axiosInstance
    .get(`media/${id}`)
    .then((res) => res.data)
    .catch((err) => err.message);
  return response;
};
export const createMedia = async (filepath,post) => {
  // axiosInstance.defaults.headers.common[
  //   "Content-Disposition"
  // ] = `attachment; filename=${filepath?.name}`;
  const formData = new FormData();
  formData.append("file", filepath);
  formData.append("title", filepath?.name.replace(/\.[^/.]+$/, ""));
  if(post)
  formData.append("post", post);

  const response = await axiosInstance
    .post("media", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((err) => err.message);

  // delete axiosInstance.defaults.headers.common["Content-Disposition"];

  return response;
};

