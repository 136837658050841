import {
  Add,
  Cancel,
  CloudUpload,
  Delete,
  Edit,
  Public,
  Remove,
} from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { getWebsites } from "../../services/extraSites.service";
import { getPrograms } from "../../services/programs.service";
import {
  createCourse,
  getAllCourses,
  getAssignmentTemplates,
  getBatches,
  getCourseCategories,
  getCourseData,
  getCourseGroup,
  getCoursesLinkedProducts,
  getKriyaList,
  getMeditationList,
  updateCourse,
} from "../../services/courses.service";
import { getPaymentGateways } from "../../services/paymentGateway.service";
import { useNavigate, useParams } from "react-router-dom";
import { getBadges } from "../../services/badges.service";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AutoSnackbar from "../../common/AutoSnackbar";
import { createMedia, fetchMedia } from "../../services/media.service";
import { DataGrid } from "@mui/x-data-grid";
import AlertDialog from "../../common/AlertDialog";
const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};
function AddCourse(props) {
  const navigate = useNavigate();
  const params = useParams();
  const initialDetails = {
    title: "",
    content: "",
    acf: {
      assignment: [],
      course_attributes: {
        apply_now: "No",
        batch_status: "no",
        certificate: "No",
        content_type: "Show all",
        course_type: "Paid",
        url_source: "Primary",
      },
      watch_instructions: "",
      course_content: [],
    },
    payment: [],
    programs: [],
    course_category: [],
    groups: [],
    extra_pages_cat: [],
    status: "publish",
  };
  const [courseDetails, setCourseDetails] = useState(initialDetails);
  const [programs, setPrograms] = useState(null);
  const [categories, setCategories] = useState(null);
  const [websites, setWebsites] = useState(null);
  const [payments, setPayments] = useState(null);
  const [groups, setGroups] = useState(null);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("1");
  const [discriptionTab, setDiscriptionTab] = useState("discription");
  const [badges, setBadges] = useState([]);
  const [batchs, setBatchs] = useState([]);
  const [meditations, setMeditations] = useState([]);
  const [kriyas, setKriyas] = useState([]);
  const [hideCourse, setHideCourse] = useState([]);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const [isSqureHovered, setIsSqureHovered] = useState(false);
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [linkedProd, setLinkedProd] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [Images, setImages] = useState({
    bannerImg: "",
    squareImg: "",
    featuredImg: "",
  });
  const assignmentObj = {
    title: "",
    start_day: null,
    end_day: null,
  };
  const [courseData, setCourseData] = useState({});
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [alert, setAlert] = useState(false);
  const [courseAction, setAction] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [rowId, setRowId] = useState(null);
  const columns = [
    { field: "fetch_type", headerName: "Lesson Type", width: 200 },
    { field: "title", headerName: "Title", width: 250 },
    ...(courseDetails?.acf?.course_attributes?.content_type === "Drip by date"
      ? [
          {
            field: "start_date",
            headerName: "Start Date",
            width: 200,
            valueFormatter: (params) =>
              params.value && dayjs(params.value).format("DD/MM/YYYY"),
          },
          {
            field: "end_date",
            headerName: "End Date",
            width: 200,
            valueFormatter: (params) =>
              params.value && dayjs(params.value).format("DD/MM/YYYY"),
          },
        ]
      : []),
    ...(courseDetails?.acf?.course_attributes?.content_type === "Drip"
      ? [
          { field: "from_day", headerName: "From Day", width: 200 },
          { field: "to_day", headerName: "To Day", width: 200 },
        ]
      : []),
    {
      width: 180,
      renderCell: (params, i) =>
        hoveredRowId === params.id && (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => handleUpdateCoureData(params)}>
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                setAlert(true);
                setRowId(params.id);
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];

  const handleChange = (event, newValue) => {
    if (newValue === "1") {
      setDiscriptionTab("discription");
    }
    if (newValue === "3") {
      const fetchCourses = async () => {
        await getAllCourses(1, 100).then((res) => {
          setCoursesList(res.data);
        });
      };
      fetchCourses();
    }
    if (newValue === "6") {
      const fetchCourseTypedetails = async () => {
        await getBadges().then((res) => {
          setBadges(res.data);
        });

        await getMeditationList().then((res) => {
          setMeditations(res);
        });
        await getKriyaList().then((res) => {
          setKriyas(res);
        });
      };
      fetchCourseTypedetails();
    }
    setValue(newValue);
  };
  const handleRemoveassignment = (index) => {
    const updatedFields = {
      ...courseDetails,
      acf: {
        ...courseDetails.acf,
        assignment: courseDetails.acf?.assignment.filter((_, i) => i !== index),
      },
    };
    setCourseDetails(updatedFields);
  };
  const handleAssignmentRow = () => {
    const updatedFields = {
      ...courseDetails,
      acf: {
        ...courseDetails.acf,
        assignment: courseDetails.acf.assignment
          ? [...courseDetails.acf.assignment, assignmentObj]
          : [assignmentObj],
      },
    };

    setCourseDetails(updatedFields);
  };
  const handleRemoveTopic = (index) => {
    const updatedFields = {
      ...courseDetails,
      acf: {
        ...courseDetails.acf,
        course_content: courseDetails.acf?.course_content.filter(
          (_, i) => i !== index
        ),
      },
    };
    setCourseDetails(updatedFields);
  };
  const handleTopicRow = () => {
    const contentObj = {
      topic: "",
      badge: "",
      batch: "",
      course_data: [],
    };

    const updatedFields = {
      ...courseDetails,
      acf: {
        ...courseDetails.acf,
        course_content: courseDetails.acf.course_content
          ? [...courseDetails.acf.course_content, contentObj]
          : [contentObj],
      },
    };
    setHideCourse((prevHideCourse) => [...prevHideCourse, false]);
    setCourseDetails(updatedFields);
  };
  const hideTopicData = (index) => {
    setHideCourse((prevHideCourse) => {
      const updatedHideCourse = [...prevHideCourse];
      updatedHideCourse[index] = !prevHideCourse[index];
      return updatedHideCourse;
    });
  };
  const handleRemoveCourseData = () => {
    const indexes = rowId.split("_");
    const topicIndex = parseInt(indexes[0]);
    const courseIndex = parseInt(indexes[1]);
    setCourseDetails((prevDetails) => ({
      ...prevDetails,
      acf: {
        ...prevDetails.acf,
        course_content: prevDetails?.acf?.course_content?.map((item, i) =>
          i === topicIndex
            ? {
                ...item,
                course_data: item?.course_data?.filter(
                  (cd, j) => j !== courseIndex
                ),
              }
            : item
        ),
      },
    }));
    setAlert(false);
  };
  const handleUpdateCoureData = (data) => {
    setCourseData(data?.row);
    setAction("edit");
    setOpenDialog(true);
  };
  const handleCourseDataRow = (topicIndex) => {
    setAction("add");
    setOpenDialog(true);
    const index = `${topicIndex}_${
      courseDetails?.acf?.course_content[topicIndex]?.course_data.length || 0
    }`;
    setCourseData({ id: index });
    const updatedFields = {
      ...courseDetails,
      acf: {
        ...courseDetails.acf,
        course_content: courseDetails.acf?.course_content.map(
          (topic, index) => {
            if (index === topicIndex) {
              const course_data = Array.isArray(topic.course_data)
                ? [...topic.course_data]
                : [];
              return {
                ...topic,
                course_data: [
                  ...course_data,
                  {
                    course_data_type: "Lesson",
                    title: "",
                  },
                ],
              };
            }
            return topic;
          }
        ),
      },
    };
    setCourseDetails(updatedFields);
  };

  const handleImageChange = (file, acfKey, mediaKey, aspectRatio) => {
    const reader = new FileReader();
    let isAborted = false;

    reader.onabort = () => {
      isAborted = true;
    };

    reader.onloadend = () => {
      if (!isAborted) {
        const img = new Image();
        img.src = reader.result;

        img.onload = async () => {
          const imageAspectRatio = img.width / img.height;
          // eslint-disable-next-line no-eval
          if (imageAspectRatio?.toFixed(1) === eval(aspectRatio)?.toFixed(1)) {
            const res = await createMedia(file, params?.id);
            setImages((pre) => ({
              ...pre,
              [mediaKey]: reader.result,
            }));

            if (acfKey === "featured_media") {
              setCourseDetails((prevDetails) => ({
                ...prevDetails,
                [acfKey]: res.id,
              }));
            } else {
              setCourseDetails((prevDetails) => ({
                ...prevDetails,
                acf: {
                  ...prevDetails.acf,
                  [acfKey]: res.id,
                },
              }));
            }
          } else {
            window.alert(
              `Please upload an image with a ${aspectRatio} aspect ratio.`
            );
          }
        };
      }
    };

    // Read the file as data URL
    reader.readAsDataURL(file);

    // Return a function to abort the FileReader if needed
    return () => {
      reader.abort();
    };
  };

  const handleImageDrop = async (e, acfKey, mediaKey, aspectRatio) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    await handleImageChange(file, acfKey, mediaKey, aspectRatio);
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "banner", "bannerImg", "16 / 9"); // 4:2 aspect ratio for banner
  };

  const handleBannerDrop = (e) => {
    handleImageDrop(e, "banner", "bannerImg", "16 / 9"); // 4:2 aspect ratio for banner
  };

  const handleSquareChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "square_image", "squareImg", " 1 / 1"); // 1:1 aspect ratio for square image
  };

  const handleSquareDrop = (e) => {
    handleImageDrop(e, "square_image", "squareImg", " 1 / 1"); // 1:1 aspect ratio for square image
  };

  const handleFeatureChange = (event) => {
    const file = event.target.files[0];
    handleImageChange(file, "featured_media", "featuredImg", " 3 / 2"); // 3:9 aspect ratio for featured image
  };

  const handleFeatureDrop = (e) => {
    handleImageDrop(e, "featured_media", "featuredImg", " 3 / 2"); // 3:9 aspect ratio for featured image
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      const response = await getPrograms();
      setPrograms(response);
    };
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    const fetchCategories = async () => {
      const response = await getCourseCategories();
      setCategories(response);
    };
    const fetchGroups = async () => {
      const response = await getCourseGroup();
      setGroups(response);
    };
    const fetchPayments = async () => {
      const response = await getPaymentGateways();
      setPayments(response.data);
    };
    const fetchBatchs = async () => {
      await getBatches().then((res) => {
        setBatchs(res);
      });
    };
    const fetchLinkedProd = async () => {
      await getCoursesLinkedProducts().then((res) => {
        setLinkedProd(res);
      });
    };
    const fetchAssignmentTemplates = async () => {
      await getAssignmentTemplates().then((res) => {
        setAssignments(res);
      });
    };
    fetchBatchs();
    fetchPrograms();
    fetchWebsites();
    fetchCategories();
    fetchPayments();
    fetchGroups();
    fetchLinkedProd();
    fetchAssignmentTemplates();
  }, []);
  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateCourse(params?.id, courseDetails);
    } else {
      res = await createCourse(courseDetails);
    }
    if (res) {
      setValue("1");
      navigate(`/courses/${res?.id}`);
      setPublishSuccess(true);
    }
  };
  useEffect(() => {
    const fetchCourseData = async () => {
      const response = await getCourseData(params?.id);
      let featuredimgData = "";
      let bannerimgData = "";
      let squareimgData = "";
      if (response.featured_media)
        featuredimgData = await fetchMedia(response.featured_media);
      if (response?.acf?.banner)
        bannerimgData = await fetchMedia(response?.acf?.banner);
      if (response?.acf?.square_image)
        squareimgData = await fetchMedia(response?.acf?.square_image);

      setImages({
        bannerImg: bannerimgData?.source_url,
        squareImg: squareimgData?.source_url,
        featuredImg: featuredimgData?.source_url,
      });
      const filterEmptyValues = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(
            ([_, value]) => value !== null && value !== false && value !== ""
          )
        );
      };

      const course_content_Data = (response?.acf?.course_content || [])
        .map((topic) => ({
          topic: topic.topic,
          badge: topic?.badge,
          batch: topic?.batch,
          course_data: (topic.course_data || []).map((data) =>
            filterEmptyValues({
              course_data_type: data.course_data_type,
              title: data.title,
              fetch_type: data.fetch_type,
              api_fetch: data.api_fetch,
              from_day: data.from_day,
              to_day: data.to_day,
              start_date: data.start_date,
              end_date: data.end_date,
              api_fetch_kriya: data.api_fetch_kriya,
              data_source: data.data_source,
              primary_link: data.primary_link,
              secondary_link: data.secondary_link,
              webinar_link: data.webinar_link,
              webinar_password: data.webinar_password,
              webinar_date_and_time: data.webinar_date_and_time,
            })
          ),
        }))
        .filter((topic) => topic.course_data.length > 0);
      const assignment_Data = (response?.acf?.assignment || []).map((a) =>
        filterEmptyValues({
          title: a?.title,
          assignment_details: a?.assignment_details,
          start_day: a?.start_day,
          end_day: a?.end_day,
        })
      );
      setCourseDetails({
        ...courseDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        extra_pages_cat: response?.extra_pages_cat,
        payment: response?.payment,
        groups: response?.groups,
        programs: response?.programs,
        course_category: response?.course_category,
        featured_media: featuredimgData?.id || null,
        acf: {
          banner:bannerimgData?.id || null,
          square_image:squareimgData?.id || null,
          assignment: assignment_Data || [],
          course_attributes:
            filterEmptyValues(response?.acf?.course_attributes) || {},
          watch_instructions: response?.acf?.watch_instructions || "",
          course_content: course_content_Data || [],
          ...(response?.acf?.free_cources && {
            free_cources: response?.acf?.free_cources,
          }),
        },
      });
      setLoading(false);
    };

    if (params?.id) {
      fetchCourseData();
    } else {
      setCourseDetails(initialDetails);
      setLoading(false);
    }
  }, [params, params?.id]);

  const renderTabs = () => {
    const handleProgramChange = (id) => {
      setCourseDetails((prevDetails) => {
        const updatedPrograms = prevDetails.programs.includes(id)
          ? prevDetails.programs.filter((i) => i !== id)
          : [...prevDetails.programs, id];

        return {
          ...prevDetails,
          programs: updatedPrograms,
        };
      });
    };
    const handleWebsiteChange = (id) => {
      setCourseDetails((prevDetails) => {
        const updatedWebsites = prevDetails.extra_pages_cat.includes(id)
          ? prevDetails.extra_pages_cat.filter((i) => i !== id)
          : [...prevDetails.extra_pages_cat, id];

        return {
          ...prevDetails,
          extra_pages_cat: updatedWebsites,
        };
      });
    };
    const handleCategoryChange = (id) => {
      setCourseDetails((prevDetails) => {
        const updatedCat = prevDetails.course_category.includes(id)
          ? prevDetails.course_category.filter((i) => i !== id)
          : [...prevDetails.course_category, id];

        return {
          ...prevDetails,
          course_category: updatedCat,
        };
      });
    };
    const handlePaymentChange = (id) => {
      setCourseDetails((prevDetails) => {
        const updatedPayment = prevDetails.payment.includes(id)
          ? prevDetails.payment.filter((i) => i !== id)
          : [...prevDetails.payment, id];

        return {
          ...prevDetails,
          payment: updatedPayment,
        };
      });
    };
    const handleGroupChange = (id) => {
      setCourseDetails((prevDetails) => {
        const updatedGroups = prevDetails.groups.includes(id)
          ? prevDetails.groups.filter((i) => i !== id)
          : [...prevDetails.groups, id];

        return {
          ...prevDetails,
          groups: updatedGroups,
        };
      });
    };
    const handleRowHover = (event) => {
      const rowId = event.currentTarget?.dataset?.id;
      setHoveredRowId(rowId);
      document.dispatchEvent(
        new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
      );
    };
    const handleRowLeaved = (event) => {
      const rowId = event.currentTarget?.dataset?.id;
      setHoveredRowId(null);
      document.dispatchEvent(
        new CustomEvent(`row${rowId}HoverChange`, {
          detail: { hovered: false },
        })
      );
    };
    const handleCloseCourseDataDailog = () => {
      setOpenDialog(false);
      if (courseAction === "add") {
        const { id } = courseData;
        const indexes = id.split("_");
        const topicIndex = parseInt(indexes[0]);
        courseDetails.acf.course_content[topicIndex].course_data.pop();
      }
    };
    const updateCourseDetails = () => {
      const { id, ...data } = courseData;
      const indexes = id.split("_");
      const topicIndex = parseInt(indexes[0]);
      const courseIndex = parseInt(indexes[1]);
      setCourseDetails((prevDetails) => ({
        ...prevDetails,
        acf: {
          ...prevDetails.acf,
          course_content: prevDetails?.acf?.course_content?.map((item, j) =>
            j === topicIndex
              ? {
                  ...item,
                  course_data: item?.course_data?.map((cd, k) =>
                    k === courseIndex
                      ? {
                          ...cd,
                          ...Object.assign(cd, data),
                        }
                      : cd
                  ),
                }
              : item
          ),
        },
      }));
      setOpenDialog(false);
    };

    const renderDiscriptionTabs = () => {
      switch (discriptionTab) {
        case "discription":
          return (
            <>
              <InputLabel sx={{ mb: "5px" }}>Description</InputLabel>
              <ReactQuill
                modules={modules}
                defaultValue={""}
                theme="snow"
                className="pb-4"
                value={courseDetails.content}
                onChange={(value) =>
                  setCourseDetails((prevDetails) => ({
                    ...prevDetails,
                    content: value,
                  }))
                }
              />
              <InputLabel sx={{ mt: "10px" }}>Watch Instructions</InputLabel>
              <TextField
                placeholder="Watch Instructions"
                variant="outlined"
                fullWidth
                margin="normal"
                name="title"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
                value={courseDetails?.acf?.watch_instructions}
                onChange={(e) =>
                  setCourseDetails((prevDetails) => ({
                    ...prevDetails,
                    acf: {
                      ...prevDetails.acf,
                      watch_instructions: e.target.value,
                    },
                  }))
                }
              />
            </>
          );
        case "attributes":
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputLabel>Intro Video</InputLabel>
                  <TextField
                    placeholder="Intro Video"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="introVideo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Public />
                        </InputAdornment>
                      ),
                    }}
                    value={
                      courseDetails?.acf?.course_attributes?.intro_video || ""
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            intro_video: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Content type</InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "5px" }}
                    variant="outlined"
                    margin="normal"
                    name="videoType"
                    value={
                      courseDetails?.acf?.course_attributes?.content_type ||
                      "Show all"
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            content_type: e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    <MenuItem value="Drip">Drip by days</MenuItem>
                    <MenuItem value="Drip by date">Drip by date</MenuItem>
                    <MenuItem value="Drip One after other">
                      Drip one after other
                    </MenuItem>
                    <MenuItem value="Show all">Show all</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Course Type</InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "5px" }}
                    variant="outlined"
                    margin="normal"
                    name="Course Type"
                    value={
                      courseDetails?.acf?.course_attributes?.course_type ||
                      "Paid"
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            course_type: e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    <MenuItem value="Free">Free</MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Apply now Button</InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "5px" }}
                    variant="outlined"
                    margin="normal"
                    name="Course Type"
                    value={
                      courseDetails?.acf?.course_attributes?.apply_now || "No"
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            apply_now: e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Linked Product</InputLabel>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={linkedProd || []}
                    getOptionLabel={(option) => option?.name || "Select"}
                    fullWidth
                    value={
                      linkedProd.find(
                        (option) =>
                          option.id ===
                          courseDetails?.acf?.course_attributes?.linked_product
                      ) || null
                    }
                    onChange={(e, value) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            linked_product: value?.id,
                          },
                        },
                      }))
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select.." />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Price</InputLabel>
                  <TextField
                    placeholder="Price"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="price"
                    value={courseDetails?.acf?.course_attributes?.price || ""}
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            price: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Lessons</InputLabel>
                  <TextField
                    placeholder="Lessons"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="lessons"
                    value={courseDetails?.acf?.course_attributes?.lessons || ""}
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            lessons: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Duration</InputLabel>
                  <TextField
                    placeholder="Duration"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="suration"
                    value={
                      courseDetails?.acf?.course_attributes?.duration || ""
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            duration: e.target.value,
                          },
                        },
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Certificate</InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "5px" }}
                    variant="outlined"
                    margin="normal"
                    name="certificate"
                    value={
                      courseDetails?.acf?.course_attributes?.certificate || "No"
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            certificate: e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>URL Source</InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "5px" }}
                    variant="outlined"
                    margin="normal"
                    name="sourceUrl"
                    value={
                      courseDetails?.acf?.course_attributes?.url_source ||
                      "Primary"
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            url_source: e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    <MenuItem value="Primary">Primary</MenuItem>
                    <MenuItem value="Secondary">Secondary</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Batch Status</InputLabel>
                  <Select
                    fullWidth
                    sx={{ m: "5px" }}
                    variant="outlined"
                    margin="normal"
                    name="batchStatus"
                    value={
                      courseDetails?.acf?.course_attributes?.batch_status ||
                      "no"
                    }
                    onChange={(e) =>
                      setCourseDetails((prevDetails) => ({
                        ...prevDetails,
                        acf: {
                          ...prevDetails?.acf,
                          course_attributes: {
                            ...prevDetails?.acf?.course_attributes,
                            batch_status: e.target.value,
                          },
                        },
                      }))
                    }
                  >
                    <MenuItem value="no">No</MenuItem>
                    <MenuItem value="yes">Yes</MenuItem>
                  </Select>
                </Grid>
                {courseDetails?.acf?.course_attributes?.batch_status ===
                  "yes" && (
                  <Grid item xs={3}>
                    <InputLabel>Batchs</InputLabel>
                    <Select
                      fullWidth
                      sx={{ m: "5px" }}
                      variant="outlined"
                      margin="normal"
                      name="batchs"
                      value={courseDetails?.acf?.course_attributes?.batches}
                      onChange={(e) =>
                        setCourseDetails((prevDetails) => ({
                          ...prevDetails,
                          acf: {
                            ...prevDetails?.acf,
                            course_attributes: {
                              ...prevDetails?.acf?.course_attributes,
                              batches: e.target.value,
                            },
                          },
                        }))
                      }
                    >
                      {batchs.length > 0 &&
                        batchs?.map((batch) => (
                          <MenuItem key={batch?.id} value={batch?.id}>
                            {batch?.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                )}
              </Grid>
            </>
          );
        case "images":
          return (
            <>
              <Grid container spacing={2} sx={{ mt: "20px" }}>
                <Grid item xs={4}>
                  <Card>
                    <CardHeader title="Banner Image" />
                    <CardContent>
                      {!Images.bannerImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleBannerDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("Banner-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsBannerHovered(true)}
                          onMouseLeave={() => setIsBannerHovered(false)}
                        >
                          <img
                            src={Images.bannerImg}
                            alt="banner Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isBannerHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  bannerImg: "",
                                }));
                                setCourseDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf:{
                                    ...prevDetails.acf,
                                    banner: null,
                                  }
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="Banner-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleBannerChange}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardHeader title="Square Image" />
                    <CardContent>
                      {!Images.squareImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleSquareDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("Square-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsSqureHovered(true)}
                          onMouseLeave={() => setIsSqureHovered(false)}
                        >
                          <img
                            src={Images.squareImg}
                            alt="square Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isSqureHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  squareImg: "",
                                }));
                                setCourseDetails((prevDetails) => ({
                                  ...prevDetails,
                                  acf:{
                                    ...prevDetails.acf,
                                    square_image: null,
                                  }
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="Square-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleSquareChange}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardHeader title="Featured Image" />
                    <CardContent>
                      {!Images.featuredImg ? (
                        <Paper
                          sx={{
                            height: "100px",
                            margin: "30px",
                            border: "1px dashed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onDrop={handleFeatureDrop}
                          onDragOver={(e) => e.preventDefault()}
                        >
                          <IconButton
                            onClick={() =>
                              document
                                .getElementById("Feature-file-upload")
                                .click()
                            }
                            sx={{ flexDirection: "column" }}
                          >
                            <CloudUpload sx={{ fontSize: "50px" }} />
                            <Typography>
                              Drop files here or click to upload.
                            </Typography>
                          </IconButton>
                        </Paper>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={() => setIsFeatureHovered(true)}
                          onMouseLeave={() => setIsFeatureHovered(false)}
                        >
                          <img
                            src={Images.featuredImg}
                            alt="feature Preview"
                            style={{ maxWidth: "100%", marginTop: "10px" }}
                          />
                          {isFeatureHovered && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "3%",
                                left: "86%",
                                background: "#000",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImages((pre) => ({
                                  ...pre,
                                  featuredImg: "",
                                }));
                                setCourseDetails((prevDetails) => ({
                                  ...prevDetails,
                                  featured_media: null,
                                }));
                              }}
                            >
                              <Delete sx={{ color: "#fff" }} />
                            </IconButton>
                          )}
                        </div>
                      )}
                      <input
                        id="Feature-file-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFeatureChange}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          );
        default:
          return null;
      }
    };
    switch (value) {
      case "1":
        return (
          <Card sx={{ marginTop: "20px" }}>
            <CardContent>
              <Box sx={{ width: "100%", mb: "20px" }}>
                <Tabs
                  value={discriptionTab}
                  onChange={(e, v) => setDiscriptionTab(v)}
                  aria-label="course_tabs"
                >
                  <Tab value="discription" label="Description" />
                  <Tab value="attributes" label="Course Attributes" />
                  <Tab value="images" label="Images" />
                </Tabs>
              </Box>
              {renderDiscriptionTabs()}
            </CardContent>
          </Card>
        );
      case "2":
        return (
          <Card sx={{ marginTop: "20px" }}>
            <CardContent>
              <Box
                sx={{
                  padding: "10px",
                  background: "#ebebeb",
                  margin: "10px 0",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {courseDetails?.acf?.assignment &&
                courseDetails?.acf?.assignment?.length !== 0 ? (
                  courseDetails?.acf?.assignment?.map((row, index) => (
                    <Paper
                      key={index}
                      sx={{
                        padding: "20px",
                        position: "relative",
                        margin: "5px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <InputLabel>Title</InputLabel>
                          <TextField
                            placeholder="Title"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="assignmentTitle"
                            value={row?.title || ""}
                            onChange={(e) =>
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  assignment: prevDetails?.acf?.assignment?.map(
                                    (item, i) =>
                                      i === index
                                        ? {
                                            ...item,
                                            title: e.target.value,
                                          }
                                        : item
                                  ),
                                },
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>Assignment Details</InputLabel>
                          <Select
                            fullWidth
                            sx={{ m: "5px" }}
                            variant="outlined"
                            margin="normal"
                            name="details"
                            value={row?.assignment_details || ""}
                            onChange={(e) =>
                              e.target.value &&
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  assignment: prevDetails?.acf?.assignment?.map(
                                    (item, i) =>
                                      i === index
                                        ? {
                                            ...item,
                                            assignment_details: e.target.value,
                                          }
                                        : item
                                  ),
                                },
                              }))
                            }
                          >
                            {" "}
                            {assignments?.map((a) => (
                              <MenuItem key={a?.id} value={a?.id}>
                                {a?.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>Start Day</InputLabel>
                          <TextField
                            placeholder="Start Day"
                            variant="outlined"
                            fullWidth
                            type="number"
                            margin="normal"
                            name="startDay"
                            value={row?.start_day || null}
                            onChange={(e) =>
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  assignment: prevDetails?.acf?.assignment?.map(
                                    (item, i) =>
                                      i === index
                                        ? {
                                            ...item,
                                            start_day: Number(e.target.value),
                                          }
                                        : item
                                  ),
                                },
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>End Day</InputLabel>
                          <TextField
                            placeholder="End Day"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="endDay"
                            value={row?.end_day || null}
                            onChange={(e) =>
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  assignment: prevDetails?.acf?.assignment?.map(
                                    (item, i) =>
                                      i === index
                                        ? {
                                            ...item,
                                            end_day: Number(e.target.value),
                                          }
                                        : item
                                  ),
                                },
                              }))
                            }
                          />
                        </Grid>
                      </Grid>
                      <IconButton
                        sx={{ position: "absolute", top: 0, left: "95%" }}
                        onClick={() => handleRemoveassignment(index)}
                      >
                        <Cancel />
                      </IconButton>
                    </Paper>
                  ))
                ) : (
                  <Typography>No Rows</Typography>
                )}
              </Box>
              <Button variant="contained" onClick={handleAssignmentRow}>
                Add Row
              </Button>
            </CardContent>
          </Card>
        );
      case "3":
        return (
          <Card sx={{ marginTop: "20px" }}>
            <CardContent>
              <InputLabel>Free Courses</InputLabel>
              <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={coursesList}
                getOptionLabel={(option) => option?.title}
                value={
                  courseDetails.acf.free_cources
                    ? coursesList.filter((option) =>
                        courseDetails.acf.free_cources.includes(option.id)
                      ) || []
                    : []
                }
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select.." />
                )}
                sx={{ width: "50%" }}
                onChange={(e, values) =>
                  setCourseDetails((prevDetails) => ({
                    ...prevDetails,
                    acf: {
                      ...prevDetails.acf,
                      free_cources: values.map((value) => value.id),
                    },
                  }))
                }
              />
            </CardContent>
          </Card>
        );
      case "4":
        return (
          <Grid container spacing={2} sx={{ mt: "20px" }}>
            <Grid item xs={3}>
              <Card>
                <CardHeader title={"Programs"} />
                <CardContent>
                  <FormGroup>
                    {programs &&
                      programs?.map((program) => (
                        <FormControlLabel
                          key={program?.id}
                          control={
                            <Checkbox
                              checked={courseDetails?.programs?.includes(
                                program.id
                              )}
                              onChange={() => handleProgramChange(program.id)}
                            />
                          }
                          label={program?.name}
                        />
                      ))}
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardHeader title={"Course Category"} />
                <CardContent>
                  <FormGroup>
                    {categories &&
                      categories?.map((cat) => (
                        <FormControlLabel
                          key={cat?.id}
                          control={
                            <Checkbox
                              checked={courseDetails?.course_category?.includes(
                                cat.id
                              )}
                              onChange={() => handleCategoryChange(cat.id)}
                            />
                          }
                          label={cat?.name}
                        />
                      ))}
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardHeader title={"Websites"} />
                <CardContent>
                  <FormGroup>
                    {websites &&
                      websites?.map((site) => (
                        <FormControlLabel
                          key={site?.id}
                          control={
                            <Checkbox
                              checked={courseDetails?.extra_pages_cat?.includes(
                                site.id
                              )}
                              onChange={() => handleWebsiteChange(site.id)}
                            />
                          }
                          label={site?.name}
                        />
                      ))}
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardHeader title={"Groups"} />
                <CardContent>
                  <FormGroup>
                    {groups &&
                      groups?.map((g) => (
                        <FormControlLabel
                          key={g?.id}
                          control={
                            <Checkbox
                              checked={courseDetails?.groups?.includes(g.id)}
                              onChange={() => handleGroupChange(g.id)}
                            />
                          }
                          label={g?.name}
                        />
                      ))}
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      case "5":
        return (
          <Grid container spacing={2} sx={{ mt: "20px" }}>
            <Grid item xs={3}>
              <Card>
                <CardHeader title={"Payment Gateway"} />
                <CardContent>
                  {" "}
                  <FormGroup>
                    {payments &&
                      payments?.map((payment) => (
                        <FormControlLabel
                          key={payment?.id}
                          control={
                            <Checkbox
                              checked={courseDetails?.payment?.includes(
                                payment.id
                              )}
                              onChange={() => handlePaymentChange(payment.id)}
                            />
                          }
                          label={payment?.name}
                        />
                      ))}
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      case "6":
        return (
          <Card sx={{ marginTop: "20px" }}>
            <CardContent>
              <Box
                sx={{
                  padding: "10px",
                  background: "#ebebeb",
                  margin: "10px 0",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {courseDetails?.acf?.course_content &&
                courseDetails?.acf?.course_content?.length !== 0 ? (
                  courseDetails?.acf?.course_content?.map((row, index) => (
                    <Paper
                      key={index}
                      sx={{
                        padding: "20px",
                        position: "relative",
                        margin: "5px",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <InputLabel>Topic</InputLabel>
                          <TextField
                            placeholder="Topic"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="topic"
                            value={row?.topic || ""}
                            onChange={(e) =>
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  course_content:
                                    prevDetails?.acf?.course_content?.map(
                                      (item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              topic: e.target.value,
                                            }
                                          : item
                                    ),
                                },
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>Badge</InputLabel>
                          <Autocomplete
                            fullWidth
                            options={badges}
                            getOptionLabel={(badge) => badge?.title || ""}
                            value={
                              badges.find((badge) => badge.id === row?.badge) ||
                              null
                            }
                            onChange={(event, newValue) =>
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  course_content:
                                    prevDetails?.acf?.course_content?.map(
                                      (item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              badge: newValue?.id || "",
                                            }
                                          : item
                                    ),
                                },
                              }))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Badge"
                                variant="outlined"
                                margin="normal"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>Batch</InputLabel>
                          <Autocomplete
                            fullWidth
                            value={
                              batchs.find((batch) => batch.id == row?.batch) ||
                              null
                            }
                            onChange={(e, newValue) =>
                              setCourseDetails((prevDetails) => ({
                                ...prevDetails,
                                acf: {
                                  ...prevDetails.acf,
                                  course_content:
                                    prevDetails?.acf?.course_content?.map(
                                      (item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              batch: newValue
                                                ? newValue.id
                                                : null,
                                            }
                                          : item
                                    ),
                                },
                              }))
                            }
                            options={batchs}
                            getOptionLabel={(option) => option?.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Batch"
                                variant="outlined"
                                margin="normal"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      {!hideCourse[index] && (
                        <Box>
                          <InputLabel sx={{ m: "5px 0" }}>
                            Course Data
                          </InputLabel>
                          {row?.course_data &&
                            row?.course_data?.length !== 0 && (
                              <DataGrid
                                sx={{
                                  "& .MuiDataGrid-virtualScroller":{
                                    minHeight:"auto !important"
                                  },
                                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus":
                                    {
                                      outline: "none",
                                    },
                                }}
                                rows={row?.course_data.map((dataRow, i) => ({
                                  ...dataRow,
                                  id: `${index}_${i}`,
                                }))}
                                columns={columns}
                                disableRowSelectionOnClick
                                hideFooter
                                getRowId={(row) => row.id}
                                slotProps={{
                                  row: {
                                    onMouseEnter: handleRowHover,
                                    onMouseLeave: handleRowLeaved,
                                  },
                                }}
                              />
                            )}
                          <Button
                            variant="contained"
                            sx={{ mt: "10px" }}
                            onClick={() => handleCourseDataRow(index)}
                          >
                            Add Course Data
                          </Button>
                        </Box>
                      )}
                      <IconButton
                        sx={{ position: "absolute", top: "50px", left: "96%" }}
                        onClick={() => hideTopicData(index)}
                      >
                        {!hideCourse[index] ? <Remove /> : <Add />}
                      </IconButton>
                      <IconButton
                        sx={{ position: "absolute", top: 0, left: "96%" }}
                        onClick={() => handleRemoveTopic(index)}
                      >
                        <Cancel />
                      </IconButton>
                    </Paper>
                  ))
                ) : (
                  <Typography>No Rows</Typography>
                )}
              </Box>
              <Button variant="contained" onClick={handleTopicRow}>
                Add Topic
              </Button>
            </CardContent>

            <Dialog open={openDialog} maxWidth="md" fullWidth>
              <DialogTitle>Edit Course Data</DialogTitle>
              <DialogContent sx={{ mt: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <InputLabel>Title</InputLabel>
                    <TextField
                      placeholder="Title"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="courseType"
                      value={courseData?.title || ""}
                      onChange={(e) =>
                        setCourseData((prevDetails) => ({
                          ...prevDetails,
                          title: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      <>
                        {courseDetails?.acf?.course_attributes?.content_type ===
                          "Drip" && (
                          <>
                            <Grid item xs={4}>
                              <InputLabel>From Day</InputLabel>
                              <TextField
                                placeholder="From Day"
                                variant="outlined"
                                fullWidth
                                type="number"
                                margin="normal"
                                name="fromDay"
                                value={courseData?.from_day || ""}
                                onChange={(e) =>
                                  setCourseData((prevDetails) => ({
                                    ...prevDetails,
                                    from_day: e.target.value,
                                  }))
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <InputLabel>To Day</InputLabel>
                              <TextField
                                placeholder="To Day"
                                variant="outlined"
                                fullWidth
                                type="number"
                                margin="normal"
                                name="toDay"
                                value={courseData?.to_day || ""}
                                onChange={(e) =>
                                  setCourseData((prevDetails) => ({
                                    ...prevDetails,
                                    to_day: e.target.value,
                                  }))
                                }
                              />
                            </Grid>
                          </>
                        )}
                        {courseDetails?.acf?.course_attributes?.content_type ===
                          "Drip by date" && (
                          <>
                            <Grid item xs={4}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputLabel>Start Date</InputLabel>
                                <DatePicker
                                  value={
                                    courseData?.start_date
                                      ? dayjs(courseData?.start_date)
                                      : null
                                  }
                                  onChange={(date) => {
                                    if (dayjs(date).isValid()) {
                                      const formattedTime =
                                        dayjs(date).format("YYYY-MM-DD");
                                      setCourseData((prevDetails) => ({
                                        ...prevDetails,
                                        start_date: formattedTime,
                                      }));
                                    }
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputLabel>End Date</InputLabel>
                                <DatePicker
                                  value={
                                    courseData?.end_date
                                      ? dayjs(courseData?.end_date)
                                      : null
                                  }
                                  onChange={(date) => {
                                    if (dayjs(date).isValid()) {
                                      const formattedTime =
                                        dayjs(date).format("YYYY-MM-DD");
                                      setCourseData((prevDetails) => ({
                                        ...prevDetails,
                                        end_date: formattedTime,
                                      }));
                                    }
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={4}>
                          <InputLabel>Fetch Type</InputLabel>
                          <Select
                            fullWidth
                            sx={{ m: "5px" }}
                            variant="outlined"
                            margin="normal"
                            name="fetchType"
                            defaultValue={"Meditation"}
                            value={courseData?.fetch_type || ""}
                            onChange={(e) =>
                              setCourseData((prevDetails) => ({
                                ...prevDetails,
                                fetch_type: e.target.value,
                              }))
                            }
                          >
                            <MenuItem value="Meditation">Meditation</MenuItem>
                            <MenuItem value="Kriya">Kriya</MenuItem>
                            <MenuItem value="Offline">Custom link</MenuItem>
                          </Select>
                        </Grid>
                      </>
                    </Grid>
                  </Grid>
                  <>
                    {courseData?.fetch_type !== "Offline" ? (
                      <>
                        <Grid item xs={4}>
                          <InputLabel>
                            Api Fetch {courseData?.fetch_type}
                          </InputLabel>
                          <Select
                            fullWidth
                            sx={{
                              m: "5px",
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                },
                              },
                            }}
                            variant="outlined"
                            margin="normal"
                            name="apifetch"
                            defaultValue={""}
                            value={
                              (courseData?.fetch_type === "Meditation" &&
                                courseData?.api_fetch) ||
                              (courseData?.fetch_type === "Kriya" &&
                                courseData?.api_fetch_kriya) ||
                              null
                            }
                            onChange={(e) =>
                              setCourseData((prevDetails) => ({
                                ...prevDetails,
                                ...(courseData?.fetch_type === "Meditation"
                                  ? {
                                      api_fetch: String(e.target.value) || "",
                                    }
                                  : {}),
                                ...(courseData?.fetch_type === "Kriya"
                                  ? {
                                      api_fetch_kriya:
                                        String(e.target.value) || "",
                                    }
                                  : {}),
                              }))
                            }
                          >
                            <MenuItem value="">None</MenuItem>
                            {(
                              (courseData?.fetch_type === "Meditation" &&
                                meditations) ||
                              (courseData?.fetch_type === "Kriya" && kriyas) ||
                              []
                            ).map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                {item?.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={4}>
                          <InputLabel>Data Source</InputLabel>
                          <Select
                            fullWidth
                            sx={{ m: "5px" }}
                            variant="outlined"
                            margin="normal"
                            name="fetchType"
                            defaultValue={"MP3"}
                            value={courseData?.data_source || ""}
                            onChange={(e) =>
                              setCourseData((prevDetails) => ({
                                ...prevDetails,
                                data_source: e.target.value,
                              }))
                            }
                          >
                            <MenuItem value="MP3">MP3</MenuItem>
                            <MenuItem value="MP4">MP4</MenuItem>
                            <MenuItem value="Youtube">Youtube</MenuItem>
                            <MenuItem value="Vimeo">Vimeo</MenuItem>
                            <MenuItem value="Embedded">Embedded</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel>Primary Link</InputLabel>
                          <TextField
                            placeholder="Primary Link"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="primarylink"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Public />
                                </InputAdornment>
                              ),
                            }}
                            value={courseData?.primary_link || ""}
                            onChange={(e) =>
                              setCourseData((prevDetails) => ({
                                ...prevDetails,
                                primary_link: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel>Secondary Link</InputLabel>
                          <TextField
                            placeholder="Secondary Link"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            name="secondarylink"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Public />
                                </InputAdornment>
                              ),
                            }}
                            value={courseData?.secondary_link || ""}
                            onChange={(e) =>
                              setCourseData((prevDetails) => ({
                                ...prevDetails,
                                secondary_link: e.target.value,
                              }))
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseCourseDataDailog()}>
                  Cancel
                </Button>
                <Button onClick={() => updateCourseDetails()}>
                  {courseAction === "add" ? "Add Course Data" : "Update"}
                </Button>
              </DialogActions>
            </Dialog>
            <AlertDialog
              open={alert}
              setOpen={(isOpen) => setAlert(isOpen)}
              handleConfirm={handleRemoveCourseData}
              dialogText="Are you sure you want to delete course data?"
            />
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Course" : "Add New Course"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={courseDetails?.status}
              onChange={(e) =>
                setCourseDetails({
                  ...courseDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{mt:"5px"}}>
          <Card>
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel>Title</InputLabel>
                    <TextField
                      placeholder="Title"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="title"
                      value={courseDetails.title}
                      onChange={(e) =>
                        setCourseDetails({
                          ...courseDetails,
                          title: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          <Card sx={{ marginTop: "20px" }}>
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="course_tabs"
                >
                  <Tab value="1" label="Description" />
                  <Tab value="2" label="Assignment" />
                  <Tab value="3" label="Bogo" />
                  <Tab value="4" label="Configuration" />
                  <Tab value="5" label="Payment Gateway" />
                  <Tab value="6" label="Course Content" />
                </Tabs>
              </Box>
            </CardContent>
          </Card>
          {renderTabs()}
        </Box>
      </Container>
    </>
  );
}

export default AddCourse;
