import { Delete, Done, Edit, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getAllUsers } from "../../services/users.service";
import { useNavigate } from "react-router-dom";
function User(props) {
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [search,setSearch] = useState("");
  const navigate = useNavigate();
  const columns = [
    { field: "username", headerName: "Username", width: 250 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "role", headerName: "Role", width: 200 },
    {
      field: "active_subscriber",
      headerName: "Active subscriber?",
      width: 200,
      renderCell: (params) => {
        return params.value && <Done sx={{color:"green"}}/>;
      },
    },

    {
      width: 100,
      renderCell: (params) =>
        hoveredRowId === params.id && (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={()=>navigate(`/users/${params.id}`)}>
              <Edit />
            </IconButton>
          </Box>
        ),
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
    },
  ];

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
    setItemsPerPage(data.pageSize);
  };
  const handleRowHover = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(Number(rowId));
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );
  };
  const handleRowLeaved = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    setHoveredRowId(null);
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: false } })
    );
  };
  const fetchData = async () => {
    try {
      const users = await getAllUsers(currentPage, itemsPerPage , search);
      const userRows = [];
      users?.data?.map((user) => {
        userRows.push({
          id: user?.ID,
          username: user?.user_name,
          name: user?.display_name,
          email: user?.user_email,
          role: user?.roles?.map((role) => role)?.join(", "),
          active_subscriber: user?.is_active_subscriber,
        });
      });
      setTotalCount(users.totalCount);
      setRows(userRows);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage ,search]);
  return (
    <Container>
       <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ padding: "15px 0" }}>
        <Typography variant="h4"> All Users</Typography>
      </Box>
      <Card>
        <CardContent>
          <Box
            sx={{
              padding: "15px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={search}
                onChange={(e)=>setSearch(e.target.value)}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>
            </Paper>
            <Button variant="contained">Add New</Button>
          </Box>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: itemsPerPage,
                },
              },
            }}
            pagination
            paginationMode="server"
            pageSize={itemsPerPage}
            rowCount={totalCount}
            pageSizeOptions={[10, 20, 50]}
            onPaginationModelChange={(params) => handlePageChange(params)}
            disableRowSelectionOnClick
            slotProps={{
              row: {
                onMouseEnter: handleRowHover,
                onMouseLeave: handleRowLeaved,
              },
            }}
          />
        </CardContent>
      </Card>
    </Container>
  );
}

export default User;
