import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// styles
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { CloudUpload, Delete } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { createMedia, fetchMedia } from "../../../services/media.service";
import { getWebsites } from "../../../services/extraSites.service";
import AutoSnackbar from "../../../common/AutoSnackbar";

import {
  createGroup,
  getGroupData,
  updateGroup,
} from "../../../services/groups.service";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["direction", { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

function AddGroup(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [isFeatureHovered, setIsFeatureHovered] = useState(false);
  const [websites, setWebsites] = useState(null);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [loading, seLoading] = useState(true);
  const initialDetails = {
    title: "",
    content: "",
    extra_pages_cat: [],
    featured_media: null,
    status: "publish",
  };
  const [groupDetails, setGroupDetails] = useState(initialDetails);
  const [featuredImg, setFeaturedImg] = useState("");

  const handleFeatureChange = async (event) => {
    const file = event.target.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setGroupDetails({
          ...groupDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFeatureDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const res = await createMedia(file, params?.id);
    if (res) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeaturedImg(reader.result);
        setGroupDetails({
          ...groupDetails,
          featured_media: res.id,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleWebsiteChange = (siteId) => {
    setGroupDetails((prevDetails) => {
      const updatedWebsites = prevDetails.extra_pages_cat.includes(siteId)
        ? prevDetails.extra_pages_cat.filter((id) => id !== siteId)
        : [...prevDetails.extra_pages_cat, siteId];

      return {
        ...prevDetails,
        extra_pages_cat: updatedWebsites,
      };
    });
  };
  const handlePublish = async () => {
    let res = null;
    if (params?.id) {
      res = await updateGroup(params?.id, groupDetails);
    } else {
      res = await createGroup(groupDetails);
      navigate(`/groups/${res?.id}`);
    }
    if (res) {
      setPublishSuccess(true);
    }
  };

  useEffect(() => {
    const fetchGroupData = async () => {
      const response = await getGroupData(params?.id);
      let featuredimgData = "";
      if (response.featured_media) {
        featuredimgData = await fetchMedia(response.featured_media);
        setFeaturedImg(featuredimgData?.source_url);
      }
      setGroupDetails({
        ...groupDetails,
        title: response?.title,
        content: response?.content,
        status: response?.status,
        extra_pages_cat: response?.extra_pages_cat,
        featured_media: featuredimgData?.id,
      });
      seLoading(false);
    };

    if (params?.id) {
      fetchGroupData();
    } else {
      setGroupDetails(initialDetails);
      seLoading(false);
    }
  }, [params, params?.id]);

  useEffect(() => {
    const fetchWebsites = async () => {
      const response = await getWebsites();
      setWebsites(response.data);
    };
    fetchWebsites();
  }, []);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        {publishSuccess && (
          <AutoSnackbar message={"Published Successfully"} severity="success" />
        )}
        <Box
          sx={{
            position: "sticky",
            top: "61px",
            background: "#f1f1f1",
            zIndex: 100,
            margin:" 0 -2px",
            padding: "15px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">
            {params.id ? "Edit Group" : "Add New Group"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "25%" }}>
            <InputLabel>Status :</InputLabel>
            <Select
              sx={{ m: "5px", width: "40%" }}
              value={groupDetails.status}
              onChange={(e) =>
                setGroupDetails({
                  ...groupDetails,
                  status: e.target.value,
                })
              }
              variant="outlined"
              margin="normal"
              name="videoType"
            >
              <MenuItem value="publish">Publish</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
            </Select>
            <Button
              variant="contained"
              sx={{
                width: "15%",
                fontSize: "16px",
                fontWeight: 500,
              }}
              onClick={handlePublish}
            >
              {params?.id ? "Update" : "Publish"}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",mt:"5px" }}>
          <Box sx={{ width: "70%" }}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel>Title</InputLabel>
                      <TextField
                        placeholder="Title"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        name="title"
                        value={groupDetails.title}
                        onChange={(e) =>
                          setGroupDetails({
                            ...groupDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel>Description</InputLabel>
                      <ReactQuill
                        modules={modules}
                        defaultValue={""}
                        theme="snow"
                        className="pb-4"
                        value={groupDetails.content}
                        onChange={(value) =>
                          setGroupDetails((prevDetails) => ({
                            ...prevDetails,
                            content: value,
                          }))
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "28%" }}>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Websites" />
              <CardContent>
                <FormGroup>
                  {websites &&
                    websites?.map((site) => (
                      <FormControlLabel
                        key={site?.id}
                        control={
                          <Checkbox
                            checked={groupDetails?.extra_pages_cat?.includes(
                              site.id
                            )}
                            onChange={() => handleWebsiteChange(site.id)}
                          />
                        }
                        label={site?.name}
                      />
                    ))}
                </FormGroup>
              </CardContent>
            </Card>
            <Card sx={{ marginTop: "20px" }}>
              <CardHeader title="Featured Image" />
              <CardContent>
                {!featuredImg ? (
                  <Paper
                    sx={{
                      height: "100px",
                      margin: "30px",
                      border: "1px dashed",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onDrop={handleFeatureDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <IconButton
                      onClick={() =>
                        document.getElementById("Feature-file-upload").click()
                      }
                      sx={{ flexDirection: "column" }}
                    >
                      <CloudUpload sx={{ fontSize: "50px" }} />
                      <Typography>
                        Drop files here or click to upload.
                      </Typography>
                    </IconButton>
                  </Paper>
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    onMouseEnter={() => setIsFeatureHovered(true)}
                    onMouseLeave={() => setIsFeatureHovered(false)}
                  >
                    <img
                      src={featuredImg}
                      alt="feature Preview"
                      style={{ maxWidth: "100%", marginTop: "10px" }}
                    />
                    {isFeatureHovered && (
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "3%",
                          left: "86%",
                          background: "#000",
                          borderRadius: "50%",
                        }}
                        onClick={() => {
                          setFeaturedImg("");
                          setGroupDetails((prevDetails) => ({
                            ...prevDetails,
                            featured_media: null,
                          }));
                        }}
                      >
                        <Delete sx={{ color: "#fff" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                <input
                  id="Feature-file-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFeatureChange}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default AddGroup;
